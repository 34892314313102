<template>
  <div class="grid md:grid-cols-2 lg:grid-cols-4 justify-items-center gap-2">
    <div
      v-for="(item, itemIdx) in stats"
      :key="itemIdx"
      class="flex flex-1 flex-col gap-5 p-4 rounded-lg shadow-md w-full"
      :style="{'background-color': item.color ? item.color : 'white'}"
    >
      <ProgramStatisticsCard
        v-if="item.type !=='pie-chart' && item.type !=='binomial-pie-chart' && item.type !=='line-chart'"
        :data="item"
      />
      <ProgramPieChartStatisticsCard
        v-if="item.type ==='pie-chart'"
        :data="item"
      />
      <ProgramLineChartStatisticsCard
        v-if="item.type ==='line-chart'"
        :data="item"
      />
      <ProgramBinomialPieChartStatisticsCard
        v-if="item.type ==='binomial-pie-chart'"
        :data="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ProgramStatisticsCard from '@/program/components/program-statistics/ProgramStatisticsCard.vue';
import ProgramLineChartStatisticsCard from '@/program/components/program-statistics/ProgramLineChartStatisticsCard.vue';
import ProgramPieChartStatisticsCard from '@/program/components/program-statistics/ProgramPieChartStatisticsCard.vue';
import ProgramBinomialPieChartStatisticsCard from '@/program/components/program-statistics/ProgramBinomialPieChartStatisticsCard.vue';
import {SupportProgramMarketStatistic} from "@/program/interfaces/Program";

export default defineComponent({
    name: 'ProgramStatisticsCards',
    components: {
        ProgramBinomialPieChartStatisticsCard,
        ProgramPieChartStatisticsCard,
        ProgramStatisticsCard,
        ProgramLineChartStatisticsCard
    },
    props: {
        stats: {
            type: Array<SupportProgramMarketStatistic>,
            required: true
        }
    }
});
</script>
