<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";
import apodisLogo from '@/assets/images/logos/apodis.svg';
import {computed} from "vue";

const {type, heading = true, from = "Apodis"} = defineProps<{
    type: 'sms' | 'push';
    count: number;
    when: string;
    content: string;
    heading?: boolean;
    from?: string;
}>();

const iconName = computed(() => {
    return type == 'sms' ? 'mdi-message-processing' : 'mdi-bell';
});

</script>

<template>
  <div class="flex flex-col gap-2 text-sm ">
    <div v-if="heading" class="flex items-center">
      <span class="text-primary font-bold">
        <Icon :name="iconName" class="text-primary" />
        {{ count }}
        {{ type == 'sms' ? 'SMS' : $filters.pluralize('Notification', count) + ' ' + $filters.pluralize('mobile', count) }}
      </span>
    </div>
    <div>
      <div class="flex bg-gray-200 w-full rounded-t-lg px-3 py-1 items-center">
        <div v-if="type == 'sms'" class="flex gap-x-2 items-center">
          <div>
            <img src="@/assets/images/message-bubble.png" class="h-4 bg-white rounded" alt="message">
          </div>
          <div class="pt-1">
            MESSAGES
          </div>
        </div>
        <div v-else class="flex gap-x-2 items-center py-1">
          <div>
            <img :src="apodisLogo" class="h-5" alt="message">
          </div>
          <div class="text-primary">
            Apodis
          </div>
        </div>
        <div class="flex flex-1 justify-end text-xs">
          {{ when }}
        </div>
      </div>
      <div class="bg-white w-full rounded-b-lg p-3 font-content">
        <div>{{ from }}</div>
        {{ content }}
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
