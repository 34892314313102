import {AxiosInstance, AxiosResponse} from "axios";
import {SearchBarAutoCompleteApiResponse} from "@/core/interfaces/search-bar/ISearchBarAutoComplete";
import {CancelToken} from "axios";

/**
 * The search bar resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Autocomplete search.
         *
         * @param {string} query - The query.
         * @param {string[]} requestType - The request type.
         * @param {number} size - Size per section.
         * @param cancelToken - The axios cancel token.
         *
         * @return {Promise<SearchBarAutoCompleteApiResponse>}
         */
        async autocomplete(
            query: string,
            requestType: string[] = [],
            size = 5,
            cancelToken?: CancelToken
        ): Promise<SearchBarAutoCompleteApiResponse> {
            const data = {
                q: query,
                request_types: requestType,
                size
            };

            return client.post("/searchbar/autocompletefilters", data, {cancelToken})
                .then((response: AxiosResponse) => response.data);
        }
    };
}
