<script setup lang="ts">
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import LaboratoryRoutine from "@/laboratory/components/LaboratoryRoutine.vue";
import useLaboratoryRoutines from "@/laboratory/composables/useLaboratoryRoutines";

const props = defineProps<{
    laboratoryId: string;
}>();

const {data, isLoading} = useLaboratoryRoutines(props.laboratoryId);
</script>

<template>
  <BaseContainer class="p-6">
    <BaseFlexSpinner v-if="isLoading" />
    <div v-else-if="data">
      <BaseCard>
        <BaseCardHeader>
          <div>
            <h1 class="text-black">
              Routines
            </h1>
            <p class="font-content text-base">
              Les routines sont visibles sur l'application Apodis pour les patients et leur proposent des produits
              complémentaires à leurs achats
            </p>
          </div>
        </BaseCardHeader>
        <BaseCardBody class="space-y-12">
          <LaboratoryRoutine v-for="(routine, index) in data" :id="`routine-${routine.id}`" :key="routine.id" :routine="routine" :index="index" />
        </BaseCardBody>
      </BaseCard>
    </div>
  </BaseContainer>
</template>

<style scoped>

</style>
