<template>
  <div
    v-if="!editable"
    class="flex justify-center"
  >
    <p
      class="rounded-lg bg-primary-lightest w-full"
      :class="[density === 'compact' ? 'p-2 text-sm' : 'p-4']"
    >
      {{ !!value?.length ? value[0] : "" }}
    </p>
  </div>
  <div v-else>
    <textarea
      v-if="value"
      ref="textarea"
      class="w-full p-2 rounded-lg bg-primary-lightest overflow-hidden"
      :class="{'h-8' : question.type.slug === QuestionType.CHECK_INPUT}"
      :value="value"
      @input="handleChange"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {QuestionType} from "@/core/interfaces/survey/Survey";

export default defineComponent({
    name: 'SurveyBuiltInput',
    props: {
        question: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        },
        density: {
            type: String,
            default: 'normal'
        },
        value: {
            type: Array<string>,
            default: null
        }
    },
    emits: ['change'],
    data() {
        return {
            QuestionType
        };
    },
    watch: {
        value() {
            this.autoResize();
        }
    },
    mounted() {
        this.autoResize();
    },
    methods: {
        autoResize() {
            const textarea = this.$refs.textarea as HTMLTextAreaElement;

            if (!textarea) {
                return;
            }

            textarea.style.height = textarea.scrollHeight + 'px';
        },
        handleChange(event: Event): void {
            this.$emit('change', (event.target as HTMLTextAreaElement).value);
        }
    }
});
</script>
