import {
    AUTO_COMPLETE_REQUEST_TYPE,
    SearchBarAutoCompleteApiResponse
} from "@/core/interfaces/search-bar/ISearchBarAutoComplete";
import {searchBarApi} from "@/container";
import SearchBarAutoCompleteService from "@/core/services/SearchBarAutoCompleteService";
import {ref} from "vue";
import axios, {CancelTokenSource} from "axios";

/**
 * Default size of the results.
 */
const DEFAULT_SIZE: number = 5;

let searchSource: CancelTokenSource | undefined;

/**
 * Autocomplete composable.
 */
const useAutoComplete = () => {
    const results = ref<SearchBarAutoCompleteApiResponse>();
    const loading = ref<boolean>(false);
    const keyword = ref<string>('');
    let timer: NodeJS.Timeout;

    /**
     * Search function.
     *
     * @param {string} value
     *
     * @return {Promise<SearchBarAutoCompleteApiResponse>}
     */
    const search = (value: string): void => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(async () => {
            keyword.value = keyword.value;
            if (searchSource) {
                searchSource.cancel();
            }

            loading.value = true;
            searchSource = axios.CancelToken.source();
            return searchBarApi().autocomplete(
                value,
                [],
                DEFAULT_SIZE,
                searchSource?.token
            )
                .then(response => results.value = response)
                .finally(() => loading.value = false);
        }, 500);
    };

    /**
     * Load more result in type.
     *
     * @param {string} search - The search string.
     * @param {AUTO_COMPLETE_REQUEST_TYPE} type - The type to load more.
     *
     * @return {Promise<void>}
     */
    const loadMore = (type: AUTO_COMPLETE_REQUEST_TYPE, search?: string): Promise<void> => {
        if (!search) {
            return Promise.resolve();
        }

        const key = SearchBarAutoCompleteService.getSectionKeyFromRequestType(type);

        if (!key) {
            return Promise.resolve();
        }

        if (results.value && results.value[key].length > DEFAULT_SIZE) {
            hide(key);
            return Promise.resolve();
        }

        return searchBarApi().autocomplete(
            search,
            [type],
            100
        ).then((result) => {
            if (results.value) {
                results.value = {
                    ...results.value,
                    [key]: result[key]
                };
            }
        });
    };


    /**
     * Hide elements from the results in order to match the default size.
     *
     * @param {keyof SearchBarAutoCompleteApiResponse} type - The type to hide.
     */
    const hide = (type: keyof SearchBarAutoCompleteApiResponse) => {
        if (results.value) {
            results.value = {
                ...results.value,
                [type]: results.value[type].slice(0, DEFAULT_SIZE)
            };
        }
    };

    return {
        keyword,
        results,
        loading,
        search,
        loadMore
    };
};

export default useAutoComplete;
