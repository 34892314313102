<script setup lang="ts">
import letTheMagicHappen from "@/assets/images/let-the-magic-happens.png";
import Icon from "@/core/components/icon/Icon.vue";
</script>

<template>
  <div class="flex bg-secondary-lightest/25  justify-between flex-col mb-4 p-4">
    <div class="flex flex-row items-center">
      <img
        :src="letTheMagicHappen"
        alt="Let the magic happen"
        style="height:80px"
      >
      <div class="pl-4">
        <h2 class="font-bold">
          Facilitez-vous la vie avec l'IA
          <Icon name="mdi-creation" color="primary" />
        </h2>
        <p>Enregistrez la conversation ou importez un fichier audio pour remplir automatiquement le compte-rendu</p>
      </div>
    </div>
  </div>
</template>
