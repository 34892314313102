/** @type {import('tailwindcss').Config} */
import forms from '@tailwindcss/forms';
import typography from '@tailwindcss/typography';
import textShadow from 'tailwindcss-textshadow';
import * as animate from 'tailwindcss-animate';

export default  {
    darkMode: 'selector',
    content: ['./src/**/*.{vue,js,ts}'],
    theme: {
        extend: {
            borderWidth: {
                2.5: '2.5px'
            },
            fontFamily: {
                sans: [
                    'Comfortaa',
                    // ...defaultTheme.fontFamily.sans
                ],
                heading: ['Poppins', 'sans-serif'],
                content: ['Poppins', 'sans-serif'],
                semibold: ['Poppins-SemiBold', 'sans-serif'],
                'content-bold': ['Poppins-Bold', 'sans-serif']
            },
            fontSize: {
                tiny: '8px',
                xs: '10px',
                sm: '12px',
                base: '14px',
                lg: '16px',
                xl: '18px',
            },
            flex: {
                2: '2 2 0%'
            },
            colors: {
                primary: {
                    lightest: '#f3f5f7',
                    DEFAULT: '#3513e2',
                    dark: '#3c008a'
                },
                secondary: {
                    lightest: '#C2F5E4',
                    DEFAULT: '#65efca'
                },
                neutral: {
                    DEFAULT: '#f1f1f1'
                },
                lightPurple: {
                    lightest: '#EEECF7',
                    DEFAULT: '#E2C4FF',
                    darkest: '#AB74E0'
                },
                apodisPink: {
                    DEFAULT: '#e8DCFA'
                },
                darkPurple: {
                    default: '#3c008a'
                },
                darkBlue: {
                    default: '#3513e2'
                },
                gray: {
                    default: '#cecece',
                    dark: '#353535'
                },
                green: {
                    light: '#65efca'
                },
                orange: {
                    light: '#ffd7a4',
                    default: '#FFBC68'
                },
                pink: {
                    light: '#ff80a280',
                    default: '#FF80A2',
                    lighter: 'rgba(226, 196, 255, 0.3)'
                },
                red: {
                    default: '#ff2525',
                    light: '#eb5e57'
                }
            },
            boxShadow: {
                custom: '0px 2px 6px rgba(0, 0, 0, 0.16)',
                deep: '0px 3px 23px #AB74E0'
            }
        }
    },
    variants: {},
    plugins: [
        forms,
        textShadow,
        typography,
        animate,
    ]
};

