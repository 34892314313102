<template>
  <div class="flex flex-col flex-1">
    <div class="text-sm">
      {{ data.label }}
    </div>
    <div>
      <LineChart
        :id="data.label"
        :data="chartData"
        :options="chartOptions"
      />
    </div>
    <div class="text-sm">
      {{ data.extra }}
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {Line as LineChart} from 'vue-chartjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Filler,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement, ChartData, Point
} from 'chart.js';
import ChartService from "@/laboratory/services/ChartService";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, CategoryScale, LinearScale, ArcElement);

export default defineComponent({
    name: 'ProgramLineChartStatisticsCard',
    components: {LineChart},
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        chartData(): ChartData<"line", (number | Point | null)[], unknown> {
            const colors = ChartService.colorPalette();

            return {
                labels: [],
                datasets: Object.entries(this.data.value).map(([key, value], index) => {
                    return {
                        label: key,
                        borderColor: colors[index % colors.length],
                        data: (value as { label: string, x: string, y: number }[]).slice(1).map(item => ({
                            x: item.label,
                            y: item.y
                        }))
                    };
                }) as []
            };
        },
        chartOptions(): any {
            return {
                scales: {
                    y: {
                        ticks: {
                            callback: (value: number) => {
                                return value + ' €';
                            }
                        }
                    }
                },
                responsive: true,
                elements:
                    {
                        line: {
                            tension: 0.4
                        }
                    },
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            padding: 4,
                            font: {
                                size: 9
                            },
                            boxWidth: 8
                        }
                    }
                }
            };
        }
    },
});
</script>
