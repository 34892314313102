<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";

const emit = defineEmits(['file-upload']);

/**
 * Open the file upload dialog
 */
function openFileUpload() {
    document.getElementById('input-file')?.click();
}

/**
 * Handle the file upload
 *
 * @param {File} file
 */
function handleFileUpload(file: File | File[]): void {
    const blob = new Blob(Array.isArray(file) ? file : [file]);

    emit('file-upload', blob);
}
</script>

<template>
  <div class="flex justify-end">
    <div class="flex justify-end pb-6">
      <base-button
        class="text-primary"
        @click="openFileUpload"
      >
        Remplir automatiquement à partir d’un fichier audio
      </base-button>
    </div>
    <v-file-input
      v-show="false"
      id="input-file"
      accept="audio/*"
      @update:model-value="handleFileUpload"
    />
  </div>
</template>
