import {AxiosInstance, AxiosResponse} from "axios";
import {PrescriptionRenewalPlanDate} from "@/prescription/interfaces/prescription-renewal-plan/PrescriptionRenewalPlan";
import {Appointment} from "@/core/interfaces/Appointment";

export interface CalendarEvent {
    model: PrescriptionRenewalPlanDate | Appointment;
    title: string;
    type: string;
    start: string;
    end: string;
    color: string;
    allDay: boolean;
}

interface CalendarResourceInterface {
    /** Get the SMS credit balance for the current organization. */
    calendar(from: string, to: string): Promise<CalendarEvent[]>;
}

/**
 * The credit resource.
 * @param {AxiosInstance} client - The injected client.
 */
export default function (client: AxiosInstance): CalendarResourceInterface {
    return {
        /**
         * Get the SMS credit balance for the current organization.
         */
        async calendar(from: string, to: string): Promise<CalendarEvent[]> {
            return await client.get("calendar", {
                params: {
                    from,
                    to
                }
            })
                .then((response: AxiosResponse) => response.data.data);
        }
    };
};
