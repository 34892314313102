<script setup lang="ts">
import {
    AUTO_COMPLETE_REQUEST_TYPE,
    SearchBarAutoCompleteItem
} from "@/core/interfaces/search-bar/ISearchBarAutoComplete";
import useAutoComplete from "@/core/composables/autocomplete/useAutoComplete";
import SearchInput from "@/core/components/search-bar/SearchInput.vue";
import {ref, watchEffect} from "vue";
import {AUTO_COMPLETE_SECTION} from "@/core/interfaces/search-bar";
import SearchBarAutoCompleteService from "@/core/services/SearchBarAutoCompleteService";
import CustomerFilters from "@/customer/components/customer-filters/CustomerFilters.vue";
import {storeToRefs} from "pinia";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import BaseButton from "@/core/components/base/BaseButton.vue";
import CommunicationFilters from "@/communication/components/CommunicationFilters.vue";
import {DEFAULT_AUTO_COMPLETE_RESULT_SIZE} from "@/core/factories/search-bar/AutoCompleteConfig";

/**
 * @interface ResultsSection
 *
 * @property {string} title - Section title.
 * @property {SearchBarAutoCompleteItem[]} data - Section data
 * @property {string} color - Section color.
 * @property {AUTO_COMPLETE_REQUEST_TYPE} type - The type related to the section.
 */
export interface ResultsSection {
    title: string;
    data: SearchBarAutoCompleteItem[];
    color: string;
    type: AUTO_COMPLETE_REQUEST_TYPE;
}

const emits = defineEmits(['on-confirm']);
const {keyword, results, loading, search, loadMore} = useAutoComplete();
const {selectedFilters} = storeToRefs(useCommunicationStore());
const sections = ref<ResultsSection[]>([]);

watchEffect(() => {
    if (results.value) {
        sections.value = (Object.keys(results.value) as AUTO_COMPLETE_SECTION[])
            .filter(SearchBarAutoCompleteService.getNameForSection)
            .sort((previous, next) => {
                return SearchBarAutoCompleteService.getSectionPosition(previous) -
                    SearchBarAutoCompleteService.getSectionPosition(next);
            })
            .map((key) => {
                const data = (results as any).value[key] as SearchBarAutoCompleteItem[];

                return {
                    title: SearchBarAutoCompleteService.getNameForSection(key) as string,
                    color: SearchBarAutoCompleteService.getColorForSection(key) || "#3513e2",
                    data: data,
                    type: SearchBarAutoCompleteService.getRequestTypeFromSection(key)
                };
            });
    } else {
        sections.value = [];
    }
});

/**
 * If item is selected.
 */
const isSelected = (item: SearchBarAutoCompleteItem): boolean => {
    return !!(selectedFilters.value as SearchBarAutoCompleteItem[])
        .find((selectedItem) => (selectedItem.id === item.id) && (selectedItem.type === item.type));
};

/**
 * On result press.
 *
 * @param item
 */
const onResultPress = (item: SearchBarAutoCompleteItem) => {
    let copy = [...selectedFilters.value];

    if (!isSelected(item)) {
        copy.push({...item});
    } else {
        const index = (selectedFilters.value as SearchBarAutoCompleteItem[])
            .findIndex((selectedItem) => (selectedItem.id === item.id) && (selectedItem.type === item.type));

        if (index) {
            copy.splice(index, 1);
        }
    }
    selectedFilters.value = copy;
};

/**
 * Load more item.
 *
 * @param {AUTO_COMPLETE_REQUEST_TYPE} type - The type to expand.
 *
 * @return {Promise<any>}
 */
const handleLoadMore = (type: AUTO_COMPLETE_REQUEST_TYPE): Promise<any> => {
    if (keyword.value) {
        return loadMore(type, keyword.value);
    }

    return Promise.resolve();
};
</script>

<template>
  <div>
    <search-input
      v-model="keyword"
      placeholder="Rechercher un produit, un laboratoire, une gamme, ..."
      :is-loading="loading"
      @input="search"
    />
    <communication-filters />
    <div v-if="!results">
      <CustomerFilters
        :selected="selectedFilters"
        @on-filter-change="(value) => selectedFilters = value"
      />
    </div>
    <div v-else>
      <div class="py-4 flex flex-row justify-between items-center">
        <div>
          <h2 class="text-black">
            Résultats
          </h2>
          <p>Cliquez sur un ou plusieurs éléments pour l’ajouter à la sélection</p>
        </div>
        <div v-if="selectedFilters.length">
          <base-button primary @click="emits('on-confirm')">
            Valider
          </base-button>
        </div>
      </div>
      <div v-if="sections && !!sections?.length">
        <div v-for="(section, index) in sections" :key="index">
          <div class="py-2">
            <span v-if="section.data.length" class="relative text-black font-bold text-xl pr-4">
              <div class="absolute flex bottom-1 h-2" :style="'width: 100%;background-color:'+ section.color" />
              <span class="relative">{{ section.title }}</span>
            </span>
          </div>
          <div
            v-for="item in section.data"
            :key="item.id"
            class="px-2 py-1 cursor-pointer rounded-r-xl"
            :style="{'border-left-width': '2px', 'border-color': section.color}"
            @click="onResultPress(item)"
          >
            <p
              class="p-2 rounded-lg hover:bg-gray-100 hover:opacity-75"
              :style="{'background-color': isSelected(item) ? section.color : 'transparent'}"
            >
              {{ item.name }}<span v-if="item.cip" class="text-sm text-gray-500"> - {{ item.cip }}</span>
            </p>
          </div>
          <div
            v-if="section.data.length >= DEFAULT_AUTO_COMPLETE_RESULT_SIZE"
            class="p-2 flex text-gray-500 justify-end cursor-pointer hover:opacity-75"
            @click="handleLoadMore(section.type)"
          >
            <span>
              {{
                section.data.length > DEFAULT_AUTO_COMPLETE_RESULT_SIZE ? "Cacher les éléments" : "Afficher tous les éléments"
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
