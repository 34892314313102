<template>
  <div
    :contenteditable="editable"
    class="prose max-w-full p-2"
    :class="{format: !inheritStyles}"
    @blur="onEdit"
    v-html="markdownToHtml"
  />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {marked} from 'marked';

export default defineComponent({
    name: 'MarkdownView',
    props: {
        content: {
            type: String,
            required: true
        },
        inheritStyles: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    emits: ['on-edit'],
    computed: {
        markdownToHtml() {
            const renderer = new marked.Renderer();

            renderer.link = ({href, text}) => {
                return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
            };

            return marked(this.content, {renderer});
        }
    },
    methods: {
        onEdit (event: any) {
            if (this.editable) {
                this.$emit('on-edit', event.target?.innerHTML);
            }
        }
    }
});
</script>

<style scoped>
@tailwind components

@layer components {
    .format h1 {
        @apply text-2xl font-bold mb-0 font-heading !important;
    }

    .format h2 {
        @apply text-xl font-bold my-0 font-heading !important;
    }

    .format p {
        @apply font-content !important;
    }

    .format li {
        @apply text-sm !important;
    }

    .prose blockquote p {
        content: '' !important;
        font-style: normal !important;
    }

    .prose blockquote p::after {
        content: '' !important;
    }

    .prose blockquote p::before {
        content: '' !important;
    }
}
</style>
