<script setup lang="ts">
import {Skeleton} from "@/core/components/ui/skeleton";
</script>

<template>
  <div class="bg-white p-2 rounded-md shadow-md space-y-3">
    <Skeleton class="w-full h-48 rounded-md" />
    <Skeleton class="h-6 w-3/4" />
    <Skeleton class="h-4 w-1/2" />
  </div>
</template>

<style scoped>

</style>
