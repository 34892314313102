<script lang="ts" setup>
import {Input} from "@/core/components/ui/input";
import {Button} from "@/core/components/ui/button";
import {ref} from "vue";
import Icon from "@/core/components/icon/Icon.vue";
import {authenticationService, usersApi} from "@/container";
import User from "@/core/interfaces/User";
import debounce from "lodash.debounce";
import {Table, TableBody, TableCell, TableRow} from "@/core/components/ui/table";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import LegacyUser from "@/core/interfaces/User";
import {useRouter} from "vue-router";
import LegacyAccess from "@/core/interfaces/Access";

const keyword = ref<string | undefined>('');
const users = ref<User[]>([]);
const loading = ref<boolean>(false);
const router = useRouter();

/**
 * Search users.
 */
const search = debounce(async () => {
    if (keyword.value && keyword.value.length > 2) {
        loading.value = true;
        users.value = await usersApi().search({keyword: keyword.value, access_type: 2});
        loading.value = false;
    }
}, 300);

/**
 * Update user authentication bypass key.
 */
const loginAs = async (user: LegacyUser, access: LegacyAccess) => {
    const key: string = crypto.randomUUID();

    return await usersApi().update(user.id, {key})
        .then(() => authenticationService().loginAs(key, access.id))
        .then(() => router.push({name: 'app.loading'}));
};

</script>

<template>
  <div class="p-6 space-y-6 pb-24">
    <h1>
      <Icon name="mdi-login" class="text-primary mr-1" />
      Se connecter en tant que :
    </h1>
    <div class="relative max-w-96">
      <Input
        v-model="keyword"
        type="search"
        class="bg-white"
        placeholder="Nom, email ou pharmacie"
        @input="search"
      />
      <BaseSpinner v-if="loading" size="button" class="absolute top-2 right-2" />
    </div>

    <Table v-if="users.length && !loading">
      <TableBody>
        <template v-for="user in users" :key="user.id">
          <TableRow class="font-bold">
            <TableCell>{{ user.id }}</TableCell>
            <TableCell>{{ user.given }} {{ user.familyBirth }}</TableCell>
            <TableCell>{{ user.email }}</TableCell>
            <TableCell />
          </TableRow>
          <TableRow v-for="access in user.accesses" :key="access.id" class="hover:bg-slate-50">
            <TableCell class="pl-2">
              <span class="text-xs">{{ access.id }}</span>
            </TableCell>
            <TableCell>{{ access.organization?.morphable?.name || 'Organization' }}</TableCell>
            <TableCell>{{ access.rank?.title || 'Pharmacien' }}</TableCell>
            <TableCell>
              <Button class="bg-primary" @click="() => loginAs(user, access)">
                <Icon name="mdi-login" class="text-white mr-1" />
                Se connecter
              </Button>
            </TableCell>
          </TableRow>
        </template>
      </TableBody>
    </Table>
  </div>
</template>
