<script setup lang="ts">
import {onMounted, ref, watch} from "vue";

const props = withDefaults(
    defineProps<{ question: any; editable?: boolean; density?: string; value: string[] }>(),
    {
        editable: false,
        density: "compact"
    });

const emits = defineEmits(['change']);

const textAreaRef = ref<HTMLTextAreaElement>();

/**
 * On mount, auto resize.
 */
onMounted(() => {
    autoResize();
});

/**
 * On value change autoresize.
 */
watch(props.value, () => autoResize());

/**
 * Handle input change.
 */
const handleChange = (event: Event): void => {
    emits('change', (event.target as HTMLTextAreaElement).value);
};

/**
 * Autoresize textarea.
 */
const autoResize = () => {
    if (!textAreaRef.value) {
        return;
    }

    textAreaRef.value.style.height = textAreaRef.value.scrollHeight + 'px';
};
</script>

<template>
  <div
    v-if="!editable"
    class="flex justify-center"
  >
    <p
      class="rounded-lg bg-primary-lightest w-full"
      :class="[density === 'compact' ? 'p-2 text-sm' : 'p-4']"
    >
      {{ !!value.length ? value[0] : "" }}
    </p>
  </div>
  <div v-else class="flex items-start">
    <textarea
      v-if="value"
      ref="textAreaRef"
      class="w-full p-2 rounded-lg bg-primary-lightest overflow-hidden"
      :value="value"
      @input="handleChange"
    />
  </div>
</template>
