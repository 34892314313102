<script setup lang="ts">
import {useCreditStore} from "@/stores/credit.store";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import {storeToRefs} from "pinia";
import {CommunicationMode} from "@/communication/types";

const {mode} = storeToRefs(useCommunicationStore());
const store = useCommunicationStore();
const {credit} = useCreditStore();
const props = defineProps<{
    loading: boolean;
}>();

/**
 * Toggle the mode.
 *
 * @param to
 */
const toggleMode = (to: CommunicationMode) => {
    if (!props.loading) {
        store.mode = to;
    }
};

</script>

<template>
  <div class="flex flex-row border-2 border-primary rounded-full">
    <div
      class="flex flex-col items-center py-2 px-4 cursor-pointer hover:opacity-75"
      :class="{'text-white bg-primary font-bold rounded-xl hover:opacity-100': mode === 'notification'}"
      @click="toggleMode('notification')"
    >
      <p>Mode Notification</p>
      <p
        class="text-sm text-gray-500"
        :class="{'text-white': mode === 'notification'}"
      >
        Gratuit !
      </p>
    </div>
    <div
      class="flex flex-col items-center py-2 px-4 cursor-pointer hover:opacity-75"
      :class="{'text-white bg-primary font-bold rounded-xl hover:opacity-100': mode === 'sms'}"
      @click="toggleMode('sms')"
    >
      <p>Mode SMS</p>
      <p
        class="text-sm text-gray-500"
        :class="{'text-white': mode === 'sms'}"
      >
        {{ credit?.balance || 0 }} {{ $filters.pluralize('crédit', credit?.balance || 0) }}
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
