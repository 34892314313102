<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";
import {NotificationTemplate} from "@/core/interfaces/MassCertification";
import CommunicationMessageTemplate from "@/communication/components/CommunicationMessageTemplate.vue";

defineProps<{ template: NotificationTemplate }>();
</script>

<template>
  <div class="flex flex-col py-4">
    <p class="pl-4">
      {{ template.name }}
    </p>
    <div class="flex flex-row mx-4 mb-2 cursor-pointer hover:opacity-80">
      <CommunicationMessageTemplate :template="template" class="flex-2" />
      <div class="flex flex-col flex-1 gap-2 items-center justify-center">
        <BaseButton primary>
          Sélectionner
        </BaseButton>
        <div class="text-primary text-sm">
          {{ template.credits }} {{ $filters.pluralize('crédit', template.credits) }} SMS
        </div>
      </div>
    </div>
  </div>
</template>
