<script setup lang="ts">
import {FileManagerFile} from "@/core/interfaces/FileManagerFile";
import {ref} from "vue";
import {Skeleton} from "@/core/components/ui/skeleton";

defineProps<{
    logo?: FileManagerFile;
    alt: string;
    imgClass?: string;
}>();

const isLoading = ref(true);

const handleImageLoad = () => {
    isLoading.value = false;
};
</script>

<template>
  <div class="h-28 aspect-square rounded-md overflow-hidden flex relative">
    <Skeleton v-if="isLoading" class="absolute inset-0 w-full h-full" />
    <img
      v-if="logo?.url"
      class="w-full h-full"
      :class="[imgClass ?? '', {'opacity-0': isLoading}]"
      :src="logo.url"
      :alt="alt"
      @load="handleImageLoad"
    >
    <slot v-else name="fallback">
      <div class="bg-gray-200 w-full h-full" />
    </slot>
  </div>
</template>
