<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";
import {inject, ref} from "vue";
import {Interview, InterviewInstance} from "@/interview/interfaces/interview";
import {interviewApi} from "@/container";

const props = defineProps<{ label?: string, interview: Interview; interviewInstance: InterviewInstance }>();

const loading = ref<boolean>(false);

/**
 * Close function injected from InterviewInstanceLayoutView
 */
const close = inject('close') as () => void;

/** Injection of open confirmation modal function. */
const openConfirmationModal = inject('openConfirmationModal') as (
    title: string,
    message: string,
    success: () => never | Promise<void>
) => Promise<void>;

/**
 * Delete an interview instance.
 */
const deleteInterviewInstance = (): Promise<void> => {
    loading.value = true;
    return interviewApi().delete(props.interview.id, props.interviewInstance.id)
        .then(() => close())
        .finally(() => {
            loading.value = false;
        });
};

/**
 * Ask for confirmation before delete.
 */
const confirmDelete = () => {
    openConfirmationModal(
        'Suppression d\'un entretien',
        'Êtes-vous sûr de vouloir supprimer cet entretien ?',
        deleteInterviewInstance
    );
};
</script>

<template>
  <BaseButton prepend-icon="mdi-close" :loading="loading" @click="confirmDelete">
    {{ label ?? "Abandonner l'entretien" }}
  </BaseButton>
</template>

<style scoped>

</style>