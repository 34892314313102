import {defineStore} from "pinia";

/**
 * Laboratories store in order to smooth transition between navigation.
 */
export const useLaboratoriesStore = defineStore('laboratories', {
    state: () => ({
        laboratories: {}
    })
});
