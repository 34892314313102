import {createVuetify} from 'vuetify';
import {fr} from 'vuetify/locale';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';
import {Config} from "tailwindcss";
import {VCalendar} from "vuetify/labs/VCalendar";

const {theme} = resolveConfig(tailwindConfig as Config);

/**
 * Custom theme used to override some vuetify colors with tailwind colors
 * @type {{dark: boolean, colors: {secondary: string, orange: string, gray: string, error: string, accent: string, primary: string}}}
 */
const customTheme = {
    dark: false,
    colors: {
        primary: (theme.colors as any).primary.DEFAULT,
        secondary: (theme.colors as any).secondary.DEFAULT,
        gray: (theme.colors as any).gray.default,
        accent: '#8c9eff',
        error: '#b71c1c',
        orange: (theme.colors as any).orange.default,
    }
};

export default createVuetify({
    components: {
        VCalendar,
        ...components,
    },
    directives,
    theme: {
        defaultTheme: 'customTheme',
        themes: {
            customTheme,
        },
    },
    locale: {
        messages: {fr}
    }
});
