<script setup lang="ts">
import GlowingContainer from "@/core/components/glowing-container/GlowingContainer.vue";
import Icon from "@/core/components/icon/Icon.vue";

defineProps<{
    laboratoryName: string;
    colors?: string[];
}>();
</script>

<template>
  <GlowingContainer class="cursor-pointer select-none" :colors="colors">
    <div class="bg-white p-3 rounded-full flex justify-between">
      <p class="text-gray-400">
        Posez une question à l'IA {{ laboratoryName }}
      </p>
      <div>
        <span class="font-bold">Demander</span>
        <Icon name="mdi-creation" class="ml-1" color="primary" :size="20" />
      </div>
    </div>
  </GlowingContainer>
</template>

<style scoped>

</style>
