<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import CommunicationMessageTemplate from "@/communication/components/CommunicationMessageTemplate.vue";
import {computed, ref} from "vue";
import {MassCertificationCustomer} from "@/core/interfaces/MassCertification";
import CommunicationCustomerRow from "@/communication/components/CommunicationCustomerBaseInformation.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {massCertificationApi} from "@/container";
import {useToastStore} from "@/stores/toast.store";
import {useRouter} from "vue-router";
import CommunicationCustomMessageTemplate from "@/communication/components/CommunicationCustomMessageTemplate.vue";
import Icon from "@/core/components/icon/Icon.vue";

const {customers, template, content, contentCost, mode, selectionBatchId} = useCommunicationStore();
const {showSuccess, showError} = useToastStore();
const router = useRouter();

const selectedCustomers = ref<MassCertificationCustomer[]>(customers);

/**
 * Action when confirm button is pressed.
 */
const onConfirm = () => {
    if (mode === 'notification') {
        return sendNotification();
    } else {
        return sendSms();
    }
};

/**
 * Send sms to customers.
 */
const sendSms = () => {
    if (template || content) {
        const customerParam = selectionBatchId
            ? {batch_selection_id: selectionBatchId}
            : {customer_ids: selectedCustomers.value.map(c => c.id)};

        const params = content
            ? {
                content,
                ...customerParam
            }
            : {
                notification_template_id: template!.id,
                ...customerParam
            };

        massCertificationApi().massCertification(params)
            .then(() => showSuccess({content: "\u{1F3C1} SMS envoyés avec succès"}))
            .catch(() => showError({content: "Erreur lors de l’envoi des SMS"}))
            .finally(() => {
                router.push({name: 'patient-administration.communication'});
            });
    }
};

/**
 * Send notification to customers.
 */
const sendNotification = async () => {
    if (!template) {
        return Promise.resolve();
    }

    const params = {
        notification_template_id: template.id,
        customer_ids: selectedCustomers.value.map(c => c.id)
    };

    return massCertificationApi().notifications(params)
        .then(() => showSuccess({content: "\u{1F3C1} Notifications envoyées avec succès"}))
        .catch(() => showError({content: "Erreur lors de l’envoi des notifications"}))
        .finally(() => {
            router.push({name: 'patient-administration.communication'});
        });
};

/**
 * Cost of the message.
 */
const cost = computed(() => {
    return selectedCustomers.value.length * (contentCost || template?.credits || 1);
});
</script>

<template>
  <div>
    <HeaderBackButton :title="'Communication par '+ mode" />
    <BaseCard class="m-4 p-4">
      <h2>Confirmez l'envoi</h2>
      <p class="py-1 text-sm italic">
        <Icon name="mdi-alert text-orange" :size="12" /> L’envoi de SMS est autorisé du lundi au vendredi de 08h00 à 20h00, et le samedi de 10h00 à 19h00, hors jours fériés.
      </p>
      <CommunicationMessageTemplate
        v-if="template && !content"
        :template="template"
        class="flex-2"
      />
      <CommunicationCustomMessageTemplate v-if="content" :content="content" />
      <p v-if="customers.length > 0" class="py-1 mb-2 border-b">
        {{ mode === 'notification' ? 'Cette notification sera envoyée' : 'Ce message sera envoyé' }} aux patients
        sélectionnés :
      </p>
      <div v-for="customer in customers" :key="customer.id">
        <v-checkbox
          v-model="selectedCustomers"
          :value="customer"
          density="comfortable"
          color="primary"
          class="border-b-2"
          :class="[{'h-16': mode==='notification', 'h-24': mode !== 'notification'}]"
        >
          <template #label>
            <CommunicationCustomerRow class="pl-4" :customer="customer" :is-notification="mode==='notification'" />
          </template>
        </v-checkbox>
      </div>
      <div v-if="selectedCustomers.length > 0" class="!absolute bottom-10 right-24">
        <span v-if="mode==='sms'" class="px-4">
          Coût: {{ cost }} {{ $filters.pluralize('crédit', cost) }}
        </span>
        <BaseButton
          primary
          @click="onConfirm"
        >
          Envoyer {{ selectedCustomers.length }}
          {{ mode === 'notification' ? $filters.pluralize(mode, selectedCustomers.length) : mode }}
        </BaseButton>
      </div>
    </BaseCard>
  </div>
</template>

<style scoped>
:deep(.v-selection-control .v-label) {
    flex: 1;
    opacity: 1;
}
</style>
