<script setup lang="ts">
import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import BaseLogo from "@/core/components/logo/BaseLogo.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {computed, watch} from "vue";
import {useLaboratoriesStore} from "@/laboratory/stores/laboratories.store";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

const laboratories = useLaboratoriesStore();
const {data, isLoading} = useSwr(
    'partnership/patient/laboratories',
    () => laboratoryApi().patientPartnerLaboratories()
);

/**
 * When data is fetched, we populate the laboratories store with the fetched data
 */
watch(() => data.value, (newValue) => {
    if (newValue) {
        laboratories.laboratories = newValue.reduce((acc, laboratory) => {
            acc[laboratory.id] = laboratory;
            return acc;
        }, {});
    }
});

const backgroundImages = computed(() => {
    if (!data.value) {
        return [];
    }

    return data.value.map(laboratory => laboratory.branding?.background).filter(Boolean);
});
</script>

<template>
  <BaseContainer class="p-6 gap-3">
    <link v-for="image in backgroundImages" :key="image?.id" rel="preload" as="image" :href="image?.url">
    <div>
      <h1 class="text-black">
        Gérez la relation patients avec vos marques
      </h1>
      <p>
        Analysez votre patientèle, mesurez la performance de vos promotions, et boostez vos ventes avec nos programmes
        conseils !
      </p>
    </div>
    <BaseFlexSpinner v-if="isLoading" />
    <div v-else class="flex gap-x-12 gap-y-6 flex-wrap">
      <router-link
        v-for="laboratory in data" :key="laboratory.id"
        :to="{name: 'laboratory', params: {id: laboratory.id}}" class="space-y-1 cursor-pointer"
      >
        <BaseLogo :alt="laboratory.name" :logo="laboratory.logo" img-class="object-contain" class="bg-white" />
        <div class="flex justify-center items-end gap-1">
          <Icon name="mdi-account" :size="25" />
          <span class="font-bold">{{ laboratory.consumers_count ?? 0 }}</span>
        </div>
      </router-link>
    </div>
  </BaseContainer>
</template>

<style scoped>

</style>
