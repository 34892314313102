import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import {mutate} from "swrv";

/**
 * Composable to fetch the laboratory routines.
 *
 * @param laboratoryId
 */
const useLaboratoryRoutines = (laboratoryId: string) => {
    const {
        data,
        isLoading
    } = useSwr(`routines/${laboratoryId}`, () => laboratoryApi().routines(Number(laboratoryId)), {
        revalidateOnFocus: false
    });

    /**
     * Prefetch laboratory statistics data.
     */
    const prefetch = () => {
        return mutate(`routines/${laboratoryId}`, () => laboratoryApi().routines(Number(laboratoryId)));
    };

    return {
        data,
        isLoading,
        prefetch
    };
};

export default useLaboratoryRoutines;
