<script setup lang="ts">
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import {Input} from "@/core/components/ui/input";
import Icon from "@/core/components/icon/Icon.vue";
import debounce from "lodash.debounce";
defineOptions({inheritAttrs: false});
defineProps<{ isLoading: boolean }>();
const emits = defineEmits(['input']);
const model = defineModel<string>({default: ''});

/**
 * On input event.
 * @param event
 */
const onInput = debounce((event: Event) => emits('input', (event.target as HTMLInputElement).value), 300);

/**
 * Clear the input.
 */
const clear = () => {
    model.value = '';
    emits('input', '');
};

</script>

<template>
  <div class="relative">
    <div class="absolute inset-y-0 right-0 pr-3 flex items-center z-10">
      <Icon v-if="!isLoading && !model" name="mdi-magnify" class="text-primary" />
      <Icon v-if="!isLoading && model" name="mdi-close" :size="14" class="close text-white" @click="clear" />
      <BaseSpinner v-if="isLoading" size="small" />
    </div>
    <Input v-bind="$attrs" v-model="model" type="search" class="z-0" @input="onInput" />
  </div>
</template>

<style scoped>
.close {
    @apply cursor-pointer bg-slate-400 rounded-full
}
</style>
