import Extension, {ExtensionSlug, ExtensionType, ProgramExtension} from "@/core/interfaces/extension/Extension";
import {Router} from "vue-router";
import ExtensionService from "@/extension/services/ExtensionService";

/**
 * Extension navigation service.
 *
 * Service responsible for navigating to extension details pages.
 */
const ExtensionNavigationService = () => {

    return {
        /**
         * Navigate to an extension details page.
         */
        navigate(extension: Extension, router: Router) {
            if(!extension.enabled) {
                router.push({name: 'extension-details', params: {extensionId: extension.id}});
                return;
            }

            if (ExtensionService.isProgramExtension(extension)) {
                this.navigateToProgram(extension, router);
                return;
            }

            if (extension.extendable_type === ExtensionType.PACK) {
                this.navigateToPack(extension, router);
                return;
            }

            throw new Error('Unsupported extension type');
        },

        /**
         * Navigate to an extension of type pack.
         */
        navigateToPack(extension: Extension, router: Router) {
            switch (extension.slug) {
            case ExtensionSlug.DISRUPTION_LOGISTICS:
                router.push({
                    name: 'disruption-logistics'
                });
                break;
            case ExtensionSlug.CLICK_COLLECT:
                router.push({
                    name: 'patient-administration.orders'
                });
                break;
            case ExtensionSlug.SMS_PREMIUM:
                router.push({
                    name: 'patient-administration.communication'
                });
                break;
            }
        },

        /**
         * Navigate to an extension of type program-bundle.
         *
         * If bundle contain more than one program, navigate to the bundle detail page.
         * If bundle contain only one program, navigate to the program detail page.
         */
        navigateToProgram(extension: ProgramExtension, router: Router) {
            if (extension.program_bundle.programs.length > 1) {
                router.push({
                    name: 'program-bundle.detail',
                    params: {
                        programBundleId: extension.program_bundle.id
                    }
                });
                return;
            }

            if (extension.program_bundle.programs.length === 1) {
                const id = extension.program_bundle.programs[0].id;

                router.push({
                    name: 'program.detail.content',
                    params: {
                        programId: id
                    }
                });

                return;
            }

            router.push({
                name: 'program.detail.content',
                params: {
                    programId: extension.extendable_id
                }
            });
        }
    };
};

export default ExtensionNavigationService();
