<template>
  <div class="flex flex-col w-72 mt-6">
    <div class="sticky top-6 w-72">
      <h2 class="text-lg self-end pr-5">
        Accès rapides
      </h2>
      <div class="flex flex-col gap-3 mt-3">
        <div
          v-for="(shortcut, index) in shortcuts"
          :key="index"
          class="flex items-center justify-between py-2 pl-4 pr-8 rounded-l-full shadow-md cursor-pointer transition-all duration-300 ease-in-out bg-white hover:bg-lightPurple"
          :class="[
            shortcut.highlight ? 'border-red-500 border-t-[1px] border-b-[1px] border-l-[1px]' : 'text-black',
          ]"
          @click="select(index)"
        >
          {{ shortcut.title }}
          <img v-if="shortcut.logo" :src="shortcut.logo" :alt="shortcut.title" class="max-w-14">
          <Icon v-if="shortcut.icon" :icon="shortcut.icon" class="text-primary" />
          <div
            v-if="shortcut.count && !!shortcut.count()"
            class="ml-2"
          >
            <span class="font-bold">{{ shortcut.count() }}</span>
          </div>
        </div>
      </div>
      <interview-short-cut-drawer-element
        :component-config="component"
        @close="component = null"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, markRaw} from "vue";
import InterviewProfessionalAttachments
    from '@/interview/components/instance-modal/short-cuts/InterviewProfessionalAttachments.vue';
import InterviewShortCutDrawerElement from '@/interview/components/instance-modal/InterviewShortCutDrawerElement.vue';
import InterviewPatientAttachments
    from '@/interview/components/instance-modal/short-cuts/InterviewPatientAttachments.vue';
import InterviewDrugInteraction from '@/interview/components/instance-modal/short-cuts/InterviewDrugInteraction.vue';
import InterviewTranscript from '@/interview/components/instance-modal/InterviewTranscript.vue';
import InterviewSurveys from '@/interview/components/instance-modal/short-cuts/InterviewSurveys.vue';
import InterviewConsumerCarts from '@/interview/components/instance-modal/short-cuts/InterviewConsumerCarts.vue';
import InterviewConsumerNotes from '@/interview/components/instance-modal/short-cuts/InterviewConsumerNotes.vue';
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";
import Vidal from "@/assets/images/logos/logo-vidal.png";
import InterviewVaccinationTimeline
    from "@/interview/components/instance-modal/short-cuts/InterviewVaccinationTimeline.vue";
import Icon from "@/core/components/icon/Icon.vue";
import ProgramKnowledgeBox from "@/program/components/program-knowledge-box/ProgramKnowledgeBox.vue";
import InterviewInstanceSummary
    from "@/interview/components/instance-modal/short-cuts/InterviewInstanceSurveySummary.vue";
import dayjs from "dayjs";

export default defineComponent({
    name: 'InterviewShortCuts',
    components: {Icon, InterviewShortCutDrawerElement},
    data() {
        return {
            selected: null as any,
            component: null as any,
            initialShortcut: [
                {
                    slug: 'knowledge-box',
                    title: 'Demander à l\'IA ',
                    component: markRaw(ProgramKnowledgeBox),
                    minWidth: '50%',
                    icon: 'mdi-creation',
                },
                {
                    title: 'Bibliographie',
                    component: markRaw(InterviewProfessionalAttachments),
                    count: () => this.professionalAttachments?.length || 0,
                    minWidth: '50%'
                },
                {
                    title: 'Ressources patient',
                    component: markRaw(InterviewPatientAttachments),
                    count: () => this.patientAttachments?.length || 0,
                    minWidth: '50%'
                },
                {
                    title: 'Questionnaires',
                    component: markRaw(InterviewSurveys),
                    count: () => this.surveys?.length || 0,
                },
                {
                    title: 'Analyse pharma.',
                    component: markRaw(InterviewDrugInteraction),
                    minWidth: '100%',
                    logo: Vidal
                },
                {
                    title: 'Notes internes',
                    count: () => this.noteCount,
                    component: markRaw(InterviewConsumerNotes)
                },
                {
                    title: 'Historique délivrances',
                    component: markRaw(InterviewConsumerCarts),
                    minWidth: '100%'
                },
                {
                    title: "Suivi vaccinal",
                    component: markRaw(InterviewVaccinationTimeline),
                    minWidth: 'auto'
                }
            ]
        };
    },
    computed: {
        ...mapState(useInterviewStore, ['interview', 'loading', 'transcript', 'surveys', 'professionalAttachments', 'patientAttachments', 'noteCount', 'backing']),
        /**
         * The shortcuts.
         *
         * @returns {Array}
         */
        shortcuts() {
            const shortcuts: any[] = [...this.initialShortcut];

            if (this.loading.transcript || this.transcript) {
                shortcuts.push({
                    title: `Transcript${this.loading.transcript ? ' ...' : ''}`,
                    component: InterviewTranscript,
                    minWidth: '400px'
                });
            }

            const autoEvaluationStep = this.backing?.steps.find(step => step.auto_evaluation);
            if (this.backing && !!autoEvaluationStep?.completed_at) {
                const latestInstance = autoEvaluationStep.interview_instances?.sort((a, b) => {
                    if (!a.ended_at) {
                        return 1;
                    }

                    if (!b.ended_at) {
                        return -1;
                    }

                    return dayjs(b.ended_at).unix() - dayjs(a.ended_at).unix();
                })[0] ?? null;

                if (latestInstance) {
                    shortcuts.unshift({
                        title: 'Auto-évaluation',
                        component: markRaw(InterviewInstanceSummary),
                        minWidth: '50%',
                        highlight: true,
                        componentProps: {
                            instance: latestInstance
                        }
                    });
                }
            }

            return shortcuts
                .filter((shortcut) => {
                    switch (shortcut.slug) {
                    case 'knowledge-box':
                        return (this.interview?.knowledgeables?.length || 0) > 0;
                    default:
                        return true;
                    }
                })
                .map((shortcut) => {
                    switch (shortcut.slug) {
                    case 'knowledge-box':
                        return {
                            ...shortcut, componentProps: {
                                knowledgeables: this.interview?.knowledgeables,
                            }
                        };
                    default:
                        return shortcut;
                    }
                });
        }
    },
    methods: {
        select(index: number) {
            this.component = {
                component: this.shortcuts[index].component ?? null,
                componentProps: this.shortcuts[index].componentProps ?? {},
                minWidth: this.shortcuts[index].minWidth ?? '300px'
            };
        }
    }
});
</script>
