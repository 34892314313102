<template>
  <div class="flex flex-1 flex-row flex-wrap gap-x-3 gap-y-3">
    <div v-for="filter in [1,2,3,4,5,6,7,8]" :key="filter">
      <Skeleton class="rounded-full bg-gray-300 h-9 w-32" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {Skeleton} from "@/core/components/ui/skeleton";
</script>
