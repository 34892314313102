<template>
  <div class="max-h-screen overflow-y-auto flex flex-col">
    <div class="container mx-auto flex flex-col py-4 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-row justify-between">
        <div class="bg-gradient-to-r from-darkPurple-default to-darkBlue-default p-4 rounded-3xl">
          <div class="flex flex-row items-center">
            <img
              alt="logo white"
              class="ml-3 mr-6 hidden sm:block"
              :style="{'objectFit': 'scale-down', 'width': '50px'}"
              :src="logoApodisWhite"
            >
            <div class="text-white text-3xl font-extrabold flex flex-col">
              <div class="flex flex-col flex-wrap sm:flex-row">
                <div class="text-white mr-4">
                  Bienvenue
                </div>
                <div class="text-secondary">
                  {{ accessPoint && accessPoint.user.name + " 👋" }}
                </div>
              </div>
              <div class="text-lg text-white capitalize">
                {{ accessPoint && accessPoint.entity.name.toLowerCase() }}
              </div>
            </div>
          </div>
        </div>
        <button
          class="ml-4"
          @click="logout"
        >
          <img
            alt="logout"
            title="Se déconnecter"
            :src="iconLogout"
          >
        </button>
      </div>
      <div>
        <h1 class="text-3xl text-primary text-center mt-10 mb-4">
          Aidez vos patients à devenir acteurs de leur santé !
        </h1>
        <h2 class="font-heading text-base text-primary text-center mb-10 mx-auto max-w-4xl">
          Installez dès maintenant <b>Santé Secure</b>, <b>Apodis Pro</b> et <b>Apodis Connect</b> en suivant nos guides
          afin de profiter pleinement de vos nouveaux services.
        </h2>
      </div>
      <div class="flex flex-col">
        <div class="font-heading text-xl font-extrabold text-primary mb-2">
          Simplifiez votre métier au quotidien
        </div>
        <installation-process-tab
          :access-point="accessPoint"
          @open-guide="(guideId) => showGuide(guideId)"
        />
      </div>
    </div>
    <div class="container mx-auto flex flex-col py-4 px-4 sm:px-6 lg:px-8 mt-2">
      <div class="flex flex-col self-center items-center">
        <div class="font-heading self-start text-xl font-semibold text-primary mb-2">
          Tutoriels
        </div>
        <div class="flex rounded-3xl shadow-primary shadow-md bg-white mb-10 px-4 shrink">
          <div class="flex shrink overflow-x-auto overflow-hidden my-4">
            <div class="flex w-max p-2 items-center">
              <p
                class="text-3xl font-semibold text-shadow-md font-heading min-w-fit"
                style="writing-mode: vertical-rl; text-orientation: upright; color: #3513E2; letter-spacing: -0.6em"
              >
                TUTOS
              </p>
            </div>
            <div
              v-for="(guide, index) in guides"
              :key="index"
              class="flex-none mx-2 h-48 bg-primary rounded-lg cursor-pointer overflow-hidden"
              :style="{
                'background-image': 'url(' + guide.fields.preview.fields.file.url + ')',
                'backgroundPosition': 'center center',
                'backgroundRepeat': 'no-repeat',
                'backgroundSize': 'cover'
              }"
              @click="showGuide(guide.sys.id)"
            >
              <div class="flex items-end h-48">
                <div class="text-white p-2 bg-primary">
                  {{ guide.fields.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <security />
    <div class="container mx-auto flex flex-col py-4 px-4 sm:px-6 lg:px-8">
      <div
        v-if="showFirstSteps"
        class="flex flex-col self-center items-center"
      >
        <div class="font-heading self-start text-xl font-semibold text-primary mb-2">
          Premiers pas
        </div>
        <div class="flex rounded-3xl shadow-black shadow-xl bg-white mb-10 px-4 shrink">
          <div class="flex shrink overflow-x-auto overflow-hidden my-4">
            <div class="flex w-max p-2 items-center">
              <p
                class="text-3xl font-semibold text-shadow-md font-heading min-w-fit"
                style="writing-mode: vertical-rl; text-orientation: upright; color: #3513E2; letter-spacing: -0.6em"
              >
                TUTOS
              </p>
            </div>
            <div
              v-for="(guide, index) in firstStepsGuides"
              :key="index"
              class="flex-none mx-2 h-48 bg-primary rounded-lg cursor-pointer overflow-hidden"
              :style="{
                'background-image': 'url(' + guide.fields.preview.fields.file.url + ')',
                'backgroundPosition': 'center center',
                'backgroundRepeat': 'no-repeat',
                'backgroundSize': 'cover'
              }"
              @click="showGuide(guide.sys.id)"
            >
              <div class="flex items-end h-48">
                <div class="text-white p-2 bg-primary">
                  {{ guide.fields.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col self-center items-center w-2/3">
        <div class="font-heading self-start text-xl font-semibold text-primary mb-2">
          Pour aller plus loin
        </div>
        <div
          v-if="tutorials.length !== 0"
          class="grid grid-cols-2 place-items-center gap-2 w-full"
        >
          <div
            v-for="(item, index) in tutorials"
            :key="index"
            class="w-full p-2"
          >
            <iframe
              :src="'https://www.youtube.com/embed/' + item.snippet.resourceId.videoId + '?rel=0&autoplay=0&showinfo=0&controls=0'"
              width="100%"
              height="200"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col self-center items-center w-2/3 mt-8">
        <div class="font-heading self-start text-xl font-semibold text-primary mb-2">
          Replay de nos webinaires
        </div>
        <div
          v-if="webinars.length !== 0"
          class="grid grid-cols-2 place-items-center gap-2 w-full"
        >
          <div
            v-for="(item, index) in webinars"
            :key="index"
            class="w-full p-2"
          >
            <iframe
              :src="'https://www.youtube.com/embed/' + item.snippet.resourceId.videoId + '?rel=0&autoplay=0&showinfo=0&controls=0'"
              width="100%"
              height="200"
            />
          </div>
        </div>
      </div>
      <div class="mt-8 space-y-3">
        <div class="font-heading text-3xl font-semibold text-primary text-center">
          Besoin d'aide ?
        </div>
        <div class="font-heading text-base text-primary text-center mb-8 mx-auto max-w-4xl">
          L'équipe d'Apodis vous accompagne à chaque étape de votre parcours<br>afin d'utiliser au mieux vos services.
          <br>
          Contactez-nous par
          <span
            class="font-bold underline cursor-pointer"
            @click="openChat()"
          >
            chat
          </span>
          ,
          <a
            class="font-bold underline"
            href="mailto:contact@apodispharma.com"
          >
            email
          </a>
        </div>
      </div>
      <GuideModal
        v-if="isGuideModalVisible"
        :guide="currentGuide"
        @close="isGuideModalVisible = false"
      />
      <BaseModal
        v-show="isAppointmentModalVisible"
        :title="'Prise de rendez-vous'"
      >
        <!-- Start of Meetings Embed Script -->
        <div
          class="meetings-iframe-container"
          :data-src="supportUrl"
        />
        <!-- End of Meetings Embed Script -->

        <template #footer>
          <BaseButton @click="isAppointmentModalVisible = false">
            Fermer
          </BaseButton>
        </template>
      </BaseModal>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {authenticationService, contentfulService} from '@/container';
import GuideModal from '@/onboarding/components//guide/GuideModal.vue';
import BaseModal from '@/core/components/base/BaseModal.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import axios from 'axios';
import Security from '@/onboarding/components/security/Security.vue';
import InstallationProcessTab from "@/onboarding/components/installation-process/InstallationProcessTab.vue";
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";
import logoApodisWhite from '@/assets/images/logos/apodis-white.svg';
import iconLogout from '@/assets/icons/icon-logout.png';

export default defineComponent({
    name: 'OnboardingView',

    components: {
        Security,
        InstallationProcessTab,
        GuideModal,
        BaseModal,
        BaseButton
    },

    data() {
        return {
            accessPoint: null as any,
            guides: [] as any[],
            firstStepsGuides: [] as any[],
            currentGuide: {} as any,
            isGuideModalVisible: false,
            isAppointmentModalVisible: false,
            santeSecureGuideId: null as string | null,
            connectGuideId: null as string | null,
            proGuideId: null as string | null,
            tutorials: [] as any[],
            webinars: [] as any[],
            logoApodisWhite,
            iconLogout
        };
    },

    computed: {
        ...mapState(useSystemStore, ['environment']),
        shouldShowProModal: function () {
            if (localStorage) {
                if (localStorage.getItem('proModalViewed')) {
                    return false;
                }
            }
            return true;
        },
        showFirstSteps() {
            return !!this.firstStepsGuides.length;
        },
        supportUrl() {
            return import.meta.env.VITE_SUPPORT_URL + '?embed=true';
        }
    },

    mounted() {
        const playlistTutorialId = 'PLsBHFpvnM0qIIFxMb9zQfxUyDwDxla2zU';
        axios.get('https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=' + playlistTutorialId + '&key=' + import.meta.env.VITE_YOUTUBE_API_KEY + '&maxResults=1337')
            .then(resp => {
                this.tutorials = resp.data.items;
            });

        const playlistWebinarId = 'PLsBHFpvnM0qIvzdq_zS-rtUn5dVK7Iy5i';
        axios.get('https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=' + playlistWebinarId + '&key=' + import.meta.env.VITE_YOUTUBE_API_KEY + '&maxResults=1337')
            .then(resp => {
                this.webinars = resp.data.items;
            });

        this.accessPoint = this.environment;
        const systemStore = useSystemStore();
        systemStore.$subscribe((mutation, state) => {
            this.accessPoint = state.environment;
        });
        contentfulService()
            .getSpecificTagGuide('install')
            .then(guides => {
                this.guides = guides;
            });

        contentfulService()
            .getSpecificTagGuide('first-steps')
            .then(guides => {
                this.firstStepsGuides = guides;
            });

        this.santeSecureGuideId = contentfulService().getSanteSecureGuideId();
        this.connectGuideId = contentfulService().getConnectGuideId();
        this.proGuideId = contentfulService().getProGuideId();


        if (this.$root) {
            this.$root.$emit('show-guide', (guideId) => {
                this.showGuide(guideId);
            });
        }
    },

    methods: {
        openAppStore() {
            const link = import.meta.env.VITE_PRO_APPSTORE_URL;
            window.open(link);
        },

        openPlayStore() {
            const link = import.meta.env.VITE_PRO_PLAYSTORE_URL;
            window.open(link);
        },

        downloadConnect() {
            const link = import.meta.env.VITE_CONNECT_EXE_URL;
            window.open(link);
        },

        downloadSanteSecure() {
            const link = import.meta.env.VITE_APODISSECURE_EXE_URL;
            window.open(link);
        },

        showGuide(guideId) {
            contentfulService()
                .getGuide(guideId)
                .then(guide => {
                    this.isGuideModalVisible = true;
                    this.currentGuide = guide;
                });
        },

        openChat() {
            window.HubSpotConversations.widget.open();
        },

        logout() {
            authenticationService().logout();

            this.$router.push({
                name: 'login'
            });

            if (window.ipcRenderer) {
                window.ipcRenderer.send('unauthenticated');
            }
        }
    }
});
</script>
