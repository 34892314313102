<script setup lang="ts">

import {NotificationPlaceholders} from "@/core/interfaces/MassCertification";
import {ref, useTemplateRef} from "vue";
import {massCertificationApi} from "@/container";
import BaseButton from "@/core/components/base/BaseButton.vue";
import debounce from "lodash.debounce";
import {useCommunicationStore} from "@/communication/stores/communication.store";

const {content: storedContent, contentCost} = useCommunicationStore();
const store = useCommunicationStore();

defineProps<{ placeholders: NotificationPlaceholders | undefined }>();
const emit = defineEmits(['selected']);
const content = ref<string>(storedContent || '');
const cost = ref<number>(contentCost || 1);
const editorRef = useTemplateRef<HTMLTextAreaElement>('editorRef');
const cursorPosition = ref(0);

/**
 * Update cursor position and fetch cost.
 */
const onInput = () => {
    cursorPosition.value = editorRef.value?.selectionStart || 0;

    if (content.value.length === 0) {
        cost.value = 1;
        return;
    }

    return fetchCost();
};

/**
 * Fetch the cost of the message from Api.
 */
const fetchCost = debounce(() => {
    massCertificationApi().smsCost(content.value)
        .then(response => cost.value = response);
}, 1000);

/**
 * Insert a variable at the current cursor position.
 *
 * @param event  The event.
 * @param {string} variable The variable to insert.
 */
const insertPlaceholder = (event: Event, variable: string) => {
    event.preventDefault();
    const before = content.value.slice(0, cursorPosition.value);
    const after = content.value.slice(cursorPosition.value);
    const newValue = `${before}${variable} ${after}`;

    const newPosition = cursorPosition.value + variable.length + 1;

    content.value = newValue;
    cursorPosition.value = newPosition;

    setTimeout(() => {
        if (editorRef.value) {
            editorRef.value.focus();
            editorRef.value.setSelectionRange(newPosition, newPosition);
        }
    }, 0);
};

/**
 * Select the current content.
 */
const select = () => {
    store.contentCost = cost.value;
    emit('selected', content);
};
</script>

<template>
  <div class="flex flex-col gap-4 py-2">
    <div class="flex gap-6">
      <textarea
        ref="editorRef"
        v-model="content"
        class="bg-white flex min-h-[60px] w-full rounded-md border border-slate-200 px-3 py-2 text-sm shadow-sm placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300'"
        @input="onInput"
      />
      <div class="flex flex-col gap-2 items-center">
        <BaseButton primary @click="select">
          Sélectionner
        </BaseButton>
        <div class="text-primary text-sm">
          {{ cost }} {{ $filters.pluralize('crédit', cost) }} SMS
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div>
        Cliquez pour ajouter une variable
      </div>
      <div class="flex flex-wrap gap-2">
        <button
          v-for="(placeholder, key) in placeholders"
          :key="placeholder"
          class="rounded-lg bg-white px-4 py-1"
          @click="(event: Event) => insertPlaceholder(event, key)"
        >
          {{ placeholder }}
        </button>
      </div>
    </div>
  </div>
</template>
