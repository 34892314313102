<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import useLaboratory from "@/laboratory/composables/useLaboratory";
import LaboratoryStatisticCard from "@/laboratory/components/LaboratoryStatisticCard.vue";
import useLaboratoryConsumerAnalysis, {
    LaboratoryConsumerAnalysisSearchParams
} from "@/laboratory/composables/useConsumerAnalysis";
import {computed, reactive} from "vue";
import {currencyze} from "@/core/filters/Currencyze";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import Icon from "@/core/components/icon/Icon.vue";
import debounce from "lodash.debounce";
import dateHelper from "@/core/helpers/dateHelper";
import dayjs from "dayjs";
import {useRouter} from "vue-router";
import LaboratoryConsumerPodium from "@/laboratory/components/LaboratoryConsumerPodium.vue";
import {pluralize} from "@/core/filters/Pluralize";

const router = useRouter();
const props = defineProps<{
    laboratoryId: string;
}>();

const dateOptions = [
    {
        label: "Sur 1 mois",
        value: dayjs().subtract(1, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 3 mois",
        value: dayjs().subtract(3, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 1 an",
        value: dayjs().subtract(1, "year").format("YYYY-MM-DD")
    },
    {
        label: "Sur 3 ans",
        value: dayjs().subtract(3, "year").format("YYYY-MM-DD")
    }
];

const headers = [
    {
        title: "Patient",
        key: "name",
        sortable: false
    },
    {
        title: "CA TTC",
        key: "revenue"
    },
    {
        title: "Panier moyen TTC",
        key: "average_cart_price"
    },
    {
        title: "Unités achetées",
        key: "units"
    },
    {
        title: "Visites",
        key: "cart_count"
    },
    {
        title: "Dernier achat",
        key: "last_visit"
    }
];

let searchParams = reactive<LaboratoryConsumerAnalysisSearchParams>({
    size: 20,
    page: 1,
    since: dateOptions[2].value,
    sortBy: undefined,
    order: undefined,
    search: undefined
});

const {data: laboratory} = useLaboratory(props.laboratoryId);
const {
    data,
    isLoading,
    isValidating
} = useLaboratoryConsumerAnalysis(props.laboratoryId, searchParams);

const debounceSearch = debounce((event) => {
    searchParams.search = (event.target as HTMLInputElement)?.value.length
        ? (event.target as HTMLInputElement).value : undefined;
}, 1000);

const _data = computed(() => {
    return data.value?.data.map((item) => {
        return {
            name: item.consumer?.information?.name || "Patient Anonyme",
            birth_date: item.consumer?.information?.birth_date ?? null,
            revenue: currencyze(item.revenue),
            average_cart_price: currencyze(item.average_cart_revenue),
            units: item.units,
            cart_count: item.cart_count,
            last_visit: dateHelper.shortFormat(item.last_visit),
            key: item.consumer_key
        };
    });
});

const averageCartValue = computed(() => {
    if (!data.value) {
        return 0;
    }

    return `${currencyze(data.value?.meta.average_price)} TTC / ${data.value?.meta.average_quantity}u`;
});

/**
 * Compute faithfulness ratio.
 */
const faithfulness = computed(() => {
    if (!data.value) {
        return 0;
    }

    if (data.value.meta.total_consumer_count === 0) {
        return "0%";
    }

    const ratio = ((data.value.meta.faithful_consumer_count / data.value.meta.total_consumer_count) * 100).toFixed(1);

    return `${ratio}% (${data.value.meta.faithful_consumer_count} ${pluralize("patient", data.value.meta.faithful_consumer_count)})`;
});

const tooltipText = computed(() => {
    return `Depuis le ${dateHelper.shortFormat(searchParams.since)}`;
});

/**
 * Handle item per page update.
 *
 * @param _itemsPerPage
 */
const handleItemPerPageUpdate = (_itemsPerPage: number) => {
    searchParams = {
        ...searchParams,
        size: _itemsPerPage,
        page: 1
    };
};

/**
 * Handle page update.
 *
 * @param _page
 */
const handlePageUpdate = (_page) => {
    searchParams.page = _page;
};

/**
 * Handle sort by update.
 *
 * @param _sortBy
 */
const handleSortByUpdate = (_sortBy: {
    key: "revenue" | "average_cart_price" | "units" | "cart_count" | "last_visit",
    order: "asc" | "desc"
}[]) => {
    searchParams.page = 1;
    searchParams.sortBy = _sortBy[0]?.key ?? null;
    searchParams.order = _sortBy[0]?.order ?? null;
};

/**
 * Handle row click.
 *
 * @param event
 * @param row
 */
const handleRowClick = (event: PointerEvent, row) => {
    if (!row.item.key) {
        return;
    }

    router.push({
        name: "laboratory.sales",
        query: {
            consumer_key: row.item.key,
            entity_name: row.item.name,
            since: searchParams.since
        }
    });
};
</script>

<template>
  <div class="p-6 space-y-6">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-3">
      <div v-if="(data?.meta.top_consumers?.length ?? 0) >= 3" class="md:col-span-2">
        <LaboratoryConsumerPodium :data="data?.meta.top_consumers ?? []" />
      </div>
      <LaboratoryStatisticCard
        class="self-end"
        label="Patients" :value="data?.meta.total_consumer_count ?? 0"
        :period-label="tooltipText"
      />
      <LaboratoryStatisticCard
        class="self-end" label="Panier moyen" :value="averageCartValue"
        :period-label="tooltipText"
      />
      <LaboratoryStatisticCard
        class="self-end"
        label="Fidélité"
        :value="faithfulness"
        :period-label="tooltipText"
      />
      <LaboratoryStatisticCard
        class="self-end"
        label="Nouveau patients"
        :value="data?.meta.new_consumers_count ?? 0"
        :period-label="tooltipText"
      />
    </div>
    <BaseCard>
      <BaseCardHeader>
        <h1 class="text-black">
          Liste des patients
        </h1>
        <p v-if="data?.meta.today_consumers !== undefined" class="text-base font-content">
          {{ data?.meta.today_consumers }} patient{{ data?.meta.today_consumers > 1 ? 's' : '' }} ont acheté un produit
          {{ laboratory?.name }} aujourd'hui.
        </p>
      </BaseCardHeader>
      <BaseCardBody>
        <div class="flex justify-between bg-gray-100 p-4 items-center">
          <div class="bg-white rounded-md px-2 flex items-center shadow-md">
            <input
              type="text" class="focus:ring-0 flex-1 w-64" placeholder="Rechercher un patient"
              @input="debounceSearch"
            >
            <Icon name="mdi-magnify" color="primary" />
          </div>
          <BaseSelect
            v-model:modelValue="searchParams.since" trigger-class="justify-end w-auto"
            :options="dateOptions"
          />
        </div>
        <v-data-table-server
          :items="_data"
          :headers="headers"
          :hover="true"
          :items-length="data?.meta.total_consumer_count ?? 0"
          :loading="isLoading || isValidating"
          :items-per-page-options="[10, 20, 30]"
          :items-per-page="searchParams.size"
          :page="searchParams.page"
          @update:items-per-page="handleItemPerPageUpdate"
          @update:page="handlePageUpdate"
          @update:sort-by="handleSortByUpdate"
          @click:row="handleRowClick"
        >
          <template #[`item.name`]="{item}">
            <div>
              <p>{{ item.name }}</p>
              <p v-if="item.birth_date" class="text-xs text-gray-500">
                {{ $filters.date(item.birth_date, "relative", true) }}
              </p>
            </div>
          </template>
        </v-data-table-server>
      </BaseCardBody>
    </BaseCard>
  </div>
</template>
