<script setup lang="ts">
import useSwr from "@/core/composables/swr/useSwr";
import {creditApi} from "@/container";
import BaseModal from "@/core/components/base/BaseModal.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {StripePrice} from "@/stripe/interfaces";
import {onMounted, onUnmounted, ref} from "vue";
import StripeCheckoutService from "@/stripe/services/stripeCheckoutService";
import {useCreditStore} from "@/stores/credit.store";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";

const {data, isLoading} = useSwr('credits/prices', () => creditApi().prices());
const creditStore = useCreditStore();
const loadingStripeCheckout = ref<string>();

defineEmits(['close']);

onMounted(() => {
    window.ipcRenderer.on("stripe-checkout-success", () => {
        creditStore.refresh();
    });
});

onUnmounted(() => {
    window.ipcRenderer.removeAllListeners("stripe-checkout-success");
});

/**
 * Run the checkout process for the selected price.
 */
const buyCredits = async (price: StripePrice) => {
    loadingStripeCheckout.value = price.id;
    await StripeCheckoutService.checkout(price);
    loadingStripeCheckout.value = undefined;
};
</script>

<template>
  <BaseModal title="Achat de crédit SMS" @close="$emit('close')">
    <h1>Sélectionnez un produit</h1>
    <BaseSpinner v-if="isLoading" class="h-full v-full" />
    <ul v-if="data" class="flex flex-col gap-y-4 mt-4">
      <li
        v-for="price in data"
        :key="price.id"
        class="flex border-b border-gray-50 items-center gap-2 border-dotted justify-between cursor-pointer py-2"
      >
        <div class="">
          Pack {{ price.transform_quantity?.divide_by || 1 }} crédits
        </div>
        <BaseButton primary :loading="loadingStripeCheckout === price.id" @click="buyCredits(price)">
          <span class="text-white">Acheter pour {{ price.unit_amount / 100 }} €</span>
        </BaseButton>
      </li>
    </ul>
  </BaseModal>
</template>

<style scoped>

</style>
