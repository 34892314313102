<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {computed} from "vue";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import dayjs from "dayjs";
import {useRouter} from "vue-router";
import PatientFilters from "@/prescription/components/PatientFilters.vue";
import usePatientDashboard from "@/patient-administration/hooks/usePatientDasboard";
import Customer from "@/customer/interfaces/Customer";
import {currencyze} from "@/core/filters/Currencyze";
import dateHelper from "@/core/helpers/dateHelper";
import ProgramLogo from "@/program/components/program-logo/ProgramLogo.vue";
import CustomerSearch from "@/customer/components/customer-search/CustomerSearch.vue";
import Beneficiary from "@/customer/interfaces/Beneficiary";
import Icon from "@/core/components/icon/Icon.vue";
import {storeToRefs} from "pinia";
import {usePatientDashboardStore} from "@/stores/patient-dashboard.store";
import PatientFiltersSkeleton from "@/prescription/components/PatientFiltersSkeleton.vue";

const headers = () => [
    {
        title: "Patient",
        key: "name",
        sortable: false,
        minWidth: "250px"
    },
    {
        title: "CA (Ordos)",
        key: "revenue_prescribed",
        nowrap: true
    },
    {
        title: "CA (Hors-ordos)",
        key: "revenue_others",
        nowrap: true
    },
    {
        title: `Fréquentation \n${currentDateOption.value?.label}`,
        key: "visits",
    },
    {
        title: "Dernière visite",
        key: "last_visit",
    }
];

const dateOptions = [
    {
        label: "1 mois",
        value: dayjs().subtract(1, "month").format("YYYY-MM-DD")
    },
    {
        label: "3 mois",
        value: dayjs().subtract(3, "month").format("YYYY-MM-DD")
    },
    {
        label: "1 an",
        value: dayjs().subtract(1, "year").format("YYYY-MM-DD")
    },
];

const sortBy = defineModel<{
    key: string;
    order: "asc" | "desc";
}[]>
({
    default: [{
        key: "visits",
        order: "desc"
    }]
});

const {searchParams} = storeToRefs(usePatientDashboardStore());
const router = useRouter();
const {data, isLoading, isValidating} = usePatientDashboard(searchParams.value);

const _data = computed(() => {
    return data.value?.data.map((customer: Customer) => {
        return {
            name: customer.information?.name || "Patient Anonyme",
            programs: customer.programs,
            birth_date: customer.information?.birth_date ?? null,
            revenue_prescribed: currencyze(customer.statistics.family?.revenue.prescribed
                || customer.statistics.self?.revenue.prescribed),
            revenue_others: currencyze(customer.statistics.family?.revenue.others
                || customer.statistics.self?.revenue.others),
            visits: customer.statistics.family!.visits || customer.statistics.self!.visits,
            last_visit: dateHelper.shortFormat(customer.statistics.family!.last_visit || customer.statistics.self!.last_visit),
            customerId: customer.id,
            beneficiaries: customer.beneficiaries.map((beneficiary: Beneficiary) => {
                return {
                    programs: beneficiary.programs,
                    beneficiaryId: beneficiary.id,
                    name: beneficiary.information.name,
                    birth_date: beneficiary.information?.birth_date || null,
                    revenue_prescribed: beneficiary.statistics?.self?.revenue.prescribed
                        ? currencyze(beneficiary.statistics?.self?.revenue.prescribed)
                        : null,
                    revenue_others: beneficiary.statistics?.self?.revenue.others
                        ? currencyze(beneficiary.statistics?.self?.revenue.others)
                        : null,
                    visits: beneficiary.statistics?.self?.visits || null,
                    last_visit: beneficiary.statistics?.self?.last_visit
                        ? dateHelper.shortFormat(beneficiary.statistics?.self?.last_visit)
                        : null,
                };
            }),
        };
    });
});

/** Current date option. */
const currentDateOption = computed(() => {
    return dateOptions.find(option => option.value === searchParams.value.from);
});

/**
 * Handle item per page update.
 *
 * @param _itemsPerPage
 */
const handleItemPerPageUpdate = (_itemsPerPage: number) => {
    searchParams.value.limit = _itemsPerPage;
};

/**
 * Handle page update.
 *
 * @param _page
 */
const handlePageUpdate = (_page: number) => {
    searchParams.value.page = _page;
};

/**
 * Handle sort by update.
 *
 * @param _sortBy
 */
const handleSortByUpdate = (_sortBy: { key: string, order: "asc" | "desc" }[]) => {
    searchParams.value.page = 1;
    searchParams.value.sort_by = _sortBy[0]?.key ?? "visits";
    searchParams.value.sort_order = _sortBy[0]?.order ?? "desc";
};

/**
 * Handle name search.
 * @param type The consumer type.
 * @param id The consumer id.
 * @param key The consumer key.
 */
const handlePatientSearch = (type: string | null, id?: string, key?: string) => {
    searchParams.value.consumer_key = type && key ? key : null;
};

/**
 * Handle row click.
 *
 * @param event The event.
 * @param customerId The customer id.
 * @param beneficiaryId The optional beneficiary id.
 */
const handleRowClick = (event: MouseEvent, customerId: number, beneficiaryId?: number) => {
    if (!customerId) {
        return;
    }
    if (beneficiaryId) {
        router.push({name: "customer.beneficiary", params: {customerId, id: beneficiaryId}});
    } else {
        router.push({name: "customer.prescriptions", params: {customerId}});
    }
};
</script>

<template>
  <div class="p-6 space-y-6 pb-24">
    <div>
      <h1>
        Mes patients
      </h1>
      <p class="text-base font-content">
        Analysez votre patientèle, jouez avec les filtres, triez par colonne et fidélisez
        vos patients en les aidant à renouveler leurs ordonnances.
      </p>
      <div class="mt-2 min-h-20">
        <PatientFilters v-if="data?.meta.tags" v-model="searchParams" :tags="data!.meta.tags" />
        <PatientFiltersSkeleton v-else />
      </div>
    </div>
    <BaseCard>
      <div class="bg-white px-4 py-5 border-b border-gray-200 flex items-center justify-between">
        <div class="flex gap-3 items-center">
          <h2>Liste des patients</h2>
          <BaseSelect
            v-model:modelValue="searchParams.from" trigger-class="justify-end w-auto" :options="dateOptions.map(option => {
              return {
                label: `Sur ${option.label}`,
                value: option.value
              };
            })"
          />
        </div>
        <CustomerSearch
          class="w-full max-w-80 bg-gray-50"
          :callback="handlePatientSearch"
          placeholder="Nom du patient ..."
        />
      </div>
      <BaseCardBody>
        <v-data-table-server
          v-model:sort-by="sortBy"
          :items="_data"
          :headers="headers()"
          :hover="true"
          :items-length="data?.meta.total || searchParams.limit"
          :loading="isLoading || isValidating"
          :items-per-page-options="[20,50,100]"
          :items-per-page="searchParams.limit"
          :page="searchParams.page"
          @update:items-per-page="handleItemPerPageUpdate"
          @update:page="handlePageUpdate"
          @update:sort-by="handleSortByUpdate"
          @click:row="handleRowClick"
        >
          <template #[`item`]="{item}">
            <tr class="cursor-pointer" @click="(event) => handleRowClick(event, item.customerId)">
              <td>
                <div class="flex items-center gap-2">
                  <Icon name="mdi-account" />
                  <p>{{ item.name || 'Patient Anonyme' }}</p>
                  <p v-if="item.birth_date" class="text-xs text-gray-500">
                    {{ $filters.date(item.birth_date, "relative", true) }}
                  </p>
                </div>
                <div class="flex gap-2">
                  <div v-for="program in item.programs" :key="item.customerId + '-' + program.id">
                    <v-tooltip :text="program.name">
                      <template #activator="{props}">
                        <ProgramLogo v-bind="props" :program="program" :size="15" />
                      </template>
                    </v-tooltip>
                  </div>
                </div>
              </td>
              <td>{{ item.revenue_prescribed }}</td>
              <td>{{ item.revenue_others }}</td>
              <td>{{ item.visits }}</td>
              <td>{{ item.last_visit }}</td>
            </tr>
            <template v-for="beneficiary in item.beneficiaries" :key="beneficiary.beneficiaryId">
              <tr
                v-if="beneficiary.visits"
                class="text-xs bg-slate-50 cursor-pointer"
                @click="(event) => handleRowClick(event, item.customerId, beneficiary.beneficiaryId)"
              >
                <td class="flex flex-col gap-1 pl-6 pt-1">
                  <div class="flex items-center gap-2">
                    <Icon name="mdi-account-child" />
                    {{ beneficiary.name }}
                    <p v-if="beneficiary.birth_date" class="text-xs text-gray-500">
                      {{ $filters.date(beneficiary.birth_date, "relative", true) }}
                    </p>
                  </div>
                  <div class="flex gap-2">
                    <div v-for="program in beneficiary.programs" :key="item.customerId + '-' + program.id">
                      <v-tooltip :text="program.name">
                        <template #activator="{props}">
                          <ProgramLogo v-bind="props" :program="program" :size="15" />
                        </template>
                      </v-tooltip>
                    </div>
                  </div>
                </td>
                <td>{{ beneficiary.revenue_prescribed || '-' }}</td>
                <td>{{ beneficiary.revenue_others || '-' }}</td>
                <td>{{ beneficiary.visits || '-' }}</td>
                <td>{{ beneficiary.last_visit || '-' }}</td>
              </tr>
            </template>
          </template>
        </v-data-table-server>
      </BaseCardBody>
    </BaseCard>
  </div>
</template>
