<template>
  <div>
    <div class="absolute inset-y-0 right-0 pr-4 flex items-center sm:pr-6 md:hidden">
      <button
        type="button"
        class="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary"
        aria-expanded="false"
        @click="isOpen = true"
      >
        <span class="sr-only">
          Ouvrir le menu
        </span>

        <svg
          class="block h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <div
      v-show="isOpen"
      class="fixed inset-0 z-40 md:hidden"
    >
      <div
        class="hidden sm:block sm:fixed sm:inset-0 md:hidden"
        aria-hidden="true"
      >
        <div class="absolute inset-0 bg-gray-600 opacity-75" />
      </div>

      <nav
        class="fixed z-40 inset-0 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg md:hidden"
        aria-label="Global"
      >
        <div class="h-16 flex items-center justify-between px-4 sm:px-6">
          <button @click="route('home')">
            <img
              class="block h-10 w-auto"
              src="@/assets/images/logos/apodis.svg"
              alt="Apodis"
            >
          </button>

          <button
            type="button"
            class="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary"
            aria-expanded="false"
            @click="isOpen = false"
          >
            <span class="sr-only">
              Fermer le menu
            </span>

            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div class="max-w-8xl mx-auto py-3 px-2 sm:px-4">
          <button
            class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
            @click="route('home')"
          >
            Accueil
          </button>
          <button
            class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
            @click="route('healthcenter')"
          >
            Ma pharmacie
          </button>

          <button
            class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
            @click="route('billing')"
          >
            Mes factures
          </button>
          <button
            class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
            @click="route('notification.preference')"
          >
            Paramètres de notification
          </button>
          <button
            class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
            @click="route('support')"
          >
            Support
          </button>
          <button
            class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
            @click="route('sponsorship')"
          >
            Parrainage
          </button>
        </div>

        <div
          v-if="environment"
          class="border-t border-gray-200 pt-4 pb-3"
        >
          <div class="max-w-8xl mx-auto px-4 flex items-center sm:px-6">
            <div class="flex-shrink-0">
              <span class="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  class="h-full w-full text-gray-300"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </span>
            </div>

            <div class="ml-3 min-w-0 flex-1">
              <div class="text-base font-medium text-gray-800 truncate">
                {{ environment.user.name }}
              </div>

              <div class="text-sm font-medium text-gray-500 truncate">
                {{ environment.user.email }}
              </div>
            </div>
          </div>

          <div class="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
            <div class="block py-2 px-3 text-base font-medium text-gray-900">
              Connecté avec la {{ environment.entity.name }}
            </div>

            <button
              class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
              @click="logout()"
            >
              Déconnexion
            </button>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {
    authenticationService
} from '@/container';
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";

export default defineComponent({
    data: () => ({
        isOpen: false
    }),

    computed: {
        ...mapState(useSystemStore, ['environment'])
    },
    methods: {
        route(name: string) {
            this.$router.push({
                name
            });

            this.isOpen = false;
        },

        logout() {
            authenticationService().logout();

            this.$router.push({
                name: 'login'
            });

            if (window.ipcRenderer) {
                window.ipcRenderer.authenticated({});
            }
        }
    }
});
</script>
