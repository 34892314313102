<script setup lang="ts">
import {ref, watch, computed} from "vue";
import {programApi} from "@/container";
import {SupportProgramSettings} from "@/program/interfaces/Program";
import BaseSelect from "@/core/components/base/BaseSelect.vue";

const props = withDefaults(defineProps<{
        supportProgramId: number,
        labelDirection?: "left" | "right",
        color?: string,
    }>(),
{labelDirection: "right", color: "primary"});

const isLoading = ref(true);
const settings = ref<SupportProgramSettings | null>(null);

/**
 * Load the support program settings.
 */
const loadSettings = async () => {
    isLoading.value = true;
    settings.value = await programApi().supportProgramSettings(props.supportProgramId);
    isLoading.value = false;
};

watch(() => props.supportProgramId, () => {
    loadSettings();
}, {immediate: true});

const notificationEnabled = computed(() => {
    return settings.value?.counter_notifications !== "none";
});

/**
 * Update the model value.
 */
const handleModelValueChange = async (value: string) => {
    if (!settings.value) {
        return;
    }

    settings.value.counter_notifications = value;
    isLoading.value = true;
    settings.value = await programApi().updateSupportProgramSettings(settings.value.id, settings.value);
    isLoading.value = false;
};

const selectItems = computed(() => {
    const values = [{
        value: "all",
        label: "🎯 Notifier tous les patients concernés"
    }];

    if (settings.value?.meta.labels.excluded) {
        values.push({
            value: 'unexcluded',
            label: `Notifier les patients, hors ${settings.value.meta.labels.excluded_short}`
        });
    }

    return values;
});
</script>

<template>
  <div>
    <v-switch
      class="flex-row-reverse"
      :model-value="notificationEnabled"
      :label="labelDirection === 'left' ? undefined : notificationEnabled ? 'Notifications activées' : 'Notifications désactivées'"
      :color="color"
      :disabled="isLoading"
      :loading="isLoading"
      @update:model-value="(value) => handleModelValueChange(value ? 'all' : 'none')"
      @click="(e) => e.stopPropagation()"
    >
      <template #label>
        <div v-if="labelDirection === 'right'">
          <v-label>
            {{
              notificationEnabled
                ? 'Notifications activées'
                : 'Notifications désactivées'
            }}
          </v-label>
          <BaseSelect
            v-if="settings && settings.counter_notifications !== 'none'"
            v-model:modelValue="settings.counter_notifications"
            :options="selectItems"
            trigger-class="max-w-80 bg-transparent border-0 shadow-none"
            @update:model-value="handleModelValueChange"
          />
        </div>
      </template>
      <template v-if="labelDirection === 'left'" #prepend>
        <div class="flex flex-col items-end">
          <v-label>
            {{
              notificationEnabled
                ? 'Notifications activées'
                : 'Notifications désactivées'
            }}
          </v-label>
          <BaseSelect
            v-if="settings && settings.counter_notifications !== 'none'"
            v-model:modelValue="settings.counter_notifications"
            :options="selectItems"
            trigger-class="max-w-80 bg-transparent border-0 shadow-none"
            @update:model-value="handleModelValueChange"
          />
        </div>
      </template>
    </v-switch>
  </div>
</template>
