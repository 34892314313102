<script setup lang="ts">
import TheAuthenticationLayout from '@/core/components/layouts/TheAuthenticationLayout.vue';
import {RouteLocationNormalized, useRouter} from "vue-router";
import {shallowRef, ref, provide, nextTick} from "vue";
import TheEmptyLayout from '@/core/components/layouts/TheEmptyLayout.vue';
import TheApplicationLayout from '@/core/components/layouts/TheApplicationLayout.vue';
import TheNotificationLayout from '@/core/components/layouts/TheNotificationLayout.vue';
import TheWebsiteLayout from '@/core/components/layouts/TheWebsiteLayout.vue';
import SurveyModal from "@/survey/views/SurveyModal.vue";
import SlideToTop from "@/core/transitions/SlideToTop.vue";
import {useToastStore} from "@/stores/toast.store";
import ToastView from "@/core/components/toast/ToastView.vue";
import ConfirmationModal from "@/core/components/modal/ConfirmationModal.vue";
import TheBlankLayout from './core/components/layouts/TheBlankLayout.vue';

const router = useRouter();
const layout = shallowRef('TheApplicationLayout');
const layouts = {
    TheEmptyLayout,
    TheApplicationLayout,
    TheNotificationLayout,
    TheWebsiteLayout,
    TheBlankLayout,
    TheAuthenticationLayout
};

router.afterEach((to: RouteLocationNormalized) => {
    layout.value = to.meta?.layout ? layouts[to.meta.layout as string] : layouts['TheApplicationLayout'];

    logRoute(to);
});

/**
 * Log the route to the console in development environment.
 *
 * @param {RouteLocationNormalized} to The route to log.
 *
 * @returns {void}
 */
const logRoute = (to: RouteLocationNormalized) => {
    if (import.meta.env.DEV) {
        console.log(`Navigated to ${to.fullPath} [${to.name as string}]`);
    }
};

const showModal = ref<{
    survey: boolean,
    confirmation: boolean
}>({
    survey: false,
    confirmation: false
});

const surveyModal = ref<InstanceType<typeof SurveyModal> | null>(null);
const confirmationModal = ref<InstanceType<typeof ConfirmationModal> | null>(null);
const toastStore = useToastStore();

/**
 * Start a survey session by opening the survey modal.
 *
 * @param {Number} surveyId The survey id.
 * @param {String} consumerKey The consumer key for whom the instance is.
 * @param {Number} [pathwayStepId] The optional pathway step id attached to the survey.
 * @param {Number} [supportProgramId] The optional support program id attached to the survey.
 * @param {String} [type] The optional type of the survey (default is survey, otherwise it will be survey-bundle)
 * @param {Object} [options] The optional options to pass to the survey.
 *
 * @returns {void}
 */
const openSurveyModal = async (
    surveyId: number,
    consumerKey: string,
    pathwayStepId?: number,
    supportProgramId?: number,
    type?: string,
    options: Record<string, unknown> = {}
): Promise<void> => {
    showModal.value['survey'] = true;
    await nextTick();
    surveyModal.value?.start({
        consumerKey,
        surveyId,
        pathwayStepId,
        supportProgramId,
        type,
        ...options
    });
};

const openConfirmationModal = async (
    title: string,
    message: string,
    success: () => never | Promise<never>
): Promise<void> => {
    showModal.value['confirmation'] = true;
    await nextTick();
    confirmationModal.value?.show(title, message, success);
};

const closeModal = (type: keyof typeof showModal.value) => {
    showModal.value[type] = false;
};

provide('openSurveyModal', openSurveyModal);
provide('openConfirmationModal', openConfirmationModal);

</script>

<template>
  <v-locale-provider locale="fr">
    <component :is="layout">
      <router-view class="my-5 text-base" />
    </component>
    <Teleport to="#modal">
      <ToastView v-if="toastStore.toasts.length" />
      <slide-to-top>
        <SurveyModal
          v-if="showModal.survey"
          ref="surveyModal"
          @close="() => closeModal('survey')"
        />
      </slide-to-top>
      <ConfirmationModal ref="confirmationModal" v-model="showModal.confirmation" />
    </Teleport>
  </v-locale-provider>
</template>

<style>
@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
    url(assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: local("Poppins-SemiBold"),
    url(assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: "Poppins-Bold";
    src: local("Poppins-Bold"),
    url(assets/fonts/Poppins-Bold.ttf) format("truetype");
}

html, body {
    font-family: 'Poppins', sans-serif;
    overflow: auto !important;
}

#app {
    font-family: 'Poppins', sans-serif;
}

button:focus {
    outline: none;
    box-shadow: none;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #adadad;
}

.gradient {
    @apply bg-gradient-to-r from-darkPurple-default to-darkBlue-default
}
</style>
