<template>
  <div class="flex flex-col pb-3">
    <div class="flex items-center justify-between flex-wrap gap-4">
      <div class="flex items-center flex-1">
        <div class="min-w-80">
          <div class="flex flex-row items-center">
            <div>
              <h2 class="text-black">
                Déroulé de l'entretien
              </h2>
              <p>Suivez les consignes, ajoutez vos commentaires...</p>
            </div>
            <div
              v-if="recording"
              class="mx-4 flex items-center"
            >
              <div class="w-4 h-4 rounded-full bg-red-500 mr-1" />
              <p>{{ timerDisplay }}</p>
            </div>
          </div>
          <p
            v-if="error"
            class="text-red-500 text-sm"
          >
            {{ error }}
          </p>
        </div>
      </div>
      <div class="flex items-center gap-3 flex-wrap">
        <base-button
          v-if="recording"
          class="font-bold text-lg"
          @click="togglePause"
        >
          <div class="flex items-center">
            <icon
              :name="isPaused ? 'mdi-play' : 'mdi-pause'"
              :size="20"
              class="mr-3"
              :color="'#000000'"
            />
            {{ isPaused ? 'Reprendre' : 'Pause' }}
          </div>
        </base-button>
        <InterviewRecordingButton
          :loading="loadingRecording"
          :recording="recording"
          @record="buttonPress"
          @import="onImportAudio"
        />
        <v-file-input
          v-show="false"
          id="input-file"
          accept="audio/*"
          @update:model-value="handleFileUpload"
        />
      </div>
      <microphone-configuration
        :visible="microphoneConfigurationVisible"
        @close="microphoneConfigurationVisible = false"
        @import-audio="onImportAudio"
        @skip-recording="onSkipRecording"
        @finish="onConfigurationEnd"
      />
    </div>
    <audio-vizualizer
      v-show="recording && !isPaused"
      class="mt-2"
      :stream="stream"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Icon from '@/core/components/icon/Icon.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import InterviewAudioService from '@/interview/services/InterviewAudioService';
import MicrophoneConfiguration from '@/interview/components/microphone/MicrophoneConfiguration.vue';
import AudioVizualizer from '@/interview/components/microphone/AudioVizualizer.vue';
import {mapActions, mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";
import InterviewRecordingButton from "@/interview/components/instance-modal/InterviewRecordingButton.vue";

export default defineComponent({
    name: 'InterviewRecording',
    components: {InterviewRecordingButton, AudioVizualizer, MicrophoneConfiguration, BaseButton, Icon},
    emits: ['start', 'skip', 'finish', 'finishUpload'],
    data() {
        return {
            /** The MediaRecorder instance */
            recorder: null as any,
            /** The audio chunks recorded */
            audioChunks: [] as any[],
            /** The date when the recording started */
            startedAt: null as any,
            /** The timer in seconds */
            timer: null as number | null,
            /** The interval to update the timer */
            interval: null as any,
            /** If the recording is paused */
            isPaused: false,
            /** The error to display if any */
            error: null as any,
            /** The audio stream */
            stream: null as any,
            /** The recording device configuration visibility */
            microphoneConfigurationVisible: false,
            /** If the recording is loading */
            loadingRecording: false
        };
    },
    computed: {
        ...mapState(useInterviewStore, ['recording']),
        /** Format the timer in mm:ss format */
        timerDisplay() {
            if (this.timer !== null) {
                const minutes = Math.floor(this.timer / 60);
                const seconds = this.timer % 60;

                const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
                const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

                return `${formattedMinutes}:${formattedSeconds}`;
            }

            return '';
        }
    },
    beforeUnmount() {
        if (this.recorder && this.recorder.state !== 'inactive') {
            this.finish();
        }
    },
    methods: {
        ...mapActions(useInterviewStore, ['setRecording']),
        /**
         * Start recording the audio
         */
        record(deviceId: string) {
            this.$emit("start");
            this.isPaused = false;
            this.timer = 0;
            this.audioChunks = [];
            this.recorder = null;
            this.stream = null;

            const mediaConstraints: any = {
                audio: {
                    sampleRate: 44100,
                    channelCount: 1
                }
            };

            if (!deviceId) {
                this.microphoneConfigurationVisible = true;
                return;
            }

            mediaConstraints.audio.deviceId = {exact: deviceId};
            this.loadingRecording = true;
            return navigator.mediaDevices.getUserMedia(mediaConstraints)
                .then((stream) => {
                    if (!stream?.active || !stream.getAudioTracks().length) {
                        throw new Error('Microphone access denied or not available');
                    }

                    this.stream = stream;
                    this.recorder = new MediaRecorder(this.stream, {mimeType: InterviewAudioService.recordMimeType});

                    this.error = null;

                    this.loadingRecording = false;
                    this.startedAt = new Date();
                    this.recorder.start();
                    this.setRecording(true);

                    this.interval = setInterval(this.updateTimeAgo, 1000);

                    this.recorder.ondataavailable = (e) => {
                        this.audioChunks.push(e.data);
                    };

                    this.recorder.onstop = () => {
                        const audioBlob = new Blob(this.audioChunks, {type: InterviewAudioService.recordMimeType});
                        this.$emit('finish', audioBlob);
                        stream.getTracks().forEach(track => track.stop());
                    };

                })
                .catch(() => {
                    this.loadingRecording = false;
                    this.microphoneConfigurationVisible = true;
                    this.error = 'Une erreur est survenue lors de l\'accès au microphone';
                });
        },
        /**
         * Finish the recording
         */
        finish() {
            this.startedAt = null;
            this.setRecording(false);
            clearInterval(this.interval);
            if (this.recorder) {
                this.recorder.stop();
            }
        },
        /**
         * Toggle the recording
         */
        buttonPress() {
            if (this.recording) {
                this.finish();
            } else {
                this.microphoneConfigurationVisible = true;
            }
        },
        /**
         * Update the timer
         */
        updateTimeAgo() {
            if (!this.startedAt) {
                return;
            }

            this.timer = this.timer !== null ? this.timer + 1 : 0;
        },
        /**
         * Pause or resume the recording
         */
        togglePause() {
            if (this.isPaused) {
                this.recorder.resume();
                this.interval = setInterval(this.updateTimeAgo, 1000);
            } else {
                this.recorder.pause();
                clearInterval(this.interval);
            }

            this.isPaused = !this.isPaused;
        },
        /**
         * Action when the skip recording event is fired in the micro configuration.
         */
        onSkipRecording() {
            this.microphoneConfigurationVisible = false;
            this.$emit("skip");
        }, /**
         * Action when the import audio event is fired in the micro configuration.
         */
        onImportAudio() {
            this.microphoneConfigurationVisible = false;
            this.openFileUpload();
        },
        /**
         * Action when the micro configuration is finished.
         */
        onConfigurationEnd(deviceId: string) {
            this.microphoneConfigurationVisible = false;
            this.record(deviceId);
        },
        /**
         * Open the file upload dialog
         */
        openFileUpload() {
            document.getElementById('input-file')?.click();
        },
        /**
         * Handle the file upload
         */
        handleFileUpload(file: File | File[]) {
            const _file = Array.isArray(file) ? file : [file];
            const blob = new Blob(_file, {type: 'audio/mp3'});

            this.$emit('finishUpload', blob);
        }
    }
});
</script>
