<script setup lang="ts">
import Extension from "@/core/interfaces/extension/Extension";
import Icon from "@/core/components/icon/Icon.vue";
import ExtensionLogo from "@/extension/components/extension-logo/ExtensionLogo.vue";
import extensionNavigationService from "@/extension/services/ExtensionNavigationService";
import {useRouter} from "vue-router";

defineProps<{
    loyaltyCards: Extension[]
}>();

const router = useRouter();
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div>
      <div class="flex items-center gap-2">
        <Icon name="mdi-star" :size="36" />
        <h3>Fidélisez vos patients</h3>
      </div>
      <p>Activez la carte de fidélité et vos patients la retrouveront dans l'app</p>
    </div>
    <div class="flex gap-6">
      <div v-if="!loyaltyCards.length">
        <p class="italic">
          Aucune carte de fidélité associée à ce laboratoire
        </p>
      </div>
      <div
        v-for="extension in (loyaltyCards ?? []).slice(0,3)" :key="extension.id"
        class="shadow-md px-6 py-4 rounded-md flex flex-col items-center gap-4 cursor-pointer"
        @click="extensionNavigationService.navigate(extension, router)"
      >
        <ExtensionLogo :extension="extension" />
        <p class="text-xs">
          {{ extension.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
