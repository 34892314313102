<template>
  <PatientFilterChip
    v-if="selected"
    :selected="selected"
    :label="selectedName"
    icon="mdi-pill"
    class="cursor-pointer"
    @click="select(null, null)"
  />
  <Dialog v-else v-model:open="open" @update:open="clear">
    <DialogTrigger>
      <PatientFilterChip :selected="selected" label="Achats >" icon="mdi-pill" />
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle />
      </DialogHeader>
      <div>
        <SearchInput
          :is-loading="loading"
          class="bg-gray-50"
          placeholder="Produit, laboratoire ou gamme ..."
          @input="search"
        />
      </div>
      <div class="min-h-72 max-h-96 overflow-y-auto">
        <div v-if="results" class="flex flex-col gap-y-3 py-3">
          <div class="flex flex-col gap-y-3">
            <h3>Produits</h3>
            <Table>
              <TableRow
                v-for="product in results.product"
                :key="'product' + product.id"
                class="cursor-pointer"
                @click="select('product', product)"
              >
                <TableCell>{{ product.id }}</TableCell>
                <TableCell>{{ product.cip }}</TableCell>
                <TableCell>{{ product.name }}</TableCell>
              </TableRow>
            </Table>
          </div>
          <div class="flex flex-col gap-y-3">
            <h3>laboratoires</h3>
            <Table>
              <TableRow
                v-for="laboratory in results.laboratory"
                :key="'lab' + laboratory.id"
                class="cursor-pointer"
                @click="select('laboratory', laboratory)"
              >
                <TableCell>{{ laboratory.id }}</TableCell>
                <TableCell>{{ laboratory.name }}</TableCell>
              </TableRow>
            </Table>
          </div>
          <div class="flex flex-col gap-y-3">
            <h3>Games</h3>
            <Table>
              <TableRow
                v-for="range in results.ranges"
                :key="'range' + range.id"
                class="cursor-pointer"
                @click="select('range', range)"
              >
                <TableCell>{{ range.id }}</TableCell>
                <TableCell>{{ range.name }}</TableCell>
              </TableRow>
            </Table>
          </div>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<script lang="ts" setup>
import SearchInput from "@/core/components/search-bar/SearchInput.vue";
import {
    AUTO_COMPLETE_REQUEST_TYPE,
    SearchBarAutoCompleteApiResponse,
    SearchBarAutoCompleteItem
} from "@/core/interfaces/search-bar/ISearchBarAutoComplete";
import {Dialog, DialogTitle, DialogTrigger, DialogContent, DialogHeader} from "@/core/components/ui/dialog";
import PatientFilterChip from "@/prescription/components/PatientFilterChip.vue";
import {searchBarApi} from "@/container";
import debounce from "lodash.debounce";
import {ref} from "vue";
import {Table, TableCell, TableRow} from "@/core/components/ui/table";

defineProps({
    selected: {
        type: Boolean,
        required: true
    }
});

const emits = defineEmits(['selected']);

const loading = ref(false);
const results = ref<SearchBarAutoCompleteApiResponse | null>(null);
const open = ref(false);
const selectedName = ref<string>('');

/**
 * Search function.
 *
 * @param {string} value
 *
 * @return {Promise<SearchBarAutoCompleteApiResponse>}
 */
const search = debounce(async (value: string): Promise<void> => {
    if (!value || value.length <= 3) {
        clear();

        return;
    }

    loading.value = true;
    results.value = await searchBarApi().autocomplete(
        value,
        [
            AUTO_COMPLETE_REQUEST_TYPE.PRODUCT,
            AUTO_COMPLETE_REQUEST_TYPE.LABORATORY,
            AUTO_COMPLETE_REQUEST_TYPE.RANGE
        ],
        5
    ).catch(() => null);
    loading.value = false;
}, 300);

/**
 * Select an item.
 * @param type  The type of the item.
 * @param data  The selected item.
 */
const select = (type: 'product' | 'laboratory' | 'range' | null, data: SearchBarAutoCompleteItem | null) => {
    emits('selected', type, data?.id || null);
    open.value = false;
    selectedName.value = data?.name || '';
};


/**
 * Clear the search results.
 */
const clear = () => {
    results.value = null;
};
</script>
