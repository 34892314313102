import {defineStore} from "pinia";
import {MassCertificationCustomer, NotificationTemplate} from "@/core/interfaces/MassCertification";
import {CommunicationMode} from "@/communication/types";
import {SupportProgramPatient} from "@/program/interfaces/Program";
import {SearchItem} from "@/core/interfaces/search-bar";

export const useCommunicationStore = defineStore('communication', {
    state: () => ({
        customers: [] as MassCertificationCustomer[],
        programCustomers: [] as SupportProgramPatient[],
        template: null as NotificationTemplate | null,
        content: null as string | null,
        contentCost: 1 as number,
        selectedFilters: [] as SearchItem[],
        selectionBatchId: null as string | null,
        programId: null as number | null,
        mode: 'sms' as CommunicationMode
    })
});
