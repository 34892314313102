<script setup lang="ts">
import {SearchItem} from "@/core/interfaces/search-bar";
import {storeToRefs} from "pinia";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import SearchBarService from "@/core/services/SearchBarService";
import QuickItemPicker from "@/core/components/filters/QuickItemPicker.vue";
import SearchBarAutoCompleteService from "@/core/services/SearchBarAutoCompleteService";

const {selectedFilters} = storeToRefs(useCommunicationStore());

/**
 * Action when a filter is deleted.
 * @param item
 */
const onDelete = (item: SearchItem) => {
    const elementIndex = SearchBarService.getItemIndex(item, selectedFilters.value);
    let copy = [...selectedFilters.value];

    if (elementIndex) {
        copy.splice(elementIndex, 1);
    }

    selectedFilters.value = copy;
};

/**
 * Get the item label.
 *
 * @param {SearchItem} item
 * @return {string}
 */
const getItemLabel = (item: SearchItem): string => {
    if (SearchBarAutoCompleteService.isAutoCompleteItem(item)) {
        return item.name;
    }

    return item.label;
};

/**
 * Get the background color of an item.
 *
 * @param {SearchItem} item
 *
 * @return {string}
 */
const getBackgroundColor = (item: SearchItem): string => {
    if (SearchBarAutoCompleteService.isAutoCompleteItem(item)) {
        return SearchBarAutoCompleteService.getColorForSection(item.type) ?? '#fff';
    }

    return '#fff';
};
</script>

<template>
  <div class="flex flex-row flex-wrap">
    <QuickItemPicker
      v-for="(selectedFilter, index) in selectedFilters"
      :key="index"
      :label="getItemLabel(selectedFilter)"
      :style="{'background-color': getBackgroundColor(selectedFilter)}"
      closable
      :selected="false"
      @on-selected="onDelete(selectedFilter)"
    />
  </div>
</template>
