<script setup lang="ts">
import VideoThumbnail from "@/core/components/video/VideoThumbnail.vue";
import Icon from "@/core/components/icon/Icon.vue";
import BaseModal from "@/core/components/base/BaseModal.vue";
import {ref} from "vue";

defineProps<{
    thumbnailVideo: string;
    videos: string[];
    title: string;
}>();

const modalVisible = ref(false);
</script>

<template>
  <div>
    <div class="relative">
      <VideoThumbnail :url="thumbnailVideo" class="w-24 h-24 rounded-full overflow-hidden" />
      <div
        class="absolute inset-0 rounded-full border-4 flex items-center justify-center hover:bg-black/30 transition-colors cursor-pointer"
        @click="modalVisible = true"
      >
        <Icon name="mdi-play-circle-outline" :size="70" color="gray-200" />
      </div>
    </div>
    <BaseModal v-if="modalVisible" :title="title" @close="modalVisible = false">
      <v-carousel hide-delimiter-background :show-arrows="videos.length > 1">
        <v-carousel-item v-for="(video, index) in videos" :key="index">
          <video :src="video" controls class="w-full h-full" />
        </v-carousel-item>
      </v-carousel>
    </BaseModal>
  </div>
</template>

<style scoped>
:deep(.v-window__controls) {
    @apply text-sm pb-1 font-bold;
}

:deep(.v-carousel__controls) {
    display: none;
}
</style>
