<script setup lang="ts">
import useSurveyInstanceBuilt from "@/survey/composables/useSurveyInstanceBuilt";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import SurveyBuiltAnswer from "@/survey/components/SurveyBuiltAnswer.vue";
import BaseContainer from "@/core/components/base/BaseContainer.vue";

const props = defineProps<{
    guideSurveyInstanceId: number
}>();


const {data, isLoading} = useSurveyInstanceBuilt(props.guideSurveyInstanceId);
</script>

<template>
  <div>
    <BaseFlexSpinner v-if="isLoading" />
    <div v-else-if="data">
      <div>
        <div
          v-for="section in data.survey.sections"
          :key="`section-${section.id}`"
        >
          <base-container>
            <div
              v-for="question in section.questions"
              :id="`question-${question.id}`"
              :key="question.id"
              style="scroll-margin: 90px"
            >
              <div class="flex flex-col text-sm mb-1">
                <p>
                  {{ question.title }}
                </p>
              </div>
              <survey-built-answer
                :question="question"
                :survey-id="data.survey.id"
                :editable="false"
                :density="'compact'"
                :value="question.user_answer?.value"
              />
              <v-divider class="mt-4" />
            </div>
          </base-container>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
