import {AxiosInstance} from "axios";

export default function (client: AxiosInstance) {
    return {
        /**
         * Ask knowledge box and stream the response.
         */
        async streamKnowledgeBox(
            knowledgeableId: number,
            onChunkReceived: (text: string) => void, prompt?: string
        ) {
            const controller = new AbortController();

            const request = client({
                url: `knowledgeables/${knowledgeableId}`,
                method: 'post',
                responseType: 'stream',
                data: {prompt},
                signal: controller.signal,
                onDownloadProgress(progressEvent) {
                    onChunkReceived(progressEvent.event.target.response);
                },
            });

            return {
                request,
                abort: () => controller.abort()
            };
        }
    };
}
