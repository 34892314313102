<template>
  <Dialog
    :open="modelValue"
    @update:open="(value) => $emit('update:modelValue', value)"
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ title }}</DialogTitle>
      </DialogHeader>
      <DialogDescription class="text-base">
        {{ message }}
      </DialogDescription>
      <DialogFooter>
        <div class="flex gap-5 self-center">
          <BaseButton
            variant="text"
            :disabled="isLoading"
            class="font-bold"
            @click="cancel"
          >
            Annuler
          </BaseButton>
          <BaseButton
            primary
            :disabled="isLoading"
            :loading="isLoading"
            @click="confirm"
          >
            <span class="font-bold">Confirmer</span>
          </BaseButton>
        </div>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<script setup lang="ts">
/** @component
 * A reusable confirmation dialog component using shadcn UI.
 * @example
 * ```vue
 * <ConfirmationModal
 *   ref="confirmationModal"
 *   v-model="showModal.confirmation"
 * />
 * ```
 */
import {ref} from 'vue';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter
} from "@/core/components/ui/dialog";
import BaseButton from "@/core/components/base/BaseButton.vue";

interface Props {
    /** Controls dialog visibility */
    modelValue: boolean
}

defineProps<Props>();

type EmitTypes = {
    /** Emitted when dialog visibility changes */
    'update:modelValue': [value: boolean]
}

const emit = defineEmits<EmitTypes>();

const title = ref('');
const message = ref('');
const isLoading = ref(false);
const successCallback = ref<undefined | (() => never | Promise<never>)>();

/**
 * Shows the confirmation dialog with the specified parameters
 * @param newTitle - The title to display in the dialog
 * @param newMessage - The message to display in the dialog
 * @param success - Callback function to execute on confirmation
 */
const show = (
    newTitle: string,
    newMessage: string,
    success: () => never | Promise<never>
): void => {
    title.value = newTitle;
    message.value = newMessage;
    successCallback.value = success;
};

/**
 * Handles promise-based confirmation callbacks
 * @param callback - Promise to handle
 */
const handlePromiseCallback = async (callback: Promise<never>): Promise<void> => {
    isLoading.value = true;
    try {
        await callback;
        isLoading.value = false;
        emit('update:modelValue', false);
    } catch {
        isLoading.value = false;
    }
};

/**
 * Handles dialog confirmation
 */
const confirm = (): void => {
    if (!successCallback.value) return;

    const callback = successCallback.value();

    handlePromiseCallback(callback);
};

/**
 * Handles dialog cancellation
 */
const cancel = (): void => {
    emit('update:modelValue', false);
};

defineExpose({show});
</script>
