import {RouteRecordRaw} from "vue-router";
import CommunicationCustomerView from "@/communication/views/CommunicationCustomerView.vue";
import CommunicationMessagePushView from "@/communication/views/CommunicationMessagePushView.vue";
import CommunicationSummaryView from "@/communication/views/CommunicationSummaryView.vue";
import CommunicationMessageStoreView from "@/communication/views/CommunicationMessageStoreView.vue";
import CommunicationMessageSMSView from "@/communication/views/CommunicationMessageSMSView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: 'communication',
        name: 'patient-administration.communication',
        component: CommunicationCustomerView
    },
    {
        path: 'communication/message/notification',
        name: 'patient-administration.communication.message.notification',
        component: CommunicationMessagePushView,
    },
    {
        path: 'communication/message/sms',
        name: 'patient-administration.communication.message.sms',
        component: CommunicationMessageSMSView,
    },
    {
        path: 'communication/summary',
        name: 'patient-administration.communication.summary',
        component: CommunicationSummaryView
    },
    {
        path: 'communication/message/store',
        name: 'patient-administration.communication.message.store',
        component: CommunicationMessageStoreView
    }
];

export default routes;
