<script setup lang="ts">
import BaseLogo from "@/core/components/logo/BaseLogo.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import useLaboratory from "@/laboratory/composables/useLaboratory";
import {computed, nextTick, onMounted, onUnmounted, ref} from "vue";
import LaboratoryWelcome from "@/laboratory/components/LaboratoryWelcome.vue";
import useLaboratoryStatistics from "@/laboratory/composables/useLaboratoryStatistics";
import {animate} from "motion";
import {useLaboratoriesStore} from "@/laboratory/stores/laboratories.store";
import {storeToRefs} from "pinia";
import LaboratoryNavigationMenu from "@/laboratory/components/LaboratoryNavigationMenu.vue";
import useLaboratoryRoutines from "@/laboratory/composables/useLaboratoryRoutines";
import useLaboratoryOffers from "@/laboratory/composables/useLaboratoryOffers";

const props = defineProps<{
    id: string;
}>();
const displayContent = ref(false);
const isGradientShown = ref(false);

const {laboratories} = storeToRefs(useLaboratoriesStore());
const {data} = useLaboratory(props.id);
const {prefetch: prefetchStatistics} = useLaboratoryStatistics(props.id);
const {prefetch: prefetchRoutines} = useLaboratoryRoutines(props.id);
const {prefetch: prefetchOffers} = useLaboratoryOffers(props.id);

onMounted(() => {
    prefetchStatistics();
    prefetchRoutines();
    prefetchOffers();
});

const handleWelcomeAnimationFinished = async () => {
    displayContent.value = true;
    await nextTick();

    animate(".content-container", {'--gradient-opacity': [0, 1]}, {duration: 0.4})
        .then(() => {
            isGradientShown.value = true;
        });

    animate([
        [".laboratory-header", {y: ["-100%", "0%"]}, {duration: 1, ease: "easeInOut"}],
        [".laboratory-content", {opacity: [0, 1], y: ["20px", "0px"]}, {duration: 0.4, at: "-0.2"}]
    ]);
};

onUnmounted(() => {
    isGradientShown.value = false;
});

const laboratory = computed(() => data.value ?? laboratories.value[props.id] ?? null);
</script>

<template>
  <BaseFlexSpinner v-if="!laboratory" />
  <div
    v-if="laboratory"
    class="h-full overflow-auto content-container"
    :style="{
      '--gradient-opacity': isGradientShown ? 1 : 0,
      backgroundImage: laboratory?.branding?.background
        ? `linear-gradient(to top, rgb(255, 255, 255, var(--gradient-opacity)) 0%, rgba(255, 255, 255, 0) 80%), url(${laboratory.branding.background.url})`
        : '',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }"
  >
    <template v-if="displayContent">
      <div
        class="flex bg-white gap-32 pr-6 items-center laboratory-header"
        style="transform: translateY(-100%);"
      >
        <div class="flex-1 bg-white pt-4 px-4 space-y-6">
          <div class="space-y-2">
            <h1 class="text-black">
              Gestion de la relation patient {{ laboratory.name }}
            </h1>
            <div class="border-l-2 border-black pl-3">
              Bonjour, bienvenue dans l'espace de gestion de la relation votre pharmacie avec vos patients {{
                laboratory.name
              }}.
            </div>
          </div>
          <LaboratoryNavigationMenu
            :active-color="laboratory?.branding?.primary_color"
            :active-text-color="laboratory?.branding?.secondary_color"
          />
        </div>
        <BaseLogo alt="logo" :logo="laboratory?.logo" img-class="object-contain" class="bg-white" />
      </div>
      <div class="laboratory-content" style="opacity: 0; transform: translateY(20px)">
        <router-view :laboratory-id="props.id" :laboratory="laboratory" />
      </div>
    </template>
    <div v-else class="w-full overflow-hidden">
      <LaboratoryWelcome class="mt-32" :laboratory="laboratory" @animation-finished="handleWelcomeAnimationFinished" />
    </div>
  </div>
</template>
