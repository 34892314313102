<script setup lang="ts">
import {ref, computed} from "vue";
import Icon from "@/core/components/icon/Icon.vue";
import GlowingContainer from "@/core/components/glowing-container/GlowingContainer.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {knowledgeBoxApi} from "@/container";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import {Knowledgeable} from "@/knowledge-box/interfaces";

const props = defineProps<{ knowledgeables: Knowledgeable[] }>();
const initialSelection = props.knowledgeables.length === 1 ? props.knowledgeables[0] : null;
const selectedKnowledgeBox = ref<Knowledgeable | null>(initialSelection);
const prompt = ref<string | null>(null);
const loading = ref(false);
const result = ref<string | null>(null);
const abortRequest = ref<(() => void) | null>(null);

/**
 * Handle knowledge box click.
 */
const handleKnowledgeBoxClick = (advice: Knowledgeable) => {
    if (!selectedKnowledgeBox.value) {
        return;
    }

    result.value = null;
    prompt.value = null;
    if (selectedKnowledgeBox.value === advice) {
        selectedKnowledgeBox.value = null;
    } else {
        selectedKnowledgeBox.value = advice;
    }
};

/**
 * Handle advice button click.
 */
const handleButtonClick = (advice: Knowledgeable) => {
    result.value = null;
    prompt.value = null;
    selectedKnowledgeBox.value = advice;
};

const placeholder = computed(() => selectedKnowledgeBox.value?.default_prompt);

/**
 * Get advice.
 *
 * @param {Knowledgeable} knowledgeable
 */
const getAdvice = async (knowledgeable: Knowledgeable) => {
    if (abortRequest.value) {
        abortRequest.value();
    }

    loading.value = true;
    const {request, abort} = await knowledgeBoxApi()
        .streamKnowledgeBox(
            knowledgeable.id,
            (event) => {
                result.value = event;
            },
            prompt.value ?? knowledgeable.default_prompt
        );


    abortRequest.value = abort;
    request
        .catch(() => {
            //Dont throw error if request is aborted
        })
        .finally(() => {
            loading.value = false;
            abortRequest.value = null;
        });

    prompt.value = null;
};
</script>

<template>
  <div class="bg-white shadow-md p-4 rounded-lg flex flex-col gap-y-2">
    <h1>
      Demandez conseil à l'IA
      <Icon name="mdi-creation" color="primary" />
    </h1>
    <div class="flex items-center gap-x-12 flex-wrap">
      <div
        v-for="(knowledgeable, index) in knowledgeables"
        :key="knowledgeable.id"
        class="flex items-center gap-2"
      >
        <div
          v-if="knowledgeable.knowledge_box.logo"
          :key="knowledgeable.id"
          class="min-w-24 h-12 max-w-44 mr-2"
          :class="{
            'border-b-2 border-b-primary': selectedKnowledgeBox === knowledgeable,
            'cursor-pointer': !!selectedKnowledgeBox,
          }"
          @click="handleKnowledgeBoxClick(knowledgeable)"
        >
          <img :src="knowledgeable.knowledge_box.logo.url" :alt="`logo_${index}`" class="object-fill h-full">
        </div>
        <BaseButton
          v-if="!selectedKnowledgeBox"
          class="text-primary"
          density="comfortable"
          @click="handleButtonClick(knowledgeable)"
        >
          Poser une question
          <Icon name="mdi-creation" class="ml-1" color="primary" />
        </BaseButton>
      </div>
    </div>
    <GlowingContainer v-if="selectedKnowledgeBox">
      <div class="flex p-1">
        <v-text-field
          v-model="prompt"
          :placeholder="placeholder"
          class="prompt rounded-md pl-2" hide-details density="compact" variant="plain"
          @keydown.enter.prevent="getAdvice(selectedKnowledgeBox)"
        />
        <BaseButton
          v-if="!abortRequest" :loading="loading" class="text-primary" variant="flat"
          @click="getAdvice(selectedKnowledgeBox)"
        >
          Demander
          <Icon name="mdi-creation" color="primary" class="ml-1" />
        </BaseButton>
        <BaseButton v-if="loading && abortRequest" variant="flat" @click="abortRequest">
          <Icon name="mdi-stop-circle-outline" color="primary" />
        </BaseButton>
      </div>
    </GlowingContainer>
    <blockquote v-if="result">
      <MarkdownView class="p-2" :content="result" />
    </blockquote>
  </div>
</template>

<style scoped>
:deep(.prompt input[type="text"]:focus) {
    --tw-ring-shadow: none;
}
</style>
