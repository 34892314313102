<template>
  <div>
    <div class="flex flex-row mt-10">
      <icon
        name="mdi-pill"
        :size="24"
        :color="'#3513e2'"
      />
      <p class="ml-2 text-primary">
        Produits commandés ({{ products.length }})
      </p>
    </div>
    <div
      v-for="(item, index) in products"
      :key="index"
      class="flex flex-row my-4"
    >
      <icon
        name="mdi-cart-outline"
        :size="24"
        :color="'#3513e2'"
      />
      <p class="mx-5">
        {{ item.quantity }}x
      </p>
      <p class="flex flex-1">
        {{ item.product.name }}
      </p>
      <div class="flex flex-col items-end">
        <p
          v-if="'total_price' in item"
          :class="[
            ('offer' in item) && item.offer?.total_price ? 'line-through' : '',
            'text-primary'
          ]"
        >
          {{ $filters.currencyze(item.total_price) }}
        </p>
        <v-tooltip v-if="('offer' in item) && item.offer?.total_price" :text="'Promotion appliquée : ' + item.offer.label">
          <template #activator="{props}">
            <p v-bind="props">
              <Icon name="mdi-sale" :size="20" color="#3513e2" />
              {{ $filters.currencyze(item.offer.total_price) }}
            </p>
          </template>
        </v-tooltip>
      </div>
    </div>
    <div
      v-if="!!order?.user_comment"
      class="flex flex-row mt-4 items-center"
    >
      <p class="my-5">
        Commentaire du patient : {{ order.user_comment }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ComputedRef} from "vue";
import Icon from '@/core/components/icon/Icon.vue';
import Order, {OrderItem} from "@/order/interfaces/Order";
import PrescriptionRenewal from "@/prescription/interfaces/prescription-renewal/PrescriptionRenewal";
import Cart from "@/order/interfaces/Cart";
import PrescriptionRenewalProduct from "@/prescription/interfaces/prescription-renewal/PrescriptionRenewalProduct";

const componentProps = defineProps<{
    order: Order;
}>();

const products: ComputedRef<PrescriptionRenewalProduct[] | OrderItem[]> = computed(() => {
    return (componentProps.order?.content as PrescriptionRenewal)?.products || (componentProps.order?.content as Cart)?.items || [];
});
</script>
