import {AUTO_COMPLETE_SECTION} from "./index";

export type SearchBarAutoCompleteApiResponse = Record<AUTO_COMPLETE_SECTION, SearchBarAutoCompleteItem[]> & {
    [AUTO_COMPLETE_SECTION.CATEGORY]: ISearchBarProductCategoryItem[];
    [AUTO_COMPLETE_SECTION.PRODUCT]: ISearchBarProductItem[];
    [AUTO_COMPLETE_SECTION.LABORATORY]: ISearchBarLaboratoryItem[];
};

/**
 * @interface SearchBarAutoCompleteItem
 * @description The search bar result item.
 *
 * @property {number} id - The ID of the item.
 * @property {string} type - The item's type.
 * @property {string} name - The item's name.
 * @property {*} meta - The item's meta.
 */
export interface SearchBarAutoCompleteItem {
    id: number;
    type: AUTO_COMPLETE_SECTION;
    name: string;
    cip: string;
    meta: any;
}

/**
 * @interface ISearchBarProductItem
 * @extends SearchBarAutoCompleteItem
 * @description Product search result item.
 *
 * @property {string} id_7 - The ID_7 of the product.
 * @property {string} id_13 - The ID_13 of the product.
 * @property {string} code - The products code.
 * @property {string} cip - The products CIP.
 */
export interface ISearchBarProductItem extends SearchBarAutoCompleteItem {
    id_7: string;
    id_13: string;
    code: string;
    cip: string;
}

export interface ISearchBarProductCategoryItem extends SearchBarAutoCompleteItem {
    /** The category code. */
    code: string;
}

/**
 * @interface ISearchBarLaboratoryItem
 * @extends SearchBarAutoCompleteItem
 * @description Laboratory search result item.
 *
 * @property {number} organization_id - The organization ID of the laboratory.
 */
export interface ISearchBarLaboratoryItem extends SearchBarAutoCompleteItem {
    organization_id: number;
}

export enum AUTO_COMPLETE_REQUEST_TYPE {
    CATEGORY = "category.levelX.id",
    PRODUCT = "idProducts",
    LABORATORY = "laboratory.id",
    GENERIC = "generic.id",
    RANGE = "range.id",
    HEALTHCENTER = "idOrgas",
    REGION = "geo.region.id",
    DEPARTMENT = "geo.departement.number",
    CITY = "geo.city.id",
    UGA = "geo.uga",
    SEGMENT_PRODUCT = "segmentProduct"
}

/**
 * Key of query params send to the api.
 */
export enum PRODUCT_SEARCH_QUERY_PARAM_KEYS {
    CATEGORIES = "categoryCodes",
    PRODUCTS = "productIds",
    LABORATORIES = "laboratoryIds",
    GENERICS_GROUP = "genericIds",
    RANGES = "rangeIds",
    VAT_TYPES = "vatTypeIds",
    PRODUCT_TYPES = "productTypeIds",
    REFUND_TYPES = "refundTypeIds",
    HEALTHCENTERS = "healthcenterIds",
    REGIONS = "regionIds",
    DEPARTMENTS = "departmentIds",
    CITIES = "cityIds",
    UGAS = "ugaIds",
    HEALTHCENTER_MARGIN_TYPES = "healthcenterMarginTypes",
    SEGMENT_PRODUCTS = "segmentProductIds"
}

export type ProductSearchQueryParams = {
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.CATEGORIES]?: string[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.PRODUCTS]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.LABORATORIES]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.GENERICS_GROUP]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.RANGES]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.VAT_TYPES]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.PRODUCT_TYPES]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.REFUND_TYPES]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.HEALTHCENTERS]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.REGIONS]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.DEPARTMENTS]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.CITIES]?: number[];
    [PRODUCT_SEARCH_QUERY_PARAM_KEYS.UGAS]?: number[];
};
