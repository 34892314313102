<script setup lang="ts">
import {Routine} from "@/laboratory/interfaces";
import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps<{
    routines: Routine[],
    laboratoryId: string,
    color?: string;
}>();

/**
 * Redirect to the routines page.
 */
const redirectToRoutines = () => {
    router.push({
        name: 'laboratory.routines',
        params: {laboratoryId: props.laboratoryId}
    });
};
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div>
      <div class="flex items-center gap-2">
        <Icon name="mdi-trending-up" :size="36" />
        <h3>Plus de ventes avec les routines</h3>
      </div>
      <p>Les routines proposent aux patients des produits complémentaires à leurs achats</p>
    </div>
    <div class="flex gap-6">
      <div v-if="!routines.length">
        <p class="italic">
          Aucune routine associé à ce laboratoire
        </p>
      </div>
      <div
        v-for="routine in (routines ?? []).slice(0,3)" :key="routine.id"
        class="w-44 shadow-md rounded-b-md cursor-pointer"
        @click="redirectToRoutines()"
      >
        <img :src="routine.image?.url" class="h-32 object-cover w-full" :alt="`routine-${routine.name}`">
        <div class="p-2 pb-6">
          <p class="text-sm">
            {{ routine.name }}
          </p>
        </div>
      </div>
    </div>
    <BaseButton
      v-if="routines.length" variant="text" :style="{
        color: props.color
      }" class="align-self-end" @click="redirectToRoutines"
    >
      Voir toutes les routines
    </BaseButton>
  </div>
</template>

<style scoped>

</style>
