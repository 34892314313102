<template>
  <div class="glowing-container">
    <div class="content relative h-full w-full rounded bg-white">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">

import {computed} from "vue";

const props = withDefaults(
    defineProps<{ colors?: string[]; }>(),
    {
        colors: () => ['#3513e2', '#E2C4FF', '#65efca', '#3513e2'],
    }
);

/**
 * Generate a gradient string from the colors prop and ensure the gradient is circular.
 */
const gradientString = computed(() => {
    const gradientColors = [...props.colors];
    if (gradientColors[0] !== gradientColors[gradientColors.length - 1]) {
        gradientColors.push(gradientColors[0]);
    }

    return gradientColors.map((color, index) => {
        const percentage = (index * 100) / gradientColors.length;
        return `${color} ${percentage}%`;
    }).join(', ');
});
</script>

<style scoped>
.glowing-container {
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    padding: 2px;
}

.glowing-container::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: conic-gradient(from var(--angle), v-bind(gradientString));
    animation: rotate 5s linear infinite;
}

@keyframes rotate {
    from {
        --angle: 0deg;
    }
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}

.content {
    position: relative;
    z-index: 1;
}
</style>
