<script setup lang="ts">
import useLaboratoryRoutines from "@/laboratory/composables/useLaboratoryRoutines";
import useLaboratory from "@/laboratory/composables/useLaboratory";
import {laboratoryApi} from "@/container";
import {computed} from "vue";
import {ProgramType} from "@/program/interfaces/Program";
import LaboratoryHomeRoutines from "@/laboratory/components/LaboratoryHomeRoutines.vue";
import LaboratoryHomeLoyaltyCards from "@/laboratory/components/LaboratoryHomeLoyaltyCards.vue";
import useLaboratoryOffers from "@/laboratory/composables/useLaboratoryOffers";
import LaboratoryHomeOffers from "@/laboratory/components/LaboratoryHomeOffers.vue";

const props = defineProps<{
    laboratoryId: string;
}>();

const {data: laboratory, isLoading} = useLaboratory(props.laboratoryId);
const {data: routines} = useLaboratoryRoutines(props.laboratoryId);
const {data: offers} = useLaboratoryOffers(props.laboratoryId);

const loyaltyCards = computed(() => {
    if (!laboratory.value?.extensions) {
        return [];
    }

    return laboratory.value.extensions.filter((extension) => {
        if ('sub_type' in extension) {
            return extension.sub_type === ProgramType.LOYALTY_CARD;
        }

        return false;
    });
});

/**
 * Handle laboratory partnership status change.
 *
 * @param newValue
 */
const handleEnabledChange = async (newValue: boolean | null) => {
    if (laboratory.value?.organization) {
        laboratory.value.organization.is_enabled = newValue ?? true;

        await laboratoryApi()
            .updatePartnershipStatus(laboratory.value.organization.id, newValue ?? true)
            .then((response) => {
                if (laboratory.value?.organization.is_enabled) {
                    laboratory.value.organization.is_enabled = response.enabled;
                }
            })
            .catch(() => {
                if (laboratory.value?.organization) {
                    laboratory.value.organization.is_enabled = !laboratory.value.organization.is_enabled;
                }
            });
    }
};
</script>

<template>
  <div class="bg-white rounded-md p-4">
    <div class="flex">
      <h1 class="text-black flex-1">
        Tout ce que vos patients retrouveront sur l'application Apodis
      </h1>
      <v-switch
        :label="`Afficher ${laboratory?.name} sur l'app`"
        :model-value="laboratory?.organization.is_enabled"
        :color="laboratory?.branding?.primary_color ?? 'primary'"
        :loading="isLoading"
        @update:model-value="handleEnabledChange"
      />
    </div>
    <div class="flex flex-col lg:flex-row gap-16">
      <div class="flex-1 space-y-8">
        <LaboratoryHomeRoutines v-if="routines?.length" :routines="routines" :laboratory-id="laboratoryId" :color="laboratory?.branding?.primary_color" />
        <LaboratoryHomeOffers v-if="offers" :offers="offers" :laboratory-name="laboratory?.name" :laboratory-id="laboratoryId" :color="laboratory?.branding?.primary_color" />
        <LaboratoryHomeLoyaltyCards v-if="loyaltyCards.length" :loyalty-cards="loyaltyCards" />
      </div>
      <div class="flex justify-center">
        <img alt="App patient preview" src="@/assets/images/preview-app-patient.png" class="h-80 md:h-96 lg:h-[400px]">
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.v-input__details) {
    display: none;
}
</style>
