<template>
  <transition name="slide">
    <slot />
  </transition>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: 'SlideRightToLeft'
});
</script>

<style scoped>
.slide-enter-active, .slide-leave-active {
    transition: transform 0.5s ease;
}

.slide-leave-to /* .slide-leave-active in <2.1.8 */
{
    transform: translateX(100%);
}

.slide-enter-from {
    transform: translateX(100%);
}
</style>
