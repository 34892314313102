<script setup lang="ts">
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {MassCertificationCustomer} from "@/core/interfaces/MassCertification";
import CommunicationNotificationCustomerRow from "@/communication/components/CommunicationNotificationCustomerRow.vue";
import {ref} from "vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useCommunicationStore} from "@/communication/stores/communication.store";

const {customers} = defineProps<{ isLoading: boolean; customers: MassCertificationCustomer[] }>();
const emits = defineEmits(['selected']);
const communicationStore = useCommunicationStore();

const selectedCustomers = ref<MassCertificationCustomer[]>([]);

/**
 * Select/unselect all customers.
 */
const toggleSelection = (allCustomers: MassCertificationCustomer[]) => {
    if (selectedCustomers.value.length === allCustomers.length) {
        selectedCustomers.value = [];
    } else {
        selectedCustomers.value = [...allCustomers];
    }
};

const onSelected = () => {
    communicationStore.customers = selectedCustomers.value;
    emits('selected');
};
</script>

<template>
  <div class="relative">
    <p>
      Sélectionnez vos patients parmi ceux équipés de l'application Apodis, puis choisissez le message à envoyer (une
      notification par patient, par semaine)
    </p>
    <p class="text-sm">
      💡 Astuce : Equipez rapidement vos patients en envoyant des SMS !
    </p>
    <BaseFlexSpinner v-if="isLoading" class="my-4" />
    <div v-else>
      <div v-if="customers.length > 0" class="mt-4">
        <v-checkbox
          :model-value="selectedCustomers.length === customers.length"
          density="comfortable"
          color="primary"
          label="Tout sélectionner"
          class="bg-gray-100 h-16 pt-1 text-primary"
          @click="toggleSelection(customers)"
        >
          <template #label>
            <span class="pl-4">Tout sélectionner</span>
          </template>
        </v-checkbox>
      </div>
      <div
        v-for="customer in customers"
        :key="customer.id"
      >
        <v-checkbox
          v-model="selectedCustomers"
          :value="customer"
          density="comfortable"
          color="primary"
          class="border-b-2 h-24"
        >
          <template #label>
            <CommunicationNotificationCustomerRow :customer="customer" />
          </template>
        </v-checkbox>
      </div>
    </div>
    <BaseButton
      v-if="selectedCustomers.length > 0"
      primary
      class="!fixed bottom-10 right-24"
      @click="onSelected"
    >
      Continuer
    </BaseButton>
  </div>
</template>

<style scoped>
:deep(.v-selection-control .v-label) {
    flex: 1;
    opacity: 1;
}
</style>
