<template>
  <base-modal
    v-if="modalVisible"
    :title="title"
    :container-style="{
      'max-height': '80vh',
      'overflow-y': 'auto !important'
    }"
    @close="$emit('close')"
  >
    <div v-if="isLoading">
      <BaseSpinner />
    </div>
    <div v-else>
      <div
        v-if="!hasEnoughCredit"
        class="mt-5"
      >
        <RouterLink to="/patient-administration/communication/message/store">
          <dot-lottie-vue
            style="height: 300px"
            :loop="false"
            autoplay
            src="animations/sad-bank-card.json"
          />
          <div class="flex flex-row items-center">
            <p class="flex-1">
              Vous n'avez pas suffisamment de crédit. Veuillez <span class="text-primary font-bold">recharger vos crédits SMS</span>
              dans le module relation patients.
            </p>
            <img
              class="ml-4"
              :src="smsPremium"
              width="80"
              height="80"
              alt="SMS Premium"
            >
          </div>
        </RouterLink>
      </div>
      <div v-else>
        <customer-certification-notification-selection
          v-if="!notification"
          :program-id="programId"
          :customer="customer"
          @selected="handleSelected"
        />
        <customer-certification-notification-confirmation
          v-if="notification"
          :notification="notification"
          @on-modify="notification = null"
        />
      </div>
    </div>
    <template
      v-if="!isLoading && hasEnoughCredit"
      #footer
    >
      <certification-notification-footer
        v-if="notification && credit"
        :balance="credit?.balance"
        :customer="customer"
        :notification="notification"
        :on-send="send"
      />
    </template>
  </base-modal>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import {customerCertificationApi, organizationCreditApi} from '@/container';
import BaseModal from '@/core/components/base/BaseModal.vue';
import CertificationNotificationFooter from '@/customer-certification/components/CertificationNotificationFooter.vue';
import CustomerCertificationNotificationSelection
    from '@/customer-certification/views/CustomerCertificationNotificationSelection.vue';
import CustomerCertificationNotificationConfirmation
    from '@/customer-certification/views/CustomerCertificationNotificationConfirmation.vue';
import smsPremium from '@/assets/images/sms-premium.png';
import {DotLottieVue} from '@lottiefiles/dotlottie-vue';
import {useToastStore} from "@/stores/toast.store";
import {mapActions} from "pinia";
import {Credit} from "@/core/interfaces/Credit";
import {NotificationTemplate} from "@/core/interfaces/MassCertification";
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    name: 'CustomerCertificationNotificationModal',
    components: {
        DotLottieVue,
        CustomerCertificationNotificationConfirmation,
        CustomerCertificationNotificationSelection,
        CertificationNotificationFooter,
        BaseModal,
        BaseSpinner,
    },
    props: {
        modalVisible: {
            type: Boolean,
            required: true
        },
        customer: {
            type: Object as PropType<Customer>,
            required: true
        },
        programId: {
            type: Number,
            default: null
        }
    },
    emits: ['close'],
    data: () => {
        return {
            isLoading: false,
            credit: null as Credit | null,
            cost: undefined as number | undefined,
            notification: null as NotificationTemplate | null,
            smsPremium
        };
    },
    computed: {
        /**
         * Check if there is enough credit to send a message.
         *
         * @return {boolean}
         */
        hasEnoughCredit(): boolean {
            return (this.credit?.balance || 0) >= (this.notification?.credits || 1);
        },
        title() {
            return this.notification ? 'Confirmer l\'envoi du SMS' : 'Choisissez le message à envoyer au patient';
        },
        certification() {
            return this.customer.certification || this.customer.pending_certification;
        }
    },
    watch: {
        modalVisible(value) {
            if (!value) {
                this.notification = null;
            } else {
                this.load();
            }
        }
    },
    methods: {
        ...mapActions(useToastStore, ['showSuccess']),
        /**
         * Handle selected message
         *
         * @param {Object} message - Message
         */
        handleSelected(message: NotificationTemplate) {
            this.notification = message;
        },
        /**
         * Load credit.
         */
        load() {
            this.isLoading = true;
            organizationCreditApi()
                .getBalance()
                .then((credit) => {
                    this.credit = credit;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        /**
         * Send the message.
         */
        async send(): Promise<void> {
            if (!this.notification) {
                return Promise.resolve();
            }

            return await customerCertificationApi()
                .createNotification(this.certification.id, this.notification.id)
                .then(() => {
                    this.showSuccess({content: 'Le SMS a été envoyé avec succès.'});
                    window.dispatchEvent(new Event('refresh-customer'));
                    this.$emit('close');
                });
        }
    }
});
</script>
