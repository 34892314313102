<template>
  <div>
    <div class="mt-5">
      <div v-if="loading">
        <BaseSpinner />
      </div>
      <div
        v-else
        class="flex flex-col gap-y-5 relative flex-1"
      >
        <div v-if="notifications?.length">
          <div
            v-for="notification in notifications"
            :key="notification.id"
          >
            <certification-notification-item
              :notification="notification"
              @selected="$emit('selected', notification)"
            />
          </div>
        </div>
        <div v-else>
          <p>Aucune message disponible.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {notificationTemplateApi} from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import CertificationNotificationItem
    from '@/customer-certification/components/CertificationNotificationItem.vue';
import {NotificationTemplate} from "@/core/interfaces/MassCertification";
import Customer from "@/customer/interfaces/Customer";
import {NotificationTemplateIndexParams} from "@/core/http/resources/NotificationTemplateResource";

export default defineComponent({
    name: 'CustomerCertificationNotificationSelection',
    components: {CertificationNotificationItem, BaseSpinner},
    props: {
        programId: {
            type: Number,
            default: null
        },
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    },
    emits: ['selected'],
    data() {
        return {
            notifications: [] as NotificationTemplate[],
            loading: true
        };
    },
    /**
     * On mount, get the message template index.
     */
    mounted() {
        this.loading = true;
        const params: NotificationTemplateIndexParams = {
            type: 'sms',
            customer_id: this.customer.id,
            ...(this.programId ? {program_id: this.programId} : {})
        };

        notificationTemplateApi()
            .index(params)
            .then((messages) => {
                this.notifications = messages;
            })
            .finally(() => {
                this.loading = false;
            });
    }
});
</script>
