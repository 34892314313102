import {AxiosInstance, AxiosResponse} from "axios";
import {
    MassCertificationCustomerApiResponse,
    MassCertificationNotification,
    MassCertificationNotificationBatch,
    MassCertificationNotificationBatchIndex, NotificationPlaceholders,
    NotificationTemplate
} from "@/core/interfaces/MassCertification";
import CustomerNotification from "@/core/interfaces/CustomerNotification";
import {PaginatedData} from "@/core/interfaces/api";
import {CUSTOMER_SEARCH_QUERY_PARAM_KEYS} from "@/core/interfaces/search-bar/ICustomerSearchBar";

interface MassCertificationCustomerResponse {
    /** The customer data. */
    data: MassCertificationCustomerApiResponse[];
    /** The meta data. */
    meta: MassCertificationMetaData;
}

export interface MassCertificationMetaData {
    /** The total record. */
    total: number;
    /** The current page. */
    page: number;
    /** The limit per page. */
    limit: number;
    /** The query. */
    query: Record<CUSTOMER_SEARCH_QUERY_PARAM_KEYS, any[]>;
}

interface MassCertificationCustomerRequest {
    /** The page to request. */
    page?: number;
    /** The limit per page. */
    limit?: number;
    /** Optional query. */
    query?: any;
    /** If default filters should be used or not (default is true). */
    useDefaultFilters?: boolean;
}

interface BaseMassCertificationPostRequest {
    /** The message template id. */
    notification_template_id?: number;
    /** The message content. */
    content?: string;
}

interface MassCertificationPostRequestWithCustomers extends BaseMassCertificationPostRequest {
    /** The customer ids. */
    customer_ids: number[];
}

interface MassCertificationPostRequestWithSelection extends BaseMassCertificationPostRequest {
    /** The batch selection id. */
    batch_selection_id: string;
}

export type MassCertificationPostRequest =
    MassCertificationPostRequestWithCustomers
    | MassCertificationPostRequestWithSelection;

interface NotificationTemplateIndexRequest {
    /** The program id that should scope the templates on. */
    program_id?: number | null;
    /** The type that should scope the templates on. */
    type?: "sms" | "notification";
}

/**
 * The survey resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get customers available for mass certification.
         *
         * @return {any}
         */
        customers(params: MassCertificationCustomerRequest = {}): Promise<MassCertificationCustomerResponse> {
            return client.post("mass-certification/customers", {data: params})
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Get message templates available for mass certification.
         *
         * @param {NotificationTemplateIndexRequest} params - The query params.
         *
         * @return {Promise<NotificationTemplate[]>}
         */
        messageTemplates(params: NotificationTemplateIndexRequest = {}): Promise<NotificationTemplate[]> {
            return client.get("mass-certification/notification-templates", {params})
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Send mass certification.
         *
         * @param {MassCertificationPostRequest} data
         * @return {Promise<void>}
         */
        massCertification(data: MassCertificationPostRequest): Promise<void> {
            return client.post("mass-certification", {data})
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Send mass certification.
         *
         * @param {MassCertificationPostRequest} data
         * @return {Promise<void>}
         */
        notifications(data: MassCertificationPostRequest): Promise<void> {
            return client.post("custom-notifications", {data})
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Count messages sent by the current organization.
         *
         * @return {Promise<number>}
         */
        countMessages(): Promise<number> {
            return client.get("mass-certification/notifications/count")
                .then((response: AxiosResponse) => response.data.data.count);
        },

        /**
         * Count notifications sent by the current organization.
         *
         * @return {Promise<number>}
         */
        countNotifications(): Promise<number> {
            return client.get("notifications/count")
                .then((response: AxiosResponse) => response.data.data.count);
        },

        /**
         * List notifications sent by the current organization.
         *
         * @return {Promise<PaginatedData<CustomerNotification[]>>}
         */
        customNotifications(page = 1, limit = 20): Promise<PaginatedData<CustomerNotification[]>> {
            const params = {
                page,
                limit
            };

            return client.get("custom-notifications", {params})
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Get message sent by batches.
         *
         * @return {Promise<PaginatedData<MassCertificationNotificationBatchIndex[]>>}
         */
        indexBatches(page = 1, limit = 20): Promise<PaginatedData<MassCertificationNotificationBatchIndex[]>> {
            const params = {
                page,
                limit
            };

            return client.get("mass-certification/batches", {params})
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Read a certification batch.
         *
         * @param {number} id The batch id.
         * @return {Promise<MassCertificationNotificationBatch>}
         */
        readBatch(id: number): Promise<MassCertificationNotificationBatch> {
            return client.get(`mass-certification/batches/${id}`)
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Get the batch messages.
         *
         * @param {number} id - The batch id.
         * @param {number} page - The page to fetch.
         * @param {number} limit - The limit per page.
         *
         * @return {Promise<PaginatedData<MassCertificationNotification[]>>}
         */
        readBatchMessages(id: number, page = 1, limit = 20): Promise<PaginatedData<MassCertificationNotification[]>> {
            const params = {
                page,
                limit
            };

            return client.get(`mass-certification/batches/${id}/notifications`, {params})
                .then((response: AxiosResponse) => response.data);
        },

        /** Request SMS cost based on content. */
        async smsCost(content: string): Promise<number> {
            return client.post("mass-certification/notifications/cost", {content})
                .then((response: AxiosResponse) => response.data.data);
        },

        /** Request available placeholders for notification. */
        async placeholders(): Promise<NotificationPlaceholders> {
            return client.get("mass-certification/notifications/placeholders")
                .then((response: AxiosResponse) => response.data.data);
        }
    };
};
