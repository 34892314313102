import {AxiosInstance, AxiosResponse} from 'axios';
import {jwtDecode} from "jwt-decode";

export interface StorageService {
    read: (key: string) => string | null;
    store: (key: string, value: string) => void;
    destroy: (key: string) => void;
}

interface User {
    currentAccess: {
        organization: {
            morphable_type: string;
            rankId: number;
        };
    };
    token: string;
}

interface Response {
    data: {
        user: User;
        token: string;
    };
}

export default function (client: AxiosInstance, storageService: StorageService) {
    return {
        /**
         * Check if user is authenticated by checking if token is valid in local storage.
         */
        get authenticated(): boolean {
            const token = storageService.read('token');

            if (!token) {
                return false;
            }

            const decoded = jwtDecode<any>(token);

            return decoded && decoded.exp * 1000 > Date.now();
        },
        /**
         * Read user from jwt.
         * @returns {object}
         */
        get user() {
            const token = storageService.read('token');
            if (token) {
                const decoded = jwtDecode<any>(token);

                return decoded && decoded.user;
            }
        },

        /**
         * Read access from jwt.
         * @returns {object}
         */
        get access() {
            const token = storageService.read('token');
            if (token) {
                const decoded = jwtDecode<any>(token);
                return decoded && decoded.access;
            }
        },

        /**
         * Login as a given user, using an authentication key.
         * @param key  The authentication key.
         * @param access_id  The access id.
         */
        async loginAs(key: string, access_id: number): Promise<User | null> {
            const response = await client.post('/authenticate', {key, access_id}, {
                params: {
                    include: 'currentAccess.organization'
                }
            })
            ;
            storageService.store('token', response.data.token);

            return response.data.user;
        },

        /**
         * Login with email and password.
         * @param email
         * @param password
         */
        login(email: string, password: string): Promise<User | null> {
            return client
                .post<Response>('/authenticate', {}, {
                    params: {
                        include: 'currentAccess.organization'
                    },

                    auth: {
                        username: email,
                        password: password
                    }
                })
                .then((response: AxiosResponse) => {
                    const organization = response.data
                        .user
                        .currentAccess
                        .organization;

                    if (organization.morphable_type !== 'healthcenter') {
                        throw new Error('Sélectionnez un environnement « pharmacie » sur Apodis');
                    }

                    if (response.data.user.currentAccess.rankId !== 1 && response.data.user.currentAccess.rankId !== 2) {
                        return null;
                    } else {
                        storageService.store('token', response.data.token);
                    }

                    return organization;
                });
        },

        logout(): void {
            storageService.destroy('token');
        },

        forgotPassword(email: string): Promise<object> {
            return client
                .post('/users/forgotPassword', {email})
                .then(response => {
                    return response.data;
                });
        }
    };
}
