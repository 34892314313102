<script setup lang="ts">
import useLaboratoryConsumerAnalysis, {
    LaboratoryConsumerAnalysisSearchParams
} from "@/laboratory/composables/useConsumerAnalysis";
import dayjs from "dayjs";
import {computed, ComputedRef, reactive, ref, watch} from "vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import Icon from "@/core/components/icon/Icon.vue";
import formatHelper from "@/core/helpers/formatHelper";
import useSwr from "@/core/composables/swr/useSwr";
import {creditApi, customerCertificationApi, notificationTemplateApi} from "@/container";
import NotificationPreview from "@/core/components/notification-preview/NotificationPreview.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {Customer, CustomerCertification} from "@/customer/interfaces/Customer";
import {useToastStore} from "@/stores/toast.store";
import {storeToRefs} from "pinia";
import {useCreditStore} from "@/stores/credit.store";
import CreditStoreModal from "@/credit/components/CreditStoreModal.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import useLaboratory from "@/laboratory/composables/useLaboratory";

type DataTableItem = {
    name: string;
    birth_date: string | null;
    top_sale?: {
        datetime: string;
        product: {
            name: string;
            code: string;
        }
    };
    customer?: Customer;
    phone?: string;
}

const props = defineProps<{
    laboratoryId: string;
}>();

let searchParams = reactive<LaboratoryConsumerAnalysisSearchParams>({
    size: 20,
    page: 1,
    since: dayjs().startOf("day").format("YYYY-MM-DD"),
    includes: ['top_sale', 'last_certification_notification'],
    sortBy: 'last_visit',
    order: 'desc'
});

const {data: laboratory} = useLaboratory(props.laboratoryId);
const {credit} = storeToRefs(useCreditStore());
const creditModalVisible = ref(false);
const {data: templates, isLoading: isLoadingTemplates} = useSwr(
    `notification-templates?laboratory_id=${props.laboratoryId}`,
    () => notificationTemplateApi().index({laboratory_id: Number(props.laboratoryId)}),
    {
        revalidateOnFocus: false
    }
);
const {data: creditData, mutate: mutateCredits} = useSwr('credit', () => creditApi().read());

watch(creditData, (newVal) => {
    if (newVal) {
        credit.value = newVal;
    }
});

const loadings = reactive<{
    [id: string]: boolean;
}>({});
const {data, isLoading, isValidating, mutate} = useLaboratoryConsumerAnalysis(
    props.laboratoryId,
    searchParams
);

const _data: ComputedRef<DataTableItem[] | undefined> = computed(() => {
    return data.value?.data.map((item) => {
        return {
            name: item.consumer?.information.name || "Patient Anonyme",
            birth_date: item.consumer?.information?.birth_date ?? null,
            top_sale: item.top_sale,
            customer: item.customer,
            phone: formatHelper.readablePhone(item.customer?.information.phone),
        };
    });
});

const headers = [
    {
        title: "Patient",
        key: "name",
        sortable: false
    },
    {
        title: "Achats",
        key: "top_sale",
        sortable: false
    },
    {
        title: "Dernier message",
        key: "latest_certification_notification",
        sortable: false
    },
    {
        title: 'Actions',
        key: 'actions',
        sortable: false
    }
];

/**
 * Compute the age of a patient based on his birth date
 *
 * @param birthDate
 */
const age = (birthDate: string): number => {
    return Math.floor(
        dayjs().diff(dayjs(birthDate), "year")
    );
};

/**
 * Compute the template to use
 */
const template = computed(() => {
    if (!templates.value?.length) {
        return null;
    }

    return templates.value[0];
});

/**
 * Handle the phone input change
 *
 * @param event
 * @param item
 */
const handlePhoneInputChange = (event: any, item: DataTableItem) => {
    if (!data.value) {
        return;
    }

    data.value.data = data.value.data.map((_item) => {
        if (_item.customer?.id === item.customer?.id && _item.customer) {
            _item.customer.information.phone = formatHelper.readablePhone(event.target.value);
        }

        return _item;
    });
};

/**
 * Handle page update.
 *
 * @param page
 */
const handlePageUpdate = (page: number) => {
    searchParams.page = page;
};

/**
 * Handle item per page update.
 *
 * @param itemsPerPage
 */
const handleItemPerPageUpdate = (itemsPerPage: number) => {
    searchParams.size = itemsPerPage;
    searchParams.page = 1;
};

/**
 * If organization has enough credit, certify and notify the customer item.
 *
 * @param item
 */
const certifyAndNotify = async (item: DataTableItem) => {
    if (!item.customer) {
        return;
    }

    if (!hasEnoughCredit.value) {
        creditModalVisible.value = true;
        return;
    }

    loadings[item.customer.id] = true;
    certify(item)
        .then((certification) => {
            if (certification) {
                return notifyCertification(certification)
                    .then(() => {
                        const toast = useToastStore();
                        toast.showSuccess({content: 'Le message a bien été envoyé'});

                        mutate();
                        mutateCredits();
                    })
                    .catch((error) => {
                        if (error.response.status === 402) {
                            creditModalVisible.value = true;
                        } else {
                            const toast = useToastStore();
                            toast.showError({content: 'Une erreur est survenue lors de l\'envoi du message'});
                        }
                    });
            }
        })
        .catch(() => {
            const toast = useToastStore();
            toast.showError({content: 'Une erreur est survenue lors de la certification du patient'});
        })
        .finally(() => {
            if (item.customer) {
                loadings[item.customer.id] = false;
            }
        });
};

/**
 * Certify the customer item.
 *
 * @param item
 */
const certify = async (item: DataTableItem): Promise<CustomerCertification | null> => {
    if (!item.customer || !item.phone) {
        return Promise.reject("Phone number is required");
    }

    return await customerCertificationApi()
        .certifyPhone(item.customer.id, formatHelper.apiPhone(item.phone));
};

/**
 * Notify the customer item certification.
 *
 * @param {CustomerCertification} certification
 */
const notifyCertification = async (certification: CustomerCertification) => {
    if (!template.value) {
        return;
    }

    return await customerCertificationApi().createNotification(certification.id, template.value.id);
};

/**
 * Determine whether the customer phone is valid
 *
 * @param phone
 */
const phoneIsValid = (phone?: string) => {
    if (!phone) {
        return false;
    }

    return formatHelper.phoneIsValid(phone);
};

const hasEnoughCredit = computed(() => {
    return (credit.value?.balance ?? 0) >= (template.value?.credits ?? 0);
});
</script>

<template>
  <div class="p-6 space-y-6">
    <BaseFlexSpinner v-if="isLoadingTemplates || isLoading" />
    <div v-else>
      <BaseCard>
        <BaseCardHeader>
          <div class="flex justify-between gap-8">
            <div>
              <h1 class="text-black">
                Animez vos patients avec un sms
              </h1>
              <p v-if="data" class="font-content text-base">
                {{ data.meta.total_consumer_count }} patients ont acheté un produit {{ laboratory?.name ?? '' }} aujourd'hui. <br>
                C'est le <span class="underline">bon moment</span> pour leur envoyer un message !
              </p>
            </div>
            <div class="max-w-xl">
              <NotificationPreview
                v-if="template"
                class="shadow-md rounded-b-lg"
                :content="template.parsed_content"
                type="sms"
                :from="''"
                :count="1"
                :heading="false"
                when="Maintenant"
              />
            </div>
          </div>
        </BaseCardHeader>
        <BaseCardBody>
          <div class="flex justify-between">
            <h2 class="text-black">
              Patients ayant acheté aujourd'hui
            </h2>
            <p class="font-bold">
              <Icon v-if="!hasEnoughCredit" name="mdi-alert-circle" color="red" />
              Crédits restants : {{ credit?.balance ?? 0 }}
            </p>
          </div>
          <v-data-table-server
            :items="_data"
            :headers="headers"
            :items-length="data?.meta.total_consumer_count ?? 0"
            :loading="isLoading || isValidating"
            :items-per-page-options="[10, 20, 30]"
            :items-per-page="searchParams.size"
            :page="searchParams.page"
            @update:items-per-page="handleItemPerPageUpdate"
            @update:page="handlePageUpdate"
          >
            <template #[`item.name`]="{item}">
              <div class="flex gap-1">
                <img
                  v-if="item.customer?.onboarded"
                  alt="Certifié"
                  src="@/assets/icons/certified.svg"
                >
                <Icon v-else name="mdi-cellphone" :size="35" />
                <div class="flex flex-col justify-between">
                  <p class="text-primary font-bold">
                    {{ item.name }} <span v-if="item.birth_date" class="text-sm text-gray-500">
                      {{ age(item.birth_date) }} ans</span>
                  </p>
                  <input
                    class="px-0 py-0 rounded-md self-start focus:ring-0"
                    placeholder="__ __ __ __ __"
                    :value="item.phone"
                    @input="event => handlePhoneInputChange(event, item)"
                  >
                </div>
              </div>
            </template>
            <template #[`item.top_sale`]="{item}">
              <div v-if="item.top_sale" class="flex items-center gap-2">
                <Icon name="mdi-cart-outline" :size="20" />
                <p>{{ item.top_sale.product.name }} le {{ $filters.date(item.top_sale.datetime) }}</p>
              </div>
            </template>
            <template #[`item.latest_certification_notification`]="{item}">
              <p v-if="item.customer?.latest_certification_notification">
                {{ $filters.date(item.customer.latest_certification_notification.created_at, 'relative') }}
              </p>
              <p v-else>
                -
              </p>
            </template>
            <template #[`item.actions`]="{item}">
              <div v-if="item.customer?.onboarded">
                <p>
                  Notification programmée
                  <v-tooltip>
                    <template #activator="{props: activatorProps}">
                      <Icon name="mdi-clock" v-bind="activatorProps" color="primary" />
                    </template>
                    <div>
                      <p>
                        La notification suivante sera envoyée après chaque achat s'il dispose de l'application Apodis
                        :
                      </p>
                      <p>Merci de votre visite !</p>
                      <p>Retrouvez vos ordonnances et vos traitements sur l’application Apodis.</p>
                    </div>
                  </v-tooltip>
                </p>
              </div>
              <div v-else>
                <p v-if="!item.customer?.information.phone" class="font-bold">
                  Veuillez renseigner le numéro de téléphone
                </p>
                <div v-else>
                  <div class="flex items-center gap-3">
                    <p v-if="template">
                      {{ template.credits }} {{ $filters.pluralize('crédit', template.credits) }} SMS
                    </p>
                    <BaseButton
                      primary
                      :disabled="!phoneIsValid(item.phone)"
                      :loading="loadings[item.customer?.id]"
                      density="comfortable"
                      append-icon="mdi-send"
                      @click="certifyAndNotify(item)"
                    >
                      Envoyer
                    </BaseButton>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table-server>
        </BaseCardBody>
      </BaseCard>
      <CreditStoreModal v-if="creditModalVisible" @close="creditModalVisible = false" />
    </div>
  </div>
</template>

<style scoped>

</style>
