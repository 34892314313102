<template>
  <div v-if="program" class="flex flex-col gap-y-6 p-6">
    <div class="flex flex-row">
      <SupportProgramDescription v-if="program" :program="program" />
    </div>
    <div v-if="program.user_communication">
      <ProgramUserCommunication :program="program" />
    </div>
    <div v-if="program?.bookable || (patientStore.customer && program?.onboarding_communication)" class="flex flex-row">
      <ProgramAppointment
        v-if="program?.bookable"
        :bookable="program.bookable"
        :programmable-id="program.programmable_id"
        :programmable-type="program.programmable_type"
        class="w-[50%]"
      />
      <CustomerCertification
        v-if="program && patientStore.customer && program?.onboarding_communication"
        :customer="patientStore.customer"
        :program-id="program.id"
        :communication-text="program.onboarding_communication"
        class="pl-3 w-[50%]"
      />
    </div>
    <div v-if="program.knowledgeables?.length">
      <ProgramKnowledgeBox :knowledgeables="program?.knowledgeables" :kid="program.id" type="program" />
    </div>
    <div v-if="program.programmable?.pathway">
      <PathwayStepper
        :pathway="program.programmable.pathway"
        :consumer-key="$route.params.consumerKey as string"
        @skip-delay="skipDelay()"
        @start="start()"
      />
    </div>
    <div v-if="program.backings?.length && customer">
      <BackingDetails
        v-for="backing in program.backings"
        :key="backing.id"
        :backing="backing"
        :customer="customer"
        :program-id="program.id"
        :attachments="program?.attachments"
        class="mt-4"
      />
    </div>
    <SupportProgramAdvices
      v-if="supportProgram.advices?.length > 0"
      :advices="supportProgram.advices"
    />
    <SupportProgramPurchaseHistory
      v-if="salesHistory"
      :history="salesHistory"
    />
    <SupportProgramDocumentGrid
      v-if="program.attachments && program.attachments.length > 0"
      :attachments="program.attachments"
    />
    <SupportProgramProtocol
      v-if="supportProgram.settings"
      :consumer-protocol="supportProgram.settings"
    />
    <div>
      <BaseButton
        v-if="!salesHistory"
        class="mr-6 w-72"
        @click="loadSaleHistory(program.programmable_id, $route.params.consumerKey as string)"
      >
        <div v-if="!states.loadingHistory">
          Afficher l'historique des achats
        </div>
        <div v-else>
          <BaseFlexSpinner size="small" />
        </div>
      </BaseButton>
      <BaseButton @click="toggleConsumerSettings(program.programmable_id, $route.params.consumerKey as string)">
        {{
          states.consumerDisabled
            ? 'Ce patient a été noté comme exclu du programme'
            : 'Exclure ce patient du programme'
        }}
      </BaseButton>
    </div>
    <div v-if="supportProgram.terms">
      <SupportProgramMention :mentions="supportProgram.terms" />
    </div>
    <div
      v-if="!!program.additional_information?.length"
      class="pl-10"
    >
      <markdown :content="program.additional_information" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ComputedRef, inject, reactive, computed} from "vue";
import SupportProgramDescription from '@/program/components/support-program/SupportProgramDescription.vue';
import SupportProgramPurchaseHistory from '@/program/components/support-program/SupportProgramPurchaseHistory.vue';
import SupportProgramAdvices from '@/program/components/support-program/SupportProgramAdvices.vue';
import SupportProgramDocumentGrid from '@/program/components/support-program/SupportProgramDocumentGrid.vue';
import PathwayStepper from '@/pathway/components/PathwayStepper.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import SupportProgramMention from '@/program/components/support-program/SupportProgramMention.vue';
import SupportProgramProtocol from '@/program/components/support-program/SupportProgramProtocol.vue';
import Markdown from '@/core/components/markdown/Markdown.vue';
import {usePatientStore} from '@/stores/patient.store';
import BackingDetails from "@/backing/components/BackingDetails.vue";
import ProgramKnowledgeBox from "@/program/components/program-knowledge-box/ProgramKnowledgeBox.vue";
import pathwayService from "@/pathway/services/PathwayService";
import {programApi} from "@/container";
import {
    ProgramSnapshot,
    SupportProgram,
    SupportProgramMeta,
    SupportProgramPatient
} from "@/program/interfaces/Program";
import {useRoute} from "vue-router";
import ProgramUserCommunication from "@/program/components/user-communication/ProgramUserCommunication.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import ProgramAppointment from "@/program/components/program-appointment/ProgramAppointment.vue";
import CustomerCertification from "@/customer/components/customer-certification/CustomerCertification.vue";
import {storeToRefs} from "pinia";

const openSurveyModal = inject<{
    (surveyId: number, consumerKey: string, stepId: number, supportId: number, type: string, options: unknown): void
        }>('openSurveyModal');

const patientStore = usePatientStore();
const {customer} = storeToRefs(patientStore);
const route = useRoute();

const states = reactive({
    loadedHistory: null as { data: SupportProgramPatient, meta: SupportProgramMeta } | null,
    loadingHistory: false as boolean,
    consumerDisabled: false as boolean,
});

const program: ComputedRef<ProgramSnapshot> = computed(() => {
    return customer.value?.programs.find(p => p.id === parseInt(route.params.programId as string)) as ProgramSnapshot;
});

const supportProgram: ComputedRef<SupportProgram> = computed(() => {
    return program.value.programmable as SupportProgram;
});

/** The consumer sale history. */
const salesHistory = computed(() => {
    return program.value.groups.included.find(g => !!g.sales)
        ? {data: program.value, meta: program.value.meta}
        : states.loadedHistory;
});

/**
 * Load the consumer sale history for the support program.
 *
 * @param {Number} supportProgramId - The support program id.
 * @param {String} consumerKey - The consumer key.
 */
const loadSaleHistory = async (supportProgramId: number, consumerKey: string) => {
    states.loadingHistory = true;
    states.loadedHistory = await programApi().history(supportProgramId, consumerKey);
    states.loadingHistory = false;
};

/**
 * Toggle the consumer disabled settings.
 *
 * @param {Number} supportProgramId - The support program id.
 * @param {String} consumerKey - The consumer key.
 */
const toggleConsumerSettings = async (supportProgramId: number, consumerKey: string) => {
    let response = await programApi().toggleConsumerSettings(supportProgramId, consumerKey);

    states.consumerDisabled = response.disabled;
};

/**
 * Reload the pathway skipping the delay.
 */
const skipDelay = () => {
    return patientStore.loadPathway(
        program.value.programmable_id,
        route.params.consumerKey as string,
        true
    );
};

const start = () => {
    if (!program.value.programmable?.pathway?.next_step?.resolved?.action) {
        return;
    }

    const step = program.value.programmable.pathway.next_step;

    if (step.type === 'survey' || step.type === 'survey-bundle') {
        return openSurveyModal!(
            step.steppable.id,
            route.params.consumerKey as string,
            step.id,
            program.value.programmable_id,
            step.type,
            {consent: step.steppable.consent}
        );
    }

    return pathwayService()
        .start(program.value.programmable.pathway, step, route.params.consumerKey as string);
};
</script>
