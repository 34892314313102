<script setup lang="ts">
import {useInterviewStore} from "@/stores/interview-store";
import InterviewDescription from "@/interview/components/instance-modal/InterviewDescription.vue";
import {storeToRefs} from "pinia";
import InterviewGuidelines from "@/interview/components/instance-modal/InterviewGuidelines.vue";
import InterviewConsumerNoteInput from "@/interview/components/instance-modal/InterviewConsumerNoteInput.vue";

const interviewStore = useInterviewStore();
const {interview} = storeToRefs(interviewStore);

</script>

<template>
  <div v-if="!interview">
    <p>Oups...</p>
  </div>
  <div v-else class="flex flex-col w-full gap-1">
    <InterviewDescription
      :description="interview.description"
      :duration="interview.duration"
      :video-url="interview.video_url"
    />
    <div class="flex gap-x-6">
      <div class="flex-1 self-start">
        <InterviewGuidelines />
        <p class="flex flex-1 py-4 text-primary justify-center text-center">
          💡N’oubliez pas de réaliser une analyse pharmaceutique afin de détecter d’éventuelles interactions
          médicamenteuses
        </p>
      </div>
      <slot name="shortcuts" />
    </div>
    <div class="flex-1" />
    <InterviewConsumerNoteInput
      v-if="interviewStore.interviewInstance"
      class="bottom-0 w-full"
      :interview-instance="interviewStore.interviewInstance"
    />
  </div>
</template>
