<script setup lang="ts">
import Markdown from "@/core/components/markdown/Markdown.vue";
import ExpandableTextOverflow from "@/core/components/expandable-text-overflow/ExpandableTextOverflow.vue";
import videoThumbnail from "@/assets/images/turorial-thumbnail.png";
import {ref} from "vue";
import BaseModal from "@/core/components/base/BaseModal.vue";
import EmbedVideo from "@/core/components/video/EmbedVideo.vue";

defineProps<{ description: string; duration?: number; videoUrl?: string }>();

const visible = ref(false);
</script>

<template>
  <div class="p-5 bg-white rounded-md flex flex-wrap justify-between gap-10 m-4">
    <div class="flex flex-1 flex-col flex-wrap">
      <div class="flex flex-row pr-2 justify-between items-center">
        <h2 class="text-black">
          🎯 Objectif
        </h2>
        <span v-if="duration" class="text-sm">Durée : {{ duration }}min</span>
      </div>
      <expandable-text-overflow :background-color="{red: 255, green: 255, blue: 255}" size="small">
        <markdown :content="description" />
      </expandable-text-overflow>
    </div>
    <div v-if="videoUrl" class="w-44 pl-4">
      <img :src="videoThumbnail" class="object-contain hover:opacity-75 cursor-pointer" @click="visible = true" />
      <base-modal v-if="visible" title=" " @close="visible = false">
        <embed-video :url="videoUrl" class="w-full"/>
      </base-modal>
    </div>
  </div>
</template>