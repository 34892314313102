<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center">
      <Icon name="mdi-cellphone" color="primary" :size="30" class="mr-2 self-start" />
      <div>
        <BaseSingleInput
          action="Certifier"
          placeholder="06 __ __ __ __"
          format="phone"
          :input="value"
          :loading="loading"
          @submit="certifyPhone"
        />
        <p>
          <span
            v-if="error"
            class="text-sm text-red-500"
          >
            {{ error }}
          </span>
        </p>
        <div
          v-if="customer.information.phone"
          class="flex flex-wrap w-52"
        >
          <p class="text-sm">
            Numéro enregistré dans votre base : {{ formattedCustomerPhone }}
          </p>
          <p
            class="text-sm text-primary underline cursor-pointer"
            @click="usePhone"
          >
            Utiliser ce numéro
          </p>
        </div>
      </div>
    </div>
    <v-tooltip
      location="bottom"
      text="Veuillez certifier un numéro"
    >
      <template #activator="{ props }">
        <div
          class="my-5 flex justify-center"
          v-bind="props"
        >
          <BaseButton disabled>
            Envoyer un sms
          </BaseButton>
        </div>
      </template>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseSingleInput from '@/core/components/base/BasePhoneInput.vue';
import formatHelper from '@/core/helpers/formatHelper';
import {customerCertificationApi} from '@/container';
import BaseButton from '@/core/components/base/BaseButton.vue';
import Icon from "@/core/components/icon/Icon.vue";

export default defineComponent({
    components: {
        Icon,
        BaseButton,
        BaseSingleInput
    },
    props: {
        customer: {
            type: Object,
            required: true
        }
    },
    emits: ['certified', 'conflict'],
    data() {
        return {
            loading: false,
            value: '',
            error: null as string | null
        };
    },
    computed: {
        /**
         * Check if the customer is already certified.
         *
         * @returns {boolean}
         */
        formattedCustomerPhone() {
            return formatHelper.readablePhone(this.customer.information.phone);
        }
    },
    methods: {
        /**
         * Certify a phone number for the current customer.
         *
         * @param {string} phone - The phone number.
         *
         * @param phone
         */
        certifyPhone(phone: string) {
            if (!formatHelper.phoneIsValid(phone)) {
                this.error = 'Le numéro doit correspondre au format 06 XX XX XX XX ou 07 XX XX XX XX';
                return;
            } else {
                this.error = null;
            }

            this.loading = true;

            customerCertificationApi()
                .certifyPhone(this.customer.id, formatHelper.apiPhone(phone))
                .then(certification => {
                    this.$emit('certified', certification);
                })
                .catch(error => {
                    if (error.response.status === 409) {
                        this.$emit('conflict', error.response.data.conflict);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        usePhone() {
            this.value = formatHelper.readablePhone(this.customer.information.phone);
        },
        /**
         * Clear input.
         */
        clear() {
            this.value = '';
        }
    }
});
</script>
