<template>
  <div class="flex flex-col gap-y-4">
    <h1>Diffusez le plan au patient</h1>
    <div class="step-card">
      <div>
        <ul>
          <li>
            <p>
              <span class="font-bold pr-2">Date de fin de validité :</span>
              <span>{{ endDateFormatted }}</span>
            </p>
          </li>
          <li class="py-2">
            <div class="flex flex-row flex-wrap align-center">
              <span class="font-bold pr-2 min-w-max">Dates de renouvellement :</span>
              <div>
                <v-chip
                  v-for="(date, index) in dates"
                  :key="index"
                  class="mr-2 mb-2"
                >
                  <span>{{ $filters.date(date, 'short') }}</span>
                </v-chip>
              </div>
            </div>
          </li>
          <li class="py-2">
            <div class="font-bold">
              Plan de renouvellement traité par :
            </div>
            <div>{{ selectedOperatorLabel }}</div>
          </li>
          <li class="py-2">
            <span class="font-bold">Rappels automatiques :</span>
            par {{ typeLabel }}
          </li>
        </ul>
        <div class="flex flex-col gap-2 pt-4">
          <div class="text-primary font-bold">
            <Icon name="mdi-send" class="-rotate-45 absolute -top-1 text-primary" />
            {{ messageCount }} {{ typeLabel }} seront {{ typeLabel === 'SMS' ? 'envoyés' : 'envoyées' }}
          </div>
          <div v-if="!isUpdate && selectedNotificationChannel === 'sms'" class="flex justify-between text-sm text-primary">
            <div class="flex items-center gap-1">
              <Icon name="mdi-star" class="text-secondary" />
              Coût: {{ messageCount }} crédits
            </div>
            <div class="flex" :class="{'text-red-light': notEnoughCredits}">
              <span v-if="smsCreditCount !== undefined && !loadingBalance" class="ml-1 ">
                Solde: {{ smsCreditCount }} crédits
              </span>
              <span v-else><base-spinner size="small" /></span>
            </div>
          </div>
          <div v-if="!isUpdate && !loadingBalance && smsCreditCount !== undefined" class="flex flex-col items-end">
            <BaseButton
              v-if="notEnoughCredits && !smsPackPrices" primary density="compact" :loading="loadingPrices"
              @click="loadPrices()"
            >
              Acheter des crédits
            </BaseButton>
            <ul v-if="smsPackPrices" class="text-sm">
              <li
                v-for="price in smsPackPrices"
                :key="price.id"
                class="flex border-b border-gray-50 items-center gap-2 justify-end cursor-pointer"
              >
                <span class="text-primary">{{ price.transform_quantity?.divide_by || 1 }} crédits</span>
                <BaseButton primary :loading="loadingStripeCheckout === price.id" @click="buyCredits(price)">
                  <span class="text-white">{{ price.unit_amount / 100 }} €</span>
                </BaseButton>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center gap-x-4 rounded-lg p-4 bg-orange-100 text-sm">
      <Icon name="mdi-lightbulb" class="text-orange" />
      <div>
        En cas de stock insuffisant avant un renouvellement, vous recevrez également une notification.
      </div>
    </div>
    <div class="flex flex-row justify-end">
      <BaseButton :disabled="cannotPublish" primary @click="$emit('on-publish')">
        Valider et diffuser
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BaseButton from '@/core/components/base/BaseButton.vue';
import Icon from "@/core/components/icon/Icon.vue";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {creditApi} from "@/container";
import {Credit} from "@/core/interfaces/Credit";
import {StripePrice} from "@/stripe/interfaces";
import StripeCheckoutService from "@/stripe/services/stripeCheckoutService";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";

const props = defineProps<{
    selectedOperatorLabel: string | null,
    selectedNotificationChannel: string,
    dates: Date[],
    endDateFormatted: string
    isUpdate: boolean
}>();

const smsCreditCount = ref<number>();
const smsPackPrices = ref<StripePrice[]>();
const loadingBalance = ref<boolean>(false);
const loadingPrices = ref<boolean>(false);
const loadingStripeCheckout = ref<string | boolean>(false);

defineEmits(['on-publish']);

const typeLabel = computed(() => props.selectedNotificationChannel === 'sms' ? 'SMS' : 'notifications');
const messageCount = computed(() => props.dates.length + 1);
const notEnoughCredits = computed(() => (smsCreditCount.value || 0) < messageCount.value);
const cannotPublish = computed(() => {
    return (!smsCreditCount.value || notEnoughCredits.value)
         && !props.isUpdate
         && props.selectedNotificationChannel === 'sms';
});

/** Load user balance */
const loadBalance = async () => {
    loadingBalance.value = true;
    const credit: Credit = await creditApi().read();
    smsCreditCount.value = credit.balance;
    loadingBalance.value = false;
};

/** Load sms pack prices. */
const loadPrices = async () => {
    loadingPrices.value = true;
    smsPackPrices.value = await creditApi().prices();
    loadingPrices.value = false;
};

/** Launch stripe checkout to buy credits. */
const buyCredits = async (price: StripePrice) => {
    loadingStripeCheckout.value = price.id;
    await StripeCheckoutService.checkout(price);
    loadingStripeCheckout.value = false;
};

/** When component is mounted */
onMounted(() => {
    if (props.selectedNotificationChannel === 'sms') {
        window.ipcRenderer.on("stripe-checkout-success", () => {
            loadBalance().then(() => {
                smsPackPrices.value = undefined;
            });
        });

        loadBalance();
    }
});

/** When component is unmounted */
onUnmounted(() => {
    if (props.selectedNotificationChannel === 'sms') {
        window.ipcRenderer.removeAllListeners("stripe-checkout-success");
    }
});


</script>

<style scoped>
li {
    @apply p-2 border-b border-gray-200;
}
</style>
