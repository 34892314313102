<script setup lang="ts">
import Extension from "@/core/interfaces/extension/Extension";
import {computed} from "vue";
import LaboratoryExtensionCard from "@/laboratory/components/LaboratoryExtensionCard.vue";

const props = defineProps<{
    extensions: Extension[];
}>();

const programLabel = computed(() => {
    return props.extensions.map((extension) => extension.name).join(", ");
});
</script>

<template>
  <div class="bg-white rounded-md p-4 flex flex-col md:flex-row w-full flex-wrap gap-3 justify-between">
    <div class="flex-1">
      <p class="text-xl font-bold">
        Augmentez les ventes au comptoir
      </p>
      <p>Avec nos programmes {{ programLabel }}</p>
    </div>
    <div class="flex flex-row flex-wrap gap-3 items-start">
      <LaboratoryExtensionCard
        v-for="extension in extensions"
        :key="extension.id"
        :extension="extension"
      />
    </div>
  </div>
</template>

<style scoped>

</style>
