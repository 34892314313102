<template>
  <div class="w-full">
    <component
      :is="answerComponent"
      :question="localQuestion"
      :inline="inline"
      @on-press="onAnswerPress"
    />
    <div
      v-if="multipleAnswers.length > 0 && question.type.slug === QuestionType.MULTIPLE_CHOICE"
      class="flex flex-row justify-end mt-2"
    >
      <base-button
        primary
        @click="$emit('onAnswerPress', multipleAnswers)"
      >
        <span class="font-content">Valider</span>
      </base-button>
    </div>
    <div
      v-if="question.optional && question.type.slug !== QuestionType.TEXT_INPUT"
      class="flex flex-row justify-end mt-2"
    >
      <base-button
        @click="onAnswerPress(undefined)"
      >
        <span class="font-content">Suivant</span>
      </base-button>
    </div>
  </div>
</template>

<script lang="ts">
import SurveyAnswerYesNo from '@/survey/components/SurveyAnswerYesNo.vue';
import SurveyAnswerSingleChoice from '@/survey/components/SurveyAnswerSingleChoice.vue';
import SurveyAnswerTextInput from '@/survey/components/SurveyAnswerTextInput.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import {QuestionType} from "@/core/interfaces/survey/Survey";
import {defineComponent} from "vue";
import SurveyAnswerMultipleChoice from '@/survey/components/SurveyAnswerMultipleChoice.vue';

export default defineComponent({
    name: 'SurveyAnswer',
    components: {
        BaseButton,
        SurveyAnswerYesNo,
        SurveyAnswerSingleChoice,
        SurveyAnswerMultipleChoice,
        SurveyAnswerTextInput
    },
    props: {
        question: {
            type: Object,
            required: true
        },
        inline: {
            type: Boolean,
            default: false
        }
    },
    emits: ['onAnswerPress'],
    data() {
        return {
            QuestionType,
            localQuestion: this.question,
            multipleAnswers: [] as any[]
        };
    },
    computed: {
        /**
         * Return the answer content.
         *
         * @returns {string}
         */
        answerComponent() {
            switch (this.question.type.slug) {
            case QuestionType.YES_NO:
                return 'SurveyAnswerYesNo';
            case QuestionType.SINGLE_CHOICE:
                return 'SurveyAnswerSingleChoice';
            case QuestionType.MULTIPLE_CHOICE:
                return 'SurveyAnswerMultipleChoice';
            case QuestionType.TEXT_INPUT:
            case QuestionType.CHECK_INPUT:
                return 'SurveyAnswerTextInput';
            default:
                return 'SurveyAnswerSingleChoice';
            }
        }
    },
    methods: {
        /**
         * Emit an onAnswerPress event when an answer is pressed.l
         *
         * @param answer
         * @param isMultipleChoice
         */
        onAnswerPress(answer: any, isMultipleChoice?: boolean) {
            if (isMultipleChoice) {
                this.multipleAnswers = answer;
            } else {
                this.$emit('onAnswerPress', [answer]);
            }
        }
    }
});
</script>
