<script setup lang="ts">
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import {onMounted, ref} from "vue";
import {NotificationPlaceholders, NotificationTemplate} from "@/core/interfaces/MassCertification";
import {massCertificationApi} from "@/container";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import {useRouter} from "vue-router";
import CommunicationMessageTemplateSelection
    from "@/communication/components/CommunicationMessageTemplateSelection.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import CommunicationMessageAuthoring from "@/communication/components/CommunicationMessageAuthoring.vue";

const {mode, programId} = useCommunicationStore();
const templates = ref<NotificationTemplate[]>([]);
const placeholders = ref<NotificationPlaceholders>();
const loading = ref(false);
const communicationStore = useCommunicationStore();
const router = useRouter();

onMounted(() => {
    loading.value = true;
    massCertificationApi()
        .messageTemplates({program_id: programId, type: mode})
        .then(response => templates.value = response)
        .then(() => massCertificationApi().placeholders())
        .then(response => placeholders.value = response)
        .finally(() => loading.value = false);

});

/**
 * On message selected.
 *
 * @param template
 */
const onSelected = (template: NotificationTemplate) => {
    communicationStore.template = template;
    router.push({
        name: 'patient-administration.communication.summary'
    });
};

/**
 * On custom content selected.
 *
 * @param content  The content.
 */
const onContentSelected = (content: string) => {
    communicationStore.content = content;
    router.push({
        name: 'patient-administration.communication.summary'
    });
};
</script>

<template>
  <div>
    <HeaderBackButton title="Communication par SMS" />
    <div>
      <BaseFlexSpinner v-if="loading" />
      <div v-else class="rounded-lg shadow-lg m-4">
        <div class="p-4 rounded-t-lg bg-violet-100">
          <h1 class="text-gray-900">
            Envoyez votre SMS personnalisé
          </h1>
          <CommunicationMessageAuthoring :placeholders="placeholders" @selected="onContentSelected" />
        </div>
        <div class="p-4 rounded-b-lg bg-white">
          <h1 class="text-gray-900">
            Ou choisissez un modèle
          </h1>
          <CommunicationMessageTemplateSelection
            v-for="template in templates"
            :key="template.id"
            :template="template"
            @click="onSelected(template)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
