import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import {computed, Reactive} from "vue";
import {filterEmptyKeys} from "@/lib/utils";

export interface LaboratoryConsumerAnalysisSearchParams {
    size?: number;
    page?: number;
    since: string;
    sortBy?: 'units' | 'revenue' | 'cart_count' | 'last_visit' | 'average_cart_quantity' | 'average_cart_price';
    order?: 'asc' | 'desc';
    search?: string;
    includes?: ('top_sale' | 'last_certification_notification')[]
}

/**
 * Composable to get the analysis of customers for a laboratory.
 */
const useLaboratoryConsumerAnalysis = (
    laboratoryId: string,
    searchParams: Reactive<LaboratoryConsumerAnalysisSearchParams>
) => {

    const swrKey = computed(() => {
        const query = new URLSearchParams(filterEmptyKeys(searchParams));

        return `/laboratories/${laboratoryId}/customers/analysis?${query.toString()}`;
    });

    const {data, error, isLoading, isValidating, mutate} = useSwr(
        swrKey,
        () => laboratoryApi().customers(
            Number(laboratoryId),
            searchParams
        ),
        {
            revalidateOnFocus: false,
            ttl: 1000 * 60 * 5,
            errorRetryCount: 0
        }
    );

    return {
        data,
        error,
        isLoading,
        isValidating,
        mutate
    };
};

export default useLaboratoryConsumerAnalysis;
