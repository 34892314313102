import {AxiosInstance} from "axios";
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";
import Customer from "@/customer/interfaces/Customer";
import Consumer from "@/core/interfaces/consumers/Consumer";
import {LaboratoryConsumerAnalysisSearchParams} from "@/laboratory/composables/useConsumerAnalysis";
import {Routine} from "@/laboratory/interfaces";
import Offer from "@/core/interfaces/Offer";

export interface CustomerAnalysisResponse {
    data: {
        customer?: Customer,
        consumer?: Consumer,
        consumer_key?: string,
        units: number,
        revenue: number,
        cart_count: number,
        last_visit: string,
        average_cart_quantity: number,
        average_cart_revenue: number,
        top_sale?: {
            datetime: string;
            product: {
                name: string;
                code: string;
            }
        }
    }[],
    meta: {
        top_consumers: {
            customer?: Customer,
            consumer?: Consumer,
            consumer_key?: string,
            units: number,
            revenue: number,
            cart_count: number,
            last_visit: string
        }[],
        total_consumer_count: number,
        today_consumers: number,
        average_quantity: number,
        average_price: number,
        faithful_consumer_count: number,
        new_consumers_count: number,
    }
}

export interface LaboratoryOperatorAnalysis {
    /** The name of the operator or the operator code if it could not be found */
    operator: string;
    operator_code: string;
    revenue: number;
    average_cart_price: number;
    total_quantity: number;
    average_cart_quantity: number;
    sale_histogram: {
        key_as_string: string;
        key: number;
        revenue: number;
    }[]
}

export interface LaboratorySale {
    consumer?: Consumer;
    consumer_key?: string;
    product: {
        name: string;
        code: string;
    },
    datetime: string;
    quantity: number;
    revenue: number;
    operator: string;
}

export interface LaboratoryProductsStatistics {
    product: {
        id: string;
        name: string;
        code: string;
    },
    consumer_count: number;
    revenue: number;
    quantity: number;
    last_sale: string;
}

export default function (client: AxiosInstance, hdsClient: AxiosInstance) {
    return {
        /**
         * Get laboratories that has patient partnership.
         */
        async patientPartnerLaboratories(): Promise<Laboratory[]> {
            return await client.get('/partnership/patient/laboratories')
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Read a laboratory that has patient partnership.
         */
        async patientPartnerLaboratory(laboratoryId: number): Promise<Laboratory> {
            return await client.get(`/partnership/patient/laboratories/${laboratoryId}`)
                .then((response) => {
                    return response.data.data;
                });
        },

        async patientPartnerStatistics(laboratoryId: number): Promise<{
            total_revenue: number,
            consumer_count: number,
            today_consumer_count: number,
            top_operator: { name?: string, code?: string },
        }> {
            return await client.get(`/partnership/patient/laboratories/${laboratoryId}/statistics`)
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get the analysis of customers for a laboratory.
         */
        async customers(laboratoryId: number, params?: LaboratoryConsumerAnalysisSearchParams): Promise<CustomerAnalysisResponse> {
            return await hdsClient.get(`/laboratories/${laboratoryId}/customers/analysis`, {
                params
            })
                .then((response) => {
                    return response.data;
                });
        },
        /**
         * Get the analysis of operators for a laboratory.
         *
         * @param {number} laboratoryId
         * @param {object} params
         */
        async operatorAnalysis(laboratoryId: number, params: {
            since?: string
        } = {}): Promise<LaboratoryOperatorAnalysis[]> {
            return await client.get(`laboratories/${laboratoryId}/operators/analysis`, {params})
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get the sales of a laboratory.
         */
        async sales(laboratoryId: number, params: {
            operator_code?: string;
            consumer_key?: string,
            page?: number,
            size?: number,
            sortBy?: string,
            order?: string,
            since?: string,
            product_id?: string
        } = {}): Promise<{
            data: LaboratorySale[],
            meta: { total: number }
        }> {
            return await hdsClient.get(`laboratories/${laboratoryId}/sales`, {params})
                .then((response) => {
                    return response.data;
                });
        },

        /**
         * Get the products statistics for a laboratory.
         */
        async products(
            laboratoryId: number,
            params: {
                page?: number,
                size?: number,
                sort_by?: string,
                order?: string,
                since?: string,
            } = {}
        ): Promise<{ data: LaboratoryProductsStatistics[]; meta: { total: number } }> {
            return await client.get(`laboratories/${laboratoryId}/products/statistics`, {params})
                .then((response) => {
                    return response.data;
                });
        },

        /**
         * Get the routines of a laboratory.
         *
         * @param laboratoryId
         */
        async routines(laboratoryId: number): Promise<Routine[]> {
            return await client.get(`routines`, {
                params: {
                    laboratory_id: laboratoryId
                }
            })
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Update the partnership status of a laboratory for the current healthcenter.
         *
         * @param organizationId
         * @param enabled
         */
        async updatePartnershipStatus(organizationId: number, enabled: boolean) {
            return await client.patch(`/healthcenter/partnerships/${organizationId}`, {
                enabled
            })
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get offers for a laboratory.
         */
        async offers(params): Promise<Offer[]> {
            return await client.get('imported-offers', {
                params
            })
                .then((response) => {
                    return response.data.data;
                });
        }
    };
}
