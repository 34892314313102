<script setup lang="ts">
import {ref} from "vue";
import SearchInput from "@/core/components/search-bar/SearchInput.vue";
import {drugInteractionApi} from "@/container";
import Icon from "@/core/components/icon/Icon.vue";

export interface VidalAllergy {
    id: string;
    title: string;
}

const props = defineProps({
    patientAllergies: {
        type: Array<VidalAllergy>,
        default: []
    }
});

const emit = defineEmits(['update']);
const allergiesLoading = ref(false);
const allergiesSearchQuery = ref('');
const allergies = ref<VidalAllergy[]>();
const selectedAllergies = ref<VidalAllergy[]>(props.patientAllergies);
let timer: NodeJS.Timeout;

/**
 * Search vidal allergies.
 * @param query
 */
const search = (query: string) => {
    allergiesSearchQuery.value = query;
    if (timer) {
        clearTimeout(timer);
    }

    if (query.length === 0 || query.length < 3) {
        allergies.value = undefined;
    } else {
        timer = setTimeout(async () => {
            allergiesLoading.value = true;
            await drugInteractionApi().allergies(query)
                .then(response => {
                    allergies.value = response;
                })
                .finally(() => allergiesLoading.value = false);
        }, 500);
    }
};

/**
 * Update selected allergies.
 *
 * @param allergy
 */
const updateAllergies = (allergy: VidalAllergy) => {
    const allergyExists = selectedAllergies.value.find(selected => selected.id === allergy.id);

    if (!allergyExists) {
        selectedAllergies.value.push(allergy);
    } else {
        const indexToRemove = selectedAllergies.value.indexOf(allergyExists);
        if (indexToRemove !== -1) {
            selectedAllergies.value.splice(indexToRemove, 1);
        }
    }

    allergiesSearchQuery.value = '';
    allergies.value = undefined;
    emit('update', selectedAllergies);
};
</script>

<template>
  <div class="pl-0 pr-3 pt-4">
    <div class="flex flex-row items-center space-between">
      <div class="flex flex-col min-w-24">
        <p>
          Allergies
        </p>
        <span class="text-sm text-primary">
          {{
            selectedAllergies.length > 0
              ? `${selectedAllergies.length} ${$filters.pluralize('séléctionné', selectedAllergies.length)}`
              : ""
          }}
        </span>
      </div>
      <div class="flex flex-1 ml-4 relative">
        <search-input
          class="flex flex-1"
          :is-loading="false"
          :value="allergiesSearchQuery"
          placeholder="Rechercher des allergies..."
          @input="search"
        />
        <div
          v-if="allergiesLoading"
          class="flex flex-1 absolute top-11 bg-white border-2 border-primary/20 rounded-lg flex-col w-full z-10 px-2 py-4 mt-1"
        >
          <span>Recherche en cours...</span>
        </div>
        <div
          v-else-if="!!allergies"
          class="flex flex-1 absolute top-11 max-h-[320px] overflow-y-scroll bg-white border-2 border-primary/20 rounded-lg flex-col w-full z-10 px-2 py-4 mt-1"
        >
          <div v-if="allergies.length > 0" class="flex flex-col">
            <span
              v-for="allergy in allergies"
              :key="allergy.id"
              class="my-1 p-2 rounded-md hover:shadow-md hover:cursor-pointer"
              @click="updateAllergies(allergy)"
            >
              {{ allergy.title }}
            </span>
          </div>
          <div v-else-if="!allergies.length">
            <span class="my-1 p-2 rounded-md">Aucun résultat</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div
      v-if="selectedAllergies.length"
      class="flex flex-row flex-wrap mt-4"
    >
      <span
        v-for="allergy in selectedAllergies"
        :key="allergy.id"
        class="py-2 px-4 m-1 rounded-xl bg-primary/30 hover:shadow-md hover:cursor-pointer"
        @click="updateAllergies(allergy)"
      >
        {{ allergy.title }}
        <icon name="mdi-close" />
      </span>
    </div>
  </div>
</template>
