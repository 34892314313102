<script setup lang="ts">
import {onMounted, ref} from "vue";
import Customer from "@/customer/interfaces/Customer";
import CustomerCertificationNotificationModal
    from "@/customer-certification/views/CustomerCertificationNotificationModal.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {customerCertificationApi, storageService} from "@/container";
import formatHelper from "@/core/helpers/formatHelper";
import BaseSingleInput from '@/core/components/base/BasePhoneInput.vue';
import CustomerCertificationOnboarding from "@/customer-certification/views/CustomerCertificationOnboarding.vue";
import {usePatientStore} from "@/stores/patient.store";

const {customer} = defineProps<{ customer: Customer, programId?: number, communicationText?: string }>();
const {load} = usePatientStore();
const isModalVisible = ref(false);
const loading = ref(false);
const phone = ref();
const noCertification = ref(customer.status === 'none');
const title = !noCertification.value
    ? customer.onboarded
        ? "Patient équipé Apodis"
        : "Patient certifié"
    : "Envoyez un SMS";
const error = ref<string | null>();

const print = () => {
    window.ipcRenderer.invoke('print', {
        url: customerCertificationApi().pdfUrl(customer.id, 'phone'),
        token: storageService().read('token')
    });
};

onMounted(() => {
    phone.value = formatHelper.readablePhone(customer.certification?.phone || customer.pending_certification?.phone || customer.information?.phone);
});

/**
 * Certify a phone number for the current customer.
 *
 * @param {string} phone - The phone number.
 *
 * @param phone
 */
const certifyPhone = (phone: string) => {
    if (!formatHelper.phoneIsValid(phone)) {
        error.value = 'Le numéro doit correspondre au format 06 XX XX XX XX ou 07 XX XX XX XX';
        return;
    } else {
        error.value = null;
    }

    loading.value = true;

    customerCertificationApi()
        .certifyPhone(customer.id, formatHelper.apiPhone(phone))
        .then(() => {
            load(customer.id, true);
        })
        .finally(() => {
            loading.value = false;
        });
};

</script>

<template>
  <div class="flex flex-row items-center">
    <img src="@/assets/images/iphone-mockup.png" class="h-24" alt="mobile">
    <div class="flex flex-col gap-1">
      <div class="flex flex-row">
        <span>{{ title }}</span>
        <icon
          v-if="customer.is_certified"
          name="mdi-check"
          color="green"
          class="pl-2"
        />
        <v-dialog fullscreen transition="dialog-bottom-transition">
          <template #activator="{ props: activatorProps }">
            <icon
              v-bind="activatorProps"
              :name="'mdi-information-outline'"
              :color="'primary'"
              class="pl-4 cursor-pointer"
            />
          </template>

          <template #default="{ isActive }">
            <CustomerCertificationOnboarding :customer="customer" @close="isActive.value = false" />
          </template>
        </v-dialog>
      </div>
      <div v-if="customer.onboarded">
        <span class="text-primary text-sm">
          {{ communicationText }}
        </span>
      </div>
      <div v-else>
        <div v-if="noCertification">
          <div>
            <BaseSingleInput
              action="Certifier"
              placeholder="06 __ __ __ __"
              format="phone"
              :input="phone"
              :loading="loading"
              @submit="certifyPhone"
            />
            <p>
              <span
                v-if="error"
                class="text-sm text-red-500"
              >
                {{ error }}
              </span>
            </p>
          </div>
        </div>
        <div v-else class="flex flex-row items-center">
          <div class="w-32 border-primary">
            <span>{{ phone }}</span>
          </div>
        </div>
      </div>
    </div>
    <customer-certification-notification-modal
      :modal-visible="isModalVisible"
      :customer="customer"
      :program-id="programId"
      @close="isModalVisible=false"
    />
    <div v-if="!noCertification" class="flex flex-col ml-4 pl-3 justify-between lg:basis-1/4 gap-1 border-l border-gray-200">
      <p class="text-primary text-sm cursor-pointer hover:opacity-80" @click="isModalVisible=true">
        <Icon name="mdi-send" class="-rotate-45 absolute -top-1 text-primary" />
        Envoyer un sms
      </p>
      <p class="text-primary text-sm cursor-pointer hover:opacity-80" @click="print()">
        <Icon name="mdi-printer" color="primary" />
        Imprimer le QR code
      </p>
    </div>
  </div>
</template>
