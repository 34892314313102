<script setup lang="ts">
import SurveyImage from "@/survey/components/SurveyImage.vue";
import {SurveyQuestion, SurveyQuestionAnswer} from "@/core/interfaces/survey/Survey";
import {ref} from "vue";

const props = defineProps<{
    surveyId: number;
    question: SurveyQuestion;
    editable?: boolean;
    density?: string,
    value: any[];
}>();

const emits = defineEmits(['change']);

const localQuestion = ref(props.question);
const multipleAnswers = ref<SurveyQuestionAnswer[]>([]);

/**
 * Tell if this question was previously answered by the user.
 *
 * @param {SurveyQuestionAnswer} answer
 * @return {boolean}
 */
const previouslyAnswered = (answer: SurveyQuestionAnswer): boolean => {
    if (localQuestion.value.user_answer) {
        return !!localQuestion.value.user_answer.value.find(item => {
            return item.toString() === answer.value.toString();
        });
    }

    return false;
};

/**
 * On answer press.
 * @param answer
 */
const onAnswerPress = (answer: SurveyQuestionAnswer) => {
    const answerAsString = answer.value.toString();
    if (localQuestion.value.user_answer === null) {
        localQuestion.value.user_answer = {value: [answerAsString]};
    } else {
        const answerIndex = localQuestion.value.user_answer.value.findIndex(
            previousAnswer => previousAnswer === answer.value.toString()
        );
        if (answerIndex === -1) {
            localQuestion.value.user_answer.value.push(answerAsString);
        } else {
            localQuestion.value.user_answer.value.splice(answerIndex, 1);
        }
    }
    const answerIndex = multipleAnswers.value.findIndex(previousAnswer => previousAnswer === answer);
    if (answerIndex === -1) {
        multipleAnswers.value.push(answer);
    } else {
        multipleAnswers.value.splice(answerIndex, 1);
    }

    emits('change', multipleAnswers.value.map(answer => answer.value));
};

</script>

<template>
  <div class="flex flex-wrap flex-row" :class="[density === 'compact' ? 'justify-start gap-3' : 'justify-center gap-6']">
    <div
      v-for="answer in question.answers"
      :key="(answer.value as number)"
    >
      <div class="grid justify-items-center">
        <SurveyImage
          v-if="answer?.image"
          :survey-id="surveyId"
          :question-id="question.id"
          :answer-id="(answer.value as number)"
          class="w-32 h-32"
        />
      </div>
      <div
        class="cursor-pointer rounded-md border border-gray-300 shadow-md"
        :class="[density === 'compact' ? 'py-1 px-3' : 'p-4']"
        :style="{'background-color': previouslyAnswered(answer) ? (answer?.color || '#3513e2') : 'white'}"
        @click="editable && onAnswerPress(answer)"
      >
        <span
          class="font-content"
          :style="{'color': previouslyAnswered(answer) ? 'white' : 'black'}"
        >
          {{ answer.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>