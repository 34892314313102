<script setup lang="ts">
import {Routine} from "@/laboratory/interfaces";
import ProductImage from "@/product/components/ProductImage.vue";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import VideoStory from "@/core/components/video/VideoStory.vue";

defineProps<{
    routine: Routine;
    index: number
}>();

/**
 * Get the product index label to have a 2 digits number.
 *
 * @param index
 */
const productIndexLabel = (index: number) => {
    const label = (index + 1).toString();

    if (label.length === 1) {
        return `0${label}`;
    }

    return label;
};
</script>

<template>
  <div
    class="border-l-8 px-6 gap-12 flex flex-col"
    :style="{borderColor: routine.color}"
  >
    <div class="space-y-3">
      <h3>Routine {{ index + 1 }} : {{ routine.name }}</h3>
      <div class="flex flex-col md:flex-row gap-6 items-center">
        <img
          alt="Routine image"
          :src="routine.image.url"
          class="object-cover rounded-sm drop-shadow-md h-28 w-full md:w-auto md:aspect-video"
        >
        <div class="flex-1">
          <p>
            {{ routine.description }}
          </p>
        </div>
        <div>
          <VideoStory
            v-if="routine.video"
            :thumbnail-video="routine.video"
            :videos="[routine.video]"
            :title="routine.name"
          />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-20 px-6">
      <div v-for="(product, productIndex) in routine.products" :key="product.id">
        <div class="flex gap-3 items-center">
          <h1 class="text-4xl text-black">
            {{ productIndexLabel(productIndex) }}
          </h1>
          <div>
            <ProductImage
              :size="36"
              :product="{photoPath: product.image_url, name: product.name}"
            />
          </div>
        </div>
        <div
          class="pb-2 mb-2 space-y-2" :class="{
            'border-b': product.override?.short_description
          }"
        >
          <p>{{ product.name }}</p>
          <p
            class="text-sm"
            :class="[
              product.price ? 'text-black' : 'text-red-500 text-sm'
            ]"
          >
            {{ product.price ? $filters.currencyze(product.price) : 'Produit non répertorié dans votre stock' }}
          </p>
        </div>
        <markdown-view
          v-if="product.override?.short_description" class="!p-0"
          :content="product.override.short_description.replace(/\\\*/g, '*')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
