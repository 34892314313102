<template>
  <div>
    <PatientFilters v-if="tags" v-model="selectedTags" :tags="tags" class="mt-2 px-6 py-2" />
    <base-flex-spinner v-if="isLoading" class="mt-4" />
    <div v-else>
      <prescription-list
        :prescriptions="prescriptions"
        @on-press="onPress"
      />
      <div class="flex justify-center py-4">
        <base-button
          v-if="!!afterKey && !endReached"
          class="font-medium"
          :disabled="isLoadingMore"
          @click="loadMore"
        >
          <base-spinner
            v-if="isLoadingMore"
            size="button"
          />
          <span v-else>Voir plus</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {prescriptionApi} from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import PrescriptionList from '@/prescription/components/PrescriptionList.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import {Prescription, Tag} from "@/prescription/interfaces/Prescription";
import PatientFilters, {PatientFiltersProps} from "@/prescription/components/PatientFilters.vue";
import {PatientSearchParams} from "@/customer/interfaces/Patient";

export default defineComponent({
    name: 'CustomerPrescriptions',
    components: {PatientFilters, BaseButton, PrescriptionList, BaseSpinner, BaseFlexSpinner},
    data() {
        return {
            isLoading: false,
            isLoadingMore: false,
            prescriptions: [] as Prescription[],
            afterKey: null,
            endReached: false,
            selectedTags: {
                tags: [],
                certified: null,
                children: null,
                tags_operator: 'AND',
                purchase_type: null,
                purchase_id: null
            } as PatientFiltersProps,
            tags: null as Tag[] | null,
        };
    },
    watch: {
        selectedTags: {
            handler() {
                this.refresh();
            },
            deep: true
        }
    },
    mounted() {
        this.isLoading = true;
        this.index()
            .finally(() => {
                this.isLoading = false;
            });
    },
    methods: {
        refresh() {
            this.isLoading = true;
            this.prescriptions = [];
            this.isLoadingMore = false;
            this.endReached = false;
            this.afterKey = null;

            this.index().finally(() => this.isLoading = false);
        },
        /**
         * List prescriptions of a customer.
         *
         * @param [after] Optional after key, to get prescriptions beyond that key.
         *
         * @return {Promise<void>}
         */
        async index(after = null): Promise<void> {
            const params: Partial<PatientSearchParams & { after: string }> = {
                tags: this.selectedTags.tags,
                tags_operator: 'AND',
                limit: 10,
                after: after ? JSON.stringify(after) : '',
                ...this.selectedTags.children && {age_to: '19 years'},
                ...this.selectedTags.certified && {certified: 1},
                ...this.selectedTags.purchase_type && {purchase_type: this.selectedTags.purchase_type},
                ...this.selectedTags.purchase_id && {purchase_id: this.selectedTags.purchase_id},
            };

            return prescriptionApi()
                .customerIndex((parseInt(this.$route.params.customerId as string)), params)
                .then(response => {
                    this.prescriptions = [...this.prescriptions, ...response.data];
                    if (this.afterKey !== response.meta.after_key) {
                        this.afterKey = response.meta.after_key;
                    } else {
                        this.endReached = true;
                    }

                    this.tags = response.meta.tags;
                });
        },
        /**
         * Load more prescriptions with the after key.
         */
        loadMore() {
            this.isLoadingMore = true;
            this.index(this.afterKey)
                .finally(() => {
                    this.isLoadingMore = false;
                });
        },
        /**
         * On press handle action with the prescription.
         *
         * TODO: Pour l'instant l'action par défaut est d'ouvrir le plan de renouvellement mais à terme on gérera plusieurs
         * actions en fonction du type d'action sélectionnée par l'utilisateur.
         *
         * @param prescription
         */
        onPress(prescription: Prescription) {
            this.$router.push({name: 'customer.prescriptions.renewal', params: {prescriptionId: prescription.id}});
        }
    }
});
</script>
