import useSwr from "@/core/composables/swr/useSwr";
import {customerApi} from "@/container";
import {computed, Reactive} from "vue";
import {PatientSearchParams} from "@/customer/interfaces/Patient";
import {filterEmptyKeys} from "@/lib/utils";

/**
 * Composable to get the analysis of customers for a laboratory.
 */
const usePatientDashboard = (params?: Reactive<PatientSearchParams>) => {
    const swrKey = computed(() => {
        if (params) {
            if (params.children) {
                params.age_to = '19 years';
            } else {
                params.age_to = null;
            }

            const queryString: string = new URLSearchParams(filterEmptyKeys(params)).toString();

            return `customers/dashboard?${queryString}`;
        }

        return `customers/dashboard`;
    });

    const {data, error, isLoading, isValidating} = useSwr(
        swrKey,
        () => customerApi().dashboard(params),
        {
            revalidateOnFocus: false,
            ttl: 1000 * 60 * 5,
            errorRetryCount: 0
        }
    );

    return {
        data,
        error,
        isLoading,
        isValidating
    };
};

export default usePatientDashboard;
