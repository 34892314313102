<script setup lang="ts">
import Offer from "@/core/interfaces/Offer";

defineProps<{
    offer: Offer;
}>();
</script>

<template>
  <div class="bg-white p-2 rounded-md shadow-md cursor-pointer">
    <img v-if="offer.teaser?.file?.url" :src="offer.teaser.file.url" alt="offer-image">
    <h3>{{ offer.name }}</h3>
    <p>{{ $filters.date(offer.start_at, 'short') }} - {{ $filters.date(offer.end_at, 'short') }}</p>
  </div>
</template>
