import {computed} from "vue";
import {filterEmptyKeys} from "@/lib/utils";
import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import {mutate} from "swrv";

interface LaboratoryOffersParams {
    page?: number;
    size?: number;
    status?: 'past' | 'upcoming' | 'active';
}

/**
 * Composable to get the offers for a laboratory.
 *
 * @param laboratoryId
 * @param params
 */
const useLaboratoryOffers = (laboratoryId: string, params: LaboratoryOffersParams = {}) => {

    const swrKey = computed(() => {
        const query = new URLSearchParams(filterEmptyKeys({...params, laboratory_id: laboratoryId}));

        return `/laboratories/${laboratoryId}/offers?${query.toString()}`;
    });

    const {data, isLoading} = useSwr(
        swrKey,
        () => laboratoryApi().offers({...params, laboratory_id: laboratoryId}),
        {
            revalidateOnFocus: false,
            ttl: 1000 * 60 * 5,
            errorRetryCount: 0
        }
    );

    /**
     * Prefetch the offers.
     */
    const prefetch = () => {
        return mutate(swrKey.value, () => laboratoryApi().offers({...params, laboratory_id: laboratoryId}));
    };

    return {
        data,
        isLoading,
        prefetch
    };
};

export default useLaboratoryOffers;
