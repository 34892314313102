<template>
  <experienceable-pathway-step-button
    v-if="action && actionIsReady && !program.groups?.excluded?.length"
    :step="step"
    :primary="true"
    class="mt-2"
    density="comfortable"
  >
    <div :class="{ 'line-clamp-1': clamp}">
      {{ isLocked ? "Répondre à la question" : action }}
    </div>
  </experienceable-pathway-step-button>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ExperienceablePathwayStepButton
    from '@/program/components/experienceable-program-button/ExperienceablePathwayStepButton.vue';
import ConsumerProgramService from '@/core/services/ConsumerProgramService';

export default defineComponent({
    name: 'ProgramTeaserAction',
    components: {ExperienceablePathwayStepButton},
    props: {
        /**
         * The program to get the teaser action from.
         */
        program: {
            type: Object,
            required: true
        },
        /**
         * Fix the number of line of the teaser text to 1.
         */
        clamp: {
            type: Boolean
        }
    },
    emits: ['action'],
    computed: {
        /**
         * Get the program next step
         *
         * @return {Object|null}
         */
        step() {
            return this.program.next_step;
        },
        /**
         * Get the next step action
         *
         * @return {string}
         */
        action() {
            return this.step ? this.step.resolved.action : null;
        },
        /**
         * Determine if the step action is ready.
         *
         * @return {boolean}
         */
        actionIsReady() {
            return ConsumerProgramService().hasAction(this.program);
        },
        /**
         * Determine if the step is locked.
         *
         * @return {boolean}
         */
        isLocked() {
            return this.step.type === 'aftercare' &&
                this.step.steppable?.verdict?.verdict &&
                !this.step.consumer_step?.steppable?.id;
        }
    }
});
</script>
