<template>
  <div class="w-full">
    <component
      :is="answerComponent"
      :question="question"
      :survey-id="surveyId"
      :editable="editable"
      :density="density"
      :value="value"
      @change="(data) => $emit('change', data)"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SurveyBuiltChoices from '@/survey/components/SurveyBuiltChoices.vue';
import SurveyBuiltInput from '@/survey/components/SurveyBuiltInput.vue';
import SurveyBuiltDate from '@/survey/components/SurveyBuiltDate.vue';
import SurveyBuiltCheckInput from '@/survey/components/SurveyBuiltCheckInput.vue';
import {QuestionType} from "@/core/interfaces/survey/Survey";
import SurveyBuiltMultipleChoices from "@/survey/components/SurveyBuiltMultipleChoices.vue";

export default defineComponent({
    name: 'SurveyBuiltAnswer',
    components: {
        SurveyBuiltChoices,
        SurveyBuiltInput,
        SurveyBuiltDate,
        SurveyBuiltCheckInput,
        SurveyBuiltMultipleChoices
    },
    props: {
        surveyId: {
            type: Number,
            required: true
        },
        question: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Array],
            default: null
        },
        density: {
            type: String,
            default: 'normal'
        }
    },
    emits: ['change'],
    computed: {
        /**
         * Return the answer content.
         *
         * @returns {string}
         */
        answerComponent() {
            switch (this.question.type.slug) {
            case QuestionType.YES_NO:
            case QuestionType.SINGLE_CHOICE:
                return 'SurveyBuiltChoices';
            case QuestionType.MULTIPLE_CHOICE:
                return 'SurveyBuiltMultipleChoices';
            case QuestionType.TEXT_INPUT:
            case QuestionType.CHECK_INPUT:
                return 'SurveyBuiltInput';
            case QuestionType.DATE:
                return 'SurveyBuiltDate';
            default:
                return 'SurveyBuiltChoices';
            }
        }
    }
});
</script>
