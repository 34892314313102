<script setup lang="ts">
import BaseModal from "@/core/components/base/BaseModal.vue";
import {ref} from "vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import CustomerAutoComplete from "@/customer/components/customer-autocomplete/CustomerAutoComplete.vue";
import CommunicationFilters from "@/communication/components/CommunicationFilters.vue";
import Icon from "@/core/components/icon/Icon.vue";

const visible = ref(false);
</script>

<template>
  <div class="my-4">
    <BaseModal v-if="visible" title="Filtrer" @close="visible=false">
      <customer-auto-complete @on-confirm="visible=false" />
    </BaseModal>
    <div
      v-else
      class="flex flex-1 flex-row items-center gap-3"
    >
      <BaseButton @click="visible=true">
        <Icon name="mdi-tune" class="mr-2 text-primary" />
        <span class="text-primary">Auprès de qui souhaitez-vous communiquer ?
        </span>
      </BaseButton>
      <communication-filters />
    </div>
  </div>
</template>
