<script setup lang="ts">
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";
import BaseLogo from "@/core/components/logo/BaseLogo.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {animate} from "motion";
import {onMounted} from "vue";

defineProps<{
    laboratory: Laboratory;
}>();
const emit = defineEmits(['animationFinished']);

const slideIn = {
    x: ["-70%", "0%"],
    opacity: [0, 1]
};

const slideOut = {
    x: ["0%", "100%"],
    opacity: [1, 0]
};

const springConfig = {
    easing: "easeIn",
};

onMounted(() => {
    animate(
        ".glow-text",
        {
            textShadow: [
                "0 0 8px rgba(255,255,255,0.3), 0 0 16px rgba(255,255,255,0.3)",
                "0 0 12px rgba(255,255,255,0.6), 0 0 24px rgba(255,255,255,0.6)",
                "0 0 16px rgba(255,255,255,0.9), 0 0 32px rgba(255,255,255,0.9)",
            ]
        },
        {
            duration: 2,
            repeat: Infinity,
            repeatType: "mirror",
            ease: "easeInOut"
        },
    );

    animate([
        [".welcome-container", slideIn, {...springConfig, duration: 1}],
        [".welcome-container", slideOut, {...springConfig, duration: 0.5, at: 3}]
    ]).then(() => {
        emit('animationFinished');
    });
});
</script>

<template>
  <div
    class="welcome-container w-full px-8 pt-12 pb-8 space-y-8" :style="[
      'background-color: ' + laboratory.branding?.primary_color,
    ]"
  >
    <div class="flex justify-between">
      <div class="text-white glow-text">
        <h1 class="text-8xl text-white">
          Bienvenue
        </h1>
        <h1 class="text-white text-5xl">
          dans votre espace {{ laboratory.name }}
        </h1>
      </div>
      <BaseLogo alt="logo" :logo="laboratory.logo" img-class="object-contain" class="bg-white h-32" />
    </div>
    <div class="flex justify-between">
      <div class="flex gap-2 text-white items-center">
        <Icon name="mdi-chart-bar" color="white" :size="30" />
        <span class="font-bold">
          Suivez vos performances
        </span>
      </div>
      <div class="flex gap-2 text-white items-center">
        <Icon name="mdi-star" color="white" :size="30" />
        <span class="font-bold">
          Fidélisez vos patients
        </span>
      </div>
      <div class="flex gap-2 text-white items-center">
        <Icon name="mdi-trending-up" color="white" :size="30" />
        <span class="font-bold">
          Augmentez vos ventes
        </span>
      </div>
    </div>
  </div>
</template>
