<script setup lang="ts">
import {onMounted, ref, onBeforeUnmount} from "vue";

const props = defineProps<{
    url: string;
}>();

const video = ref<HTMLVideoElement | null>(null);
const canvas = ref<HTMLCanvasElement | null>(null);
const thumbnail = ref<string | null>(null);
const isProcessing = ref(false);
const blobUrl = ref<string | null>(null);

/**
 * On mounted, load the video and generate the thumbnail.
 */
onMounted(async () => {
    try {
        const response = await fetch(props.url);
        const blob = await response.blob();
        blobUrl.value = URL.createObjectURL(blob);

        if (video.value) {
            video.value.src = blobUrl.value;
        }
    } catch (e) {
        console.error('Error loading video:', e);
    }
});

/**
 * On loaded data, generate the thumbnail.
 */
const onLoadedData = () => {
    if (!isProcessing.value && video.value) {
        isProcessing.value = true;
        video.value.currentTime = 1;
        video.value.addEventListener('seeked', generateThumbnail, {once: true});
    }
};

/**
 * Generate a thumbnail from the video.
 */
const generateThumbnail = () => {
    const _video = video.value;
    const _canvas = canvas.value;

    if (!_video || !_canvas || thumbnail.value) {
        return;
    }

    try {
        _canvas.width = _video.videoWidth;
        _canvas.height = _video.videoHeight;

        const ctx = _canvas.getContext('2d');
        if (!ctx) return;

        ctx.drawImage(_video, 0, 0, _canvas.width, _canvas.height);
        thumbnail.value = _canvas.toDataURL('image/jpeg');
        isProcessing.value = false;
    } catch (error) {
        isProcessing.value = false;
    }
};

onBeforeUnmount(() => {
    if (blobUrl.value) {
        URL.revokeObjectURL(blobUrl.value);
    }
});
</script>

<template>
  <div class="video-thumbnail">
    <canvas v-show="false" ref="canvas" />
    <video
      ref="video"
      style="display: none"
      crossorigin="anonymous"
      @loadeddata="onLoadedData"
    />
    <img
      v-if="thumbnail"
      :src="thumbnail"
      alt="Video thumbnail"
      class="w-full h-full object-cover object-center"
    >
    <div v-else class="loading">
      Loading thumbnail...
    </div>
  </div>
</template>
