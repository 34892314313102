import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import {computed} from "vue";
import {mutate} from "swrv";

const useLaboratoryStatistics = (laboratoryId: string) => {
    const key = computed(() => {
        return laboratoryId && `partnership/patient/laboratories/${laboratoryId}/statistics`;
    });

    const {data} = useSwr(
        key,
        () => laboratoryApi().patientPartnerStatistics(Number(laboratoryId)),
        {
            revalidateOnFocus: false
        }
    );

    /**
     * Prefetch laboratory statistics data.
     */
    const prefetch = () => {
        return mutate(key.value, () => laboratoryApi().patientPartnerStatistics(Number(laboratoryId)));
    };

    return {
        data,
        prefetch
    };
};

export default useLaboratoryStatistics;
