<script setup lang="ts">
import ExpandableTextOverflow from '@/core/components/expandable-text-overflow/ExpandableTextOverflow.vue';
import Markdown from '../../../core/components/markdown/Markdown.vue';
import videoThumbnail from "@/assets/images/thumbnail_video_default.png";
import {Program} from "@/program/interfaces/Program";
import EmbedVideo from "@/core/components/video/EmbedVideo.vue";
import {ref} from "vue";
import {Dialog, DialogContent, DialogTitle, DialogTrigger} from "@/core/components/ui/dialog";

defineProps<{ program: Program }>();

const modalVisible = ref<boolean>(false);

</script>

<template>
  <div class="w-full flex flex-row space-between bg-lightPurple-lightest px-5 py-2">
    <expandable-text-overflow class="basis-full">
      <div class="font-content">
        <markdown :content="program.description" />
      </div>
    </expandable-text-overflow>
    <div v-if="program?.video_url">
      <Dialog v-model:open="modalVisible">
        <DialogTrigger>
          <img class="w-40 cursor-pointer" alt="tutoriel" :src="videoThumbnail" />
        </DialogTrigger>
        <DialogContent class="min-w-[90%] max-h-auto">
          <DialogTitle>{{ program.name }}</DialogTitle>
          <embed-video class="w-full" :url="program.video_url" />
        </DialogContent>
      </Dialog>
    </div>
  </div>
</template>
