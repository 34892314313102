import {RouteLocationRaw, RouteRecordRaw, useRoute} from "vue-router";

/**
 * Extract all route name from a route and its children recursively.
 *
 * @param {RouteRecordRaw} route The route object to extract names from
 *
 * @returns {string[]} The list of route names
 */
const extractChildRouteNames = (route: RouteRecordRaw): string[] => {
    const names: string[] = [];

    if (route.name) {
        names.push(route.name.toString());
    }

    if (route.children) {
        route.children.forEach(child => {
            names.push(...extractChildRouteNames(child));
        });
    }

    return names;
};

/**
 * Recursively searches through a route tree to find all possible child route names
 * for a given target route name.
 *
 * @param {RouteRecordRaw[]} routes The route tree to search through
 * @param {string} targetName The name of the route to search for
 *
 * @returns {string[]} An array of route names including the target and all its children.
 *
 * @example
 * const routes = [{
 *   name: 'laboratory.offers',
 *   children: [
 *     { name: 'laboratory.offers.list' },
 *     { name: 'laboratory.offers.details' }
 *   ]
 * }];
 * findInRoutes(routes, 'laboratory.offers');
 * // Returns: ['laboratory.offers', 'laboratory.offers.list', 'laboratory.offers.details']
 *
 */
const findInRoutes = (routes: RouteRecordRaw[], targetName: string): string[] => {
    for (const route of routes) {
        if (route.name === targetName) {
            return extractChildRouteNames(route);
        }

        if (route.children) {
            const found = findInRoutes(route.children, targetName);
            if (found.length > 0) {
                return found;
            }
        }
    }
    return [];
};

/**
 * Checks if a current route is active by comparing it with a target route and its children.
 * A route is considered active if it matches the target route name or any of its children's names.
 *
 * @param {string} currentRouteName - The name of the current active route
 * @param {string} targetRouteName - The name of the target route to check against
 * @param {RouteRecordRaw[]} routes - The array of routes to search through
 *
 * @returns {boolean} True if the current route matches the target or any of its children
 */
const isRouteActive = (
    currentRouteName: string,
    targetRouteName: string,
    routes: RouteRecordRaw[]
): boolean => {
    const possibleRoutes = findInRoutes(routes, targetRouteName);
    return possibleRoutes.includes(currentRouteName);
};

/**
 * Enhanced isLinkActive function for use in navigation components.
 * Supports checking active state based on exact matches or including children.
 *
 * @param {RouteLocationRaw} to - The target route location
 * @param {boolean} [matchChildren=false] - Whether to consider child routes
 * @param {RouteRecordRaw[]} routes - The array of available routes
 * @returns {boolean} True if the link should be considered active
 */
const isLinkActive = (to: RouteLocationRaw, matchChildren: boolean = false, routes: RouteRecordRaw[]): boolean => {
    if (!to) return false;

    if (typeof to === 'object' && 'name' in to) {
        const targetName = to.name as string;
        const currentName = useRoute().name as string;

        return matchChildren
            ? isRouteActive(currentName, targetName, routes)
            : currentName === targetName;
    }

    if (typeof to === 'object' && 'path' in to) {
        return useRoute().path === to.path;
    }

    return useRoute().path === to;
};

export {
    isRouteActive,
    isLinkActive
};
