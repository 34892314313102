<script setup lang="ts">
import Offer from "@/core/interfaces/Offer";
import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import LaboratoryOfferCard from "@/laboratory/components/LaboratoryOfferCard.vue";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps<{
    offers: Offer[],
    laboratoryId: string;
    color?: string;
    laboratoryName?: string;
}>();

/**
 * Redirect to the offers page.
 */
const redirectToOffers = () => {
    router.push({
        name: 'laboratory.offers',
        params: {laboratoryId: props.laboratoryId}
    });
};

/**
 * Redirect to the offer details page.
 *
 * @param offerId
 */
const redirectToOfferDetails = (offerId: number) => {
    router.push({
        name: 'laboratory.offers.details',
        params: {
            laboratoryId: props.laboratoryId,
            offerId: offerId.toString()
        }
    });
};
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div>
      <div class="flex items-center gap-2">
        <Icon name="mdi-percent" :size="36" />
        <h3>Vos promotions dans la poche</h3>
      </div>
      <p>Publiez vos promotions et vos patients en bénéficieront en click & collect</p>
    </div>
    <div class="flex gap-6">
      <div v-if="!offers.length">
        <p class="italic">
          Ajoutez des promotions sur les produits {{ laboratoryName }} depuis votre LGO pour mesurer leur
          performance ici, et les publier sur l'app patient
        </p>
      </div>
      <LaboratoryOfferCard
        v-for="offer in (offers ?? []).slice(0,3)"
        :key="offer.id"
        :offer="offer"
        @click="redirectToOfferDetails(offer.id)"
      />
    </div>
    <BaseButton
      v-if="offers.length" variant="text" :style="{
        color: props.color
      }" class="align-self-end" @click="redirectToOffers"
    >
      Voir toutes les promotions
    </BaseButton>
  </div>
</template>

<style scoped>

</style>
