<template>
  <div class="flex items-center">
    <ProgramLogo
      class="relative z-2 mr-3"
      :program="program"
    />
    <div class="flex flex-1 flex-col">
      <h1>
        {{ program.name }}
      </h1>
      <span class="text-black">{{ program.short_description }}</span>
      <span
        v-if="program.end_at"
        class="table -ml-5 mt-2.5 p-0.5 pl-7 pr-2 rounded bg-lightPurple flex-none"
      >
        {{ formatDate(program) }}
      </span>
    </div>
    <div>
      <div v-if="program.organization.logo" class="flex-1 flex justify-end">
        <img
          class="object-cover object-center"
          :src="`${program.organization.logo}&h=${size}`"
          :alt="`${program.organization.id}`"
        >
      </div>
      <SupportProgramNotificationToggle
        v-if="program.programmable_type === 'support'"
        :support-program-id="program.programmable_id"
        label-direction="left"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import dateHelper from '@/core/helpers/dateHelper';
import ProgramLogo from '@/program/components/program-logo/ProgramLogo.vue';
import SupportProgramNotificationToggle
    from "@/program/components/support-program/SupportProgramNotificationToggle.vue";
import {Program} from "@/program/interfaces/Program";

export default defineComponent({
    name: 'ProgramHeader',
    components: {
        SupportProgramNotificationToggle,
        ProgramLogo
    },
    props: {
        size: {
            type: Number,
            default: 90
        },
        program: {
            type: Object as PropType<Program>,
            required: true
        }
    },
    methods: {
        localeDate(dateString) {
            return dateHelper.shortFormat(new Date(dateString));
        },
        formatDate(program) {
            if (program.formatted_end_date) {
                return `Jusqu'au ${program.formatted_end_date}`;
            } else if (program.end_at) {
                return `Jusqu'au ${this.localeDate(program.end_at)}`;
            } else {
                return 'Permanent';
            }
        }
    }
});
</script>
